.home-supply-top-description {
  margin-bottom: 5px !important;
}

.home-supplychain {
  padding: 50px 0px 100px 200px;
}

button.products-main.main-supply {
  margin-bottom: 0px !important;
}

img.homesupplychain {
  width: 20%;
  height: auto;
  max-width: 100%;
  object-fit: cover;

}

@media only screen and (max-width: 767px) {
  .home-supplychain {
    padding: 50px 20px 20px 20px !important;
  }

  .home-supplychain .home-supplychain-h3 {
    font-size: 29px !important;
    line-height: 39px !important;
  }
}