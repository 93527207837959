.supplychain-page {
  padding: 10px 15px 70px 200px;
}

.col.supplychain-page-1 p {
  padding-top: 0px;
}

.supplychain-row {
  align-items: center;
}

.supplychain-page-1 {
  width: 564px !important;
}

.supplychain-page-1 h2 {
  color: #20336b;
  font-size: 50px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 60px !important;
  width: 564px !important;
}

.supplyoverview-hero-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002060;
  border: none;
  cursor: pointer;
  margin-top: 20px !important;

  line-height: 145%;
  padding: 10px 15px;
}

.supplyoverview-hero-btn i {
  margin-left: 5px;
  font-size: 16px;
}

.supplychain-page-1 h3 {
  color: #20336b;
  font-size: 43px;
  font-style: normal;
  font-weight: 500;
  line-height: 54px;
}

.supplychain-capabilities h6 {
  padding-top: 0px !important;
}

.supplychain-page-2 {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-top: 0 !important;
}

.supplychain-page-2 img {
  display: flex;
  justify-content: end;
  margin: auto;
}

.supply-body {
  background: #f6f9fc;
  padding: 70px 200px 155px 200px;
}

.supply-body h1 {
  color: #20336b;
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 70px;
}

.supply-body h1 span {
  color: #00b0f0;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.square-point {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background: #0a2540;
  border-radius: 10px;
}

.supplychainicon {
  margin-left: 8px;
  margin-bottom: 18px;
}

.sup-point {
  display: flex;
  padding-left: 8px;
}

.square-point {
  margin-top: 11px;
  margin-right: 10px;
}

.capabilities h6 {
  color: #20336b;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding-top: 22px;
}

.sup-point p {
  color: #20336b;
  margin-bottom: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.capabilities {
  margin-top: 15px;
  margin-bottom: 15px;
}

.phone1 {
  display: grid;
  justify-content: center;
  margin: auto;
  margin-top: 100px;
  padding-left: 100px;
  grid-gap: 10px;
}

.phone2 {
  margin-top: 300px;
}

.phone2 img:nth-child(1) {
  margin-bottom: 10px;
}

.supply-util-down-arrow {
  left: 8px;
}

.supply-arrow {
  margin: 0px -20px;
}

.supply-topdescription {
  margin-top: 5px;
  margin-bottom: 5px !important;
}

.supply-bottomdescription,
.supply-topdescription {
  padding-top: 0px !important;
}

.supplychain-p {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

button.supplyoverview-hero-btn {
  line-height: 145%;
  padding: 10px 15px;
}

@media only screen and (max-width: 767px) {
  .supplychain-overviewpage {
    padding: 20px 20px 20px 20px !important;
  }

  .supplychain h2 {
    font-size: 35px !important;
    line-height: 45px !important;
    padding-top: 0px;
  }

  .supplychain h2 {
    font-size: 35px !important;
    line-height: 50px !important;
  }

  .supplychain p {
    font-size: 16px;
  }

  .supplychain li {
    font-size: 13px;
  }

  .supply-body h1 {
    font-size: 24px !important;
  }

  .supply-body h1 span {
    font-size: 24px !important;
  }

  .sup-point p {
    font-size: 14px;
    color: #20336b;
  }

  button.supplyoverview-hero-btn {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    align-items: center;
  }

  .supply-body h1 {
    font-size: 25px !important;
  }

  .supply-body h1 span {
    font-size: 25px;
  }

  .supplychain-page-1 {
    width: 100% !important;
  }

  .supplychain-phone-col {
    width: 50%;
  }

  .supplychain-body-row {
    flex-direction: row !important;
  }

  .animation-class {
    display: flex;
    justify-content: center;
  }

  .phone1 {
    width: 50%;
  }

  .phone1 img {
    width: 100%;
  }

  .phone2 {
    width: 50%;
    justify-content: space-between;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
  }

  .phone2 img {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .supplychain-page {
    padding: 10px 50px 20px 20px;
  }

  .supplychain-page-1 {
    width: 50%;
  }

  .supplychain h2 {
    font-size: 35px;
    padding-top: 0px;
  }

  .supplychain h2 {
    font-size: 35px !important;
    line-height: 50px !important;
    width: 100% !important;
  }

  .supplychain p {
    font-size: 16px;
  }

  .supplychain li {
    font-size: 14px;
    width: 100%;
  }

  .supply-body {
    padding: 70px 30px !important;
  }

  .sup-point p {
    font-size: 14px;
  }

  .phone1 {
    margin-top: 0px;
    padding-left: 20px;
    grid-gap: 10px;
  }

  .phone2 {
    grid-gap: 10px;
    margin-bottom: 10px;
    display: grid;
    justify-content: center;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1439px) {
  .left-image-payment {
    left: 550px !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1439px) {
  .supplychain-page {
    padding: 10px 0 20px 200px !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .supplychain-page {
    padding: 10px 80px 20px 80px !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1500px) {
  .left-image-payment {
    left: 700px !important;
  }
}

/* @media only screen and (min-width: 1501px) {
  .left-image-payment {
    left: 780px !important;
  }
} */

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .supply-body {
    padding: 70px 70px 70px 70px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1500px) {
  .supply-body {
    padding: 70px 150px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .capabilities {
    margin-bottom: 0px;
  }
}

.cap6-image {
  width: 100%;
}