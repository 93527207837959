.personal-e-com-content-image {
  transition: opacity 0.5s ease;
  width: fit-content;
}

.shop-app-download {
  margin-top: 30px !important;
}

.personal-e-com-content-image.main {
  opacity: 0;
}

.personal-e-com-content-image.hide {
  opacity: 0.2;
}

.payrow {
  padding-top: 30px;
}

.personal-e-com-content-image.show {
  opacity: 1;
  width: fit-content;
}

.payment-body-section-5 {
  padding: 60px 300px;
}

.personal-shop2 h3 {
  color: #20336b;
  font-weight: 500;
  padding-top: 32px;
}

.personal-shop2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: 0;
}

.personal-shop-hero-right {
  display: block !important;
  text-align: center !important;
}

.personal-shop-now {
  margin-top: 30px;
  font-size: 20px;
  background: #4abceb;
  border-radius: 34%;
  display: inline-block;
  padding: 3px 16px 6px 16px;
  border-radius: 16.5px;
  font: var(--ctaFont);
  color: var(--buttonColor);
  transition: var(--hoverTransition);
  outline: none;
  font-size: 15px;
  color: white;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.personal-shop-now:hover {
  color: #fff;
  text-shadow: 0 2px 15px rgba(255, 233, 152, 0.6);
}

.shopimg {
  width: 385px;
  margin-left: auto;
  margin-right: auto;
}

.process-flow {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.top-step {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 20px;
  position: relative;
}

.top-circle {
  width: 50px;
  height: 50px;
  border: 2px solid #20336b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.top-circle img {
  max-width: 50%;
}

.top-description {
  color: #20336b;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding-bottom: 15px;
}

.down-arrow {
  position: absolute;
  bottom: -33px;
  left: 28px;
}

.process-steps {
  display: grid;
  grid-template-columns: repeat(4, 0.1fr);
  align-items: center;
  margin-top: 20px;
}

.step-container {
  display: flex;
  align-items: center;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 25px 0px 0px;
  position: relative;
}

.circleimg {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #20336b; */
}

.circleimg img {
  max-width: 60%;
}

p.top-description {
  text-align: left;
}

.down-description {
  color: #20336b;
  font-size: 13px !important;
  max-width: 100px;
  text-align: center;
  white-space: normal;
  line-height: 15px !important;
  height: 44px;
  font-weight: 500 !important;
  margin-top: 1px !important;
  margin-bottom: 0px !important;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -36px;
}

p.down-description.twoline {
  padding-left: 15px;
}

.personal-shop-topdescription {
  width: 17% !important;
  text-align: center !important;
  margin: 15px 0px 5px 0px !important;
}

@media (max-width: 767px) {
  .personal-shop2 h3 {
    font-size: 24px !important;
    text-align: center;
  }
  .hero2-img {
    width: 100%;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .shop-hero {
    padding: 20px 20px 70px 20px !important;
  }

  .col.shopmain {
    padding-top: 0px !important;
  }

  .hero2-img {
    padding: 20px 0px 0px 0px;
  }

  .shop-hero p {
    width: 100% !important;
  }

  .shop-hero h1 {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}
