.indemnity-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    color: #333;
}

div.notinterested {
    border-bottom: 1px solid #e1dbdb;
    margin-bottom: 10px;
}

p.readcarefully {
    font-size: 19px !important;
}

p.optionsare {
    margin-bottom: 5px !important;
}

p.iagree {
    margin-bottom: 5px !important;
}

.indemnity-container h1 {
    color: #2c3e50;
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
}

.indemnity-container p {
    margin-bottom: 15px;
    font-size: 16px;
}

.indemnity-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
}

.indemnity-container ul li {
    margin-bottom: 10px;
    font-size: 16px;
}

@media (max-width: 600px) {
    .indemnity-container {
        margin: 10px;
    }

    .indemnity-container h1 {
        font-size: 20px;
    }

    .indemnity-container p,
    .indemnity-container ul li {
        font-size: 14px;
    }
}