.p-2.productglcoloms,
.p-2.progl,
.p-2.progl2 {
    margin-top: 0px;
    margin-left: 0px;
}

.p-2.productinput3rd,
.p-2.progl2 {
    width: 25%;
    padding: .08rem !important;
}

.gridbuttonchecker,
.switch.productswitch,
img.toogle-img2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.labelgl {
    padding-top: 10px;
    color: #5bb7e7;
    margin-bottom: 0;
    width: 100%;
    font-size: 0.9em;
    text-align: center;
    padding-bottom: 5px;
}

label.labelglrow {
    padding-top: 49px;
}

label.labelglrow2 {
    padding-top: 76px;
    font-size: 12.5px;
}

.loanlabelmak7 {
    color: #fff;
    line-height: 12px;
    font-size: 12px !important;
    padding-top: 3px;
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: center;

}

.labelglrowgl {
    padding-top: 48px;
}

.formbox {
    margin-top: 50px;
    width: 70%;
    background-color: #26abe7;
    border-radius: 15px;
    padding: 15px 28px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #26abe7;
}

.subtext {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 10px;
}