#DCP {
  overflow: hidden;
}

.dts-hero {
  padding: 30px 100px 50px 200px;
}

.dcpfooter {
  padding-top: 100px;
}

.dcp-body {
  padding: 70px 200px;
  background: #f6f9fc;
}

/* #DCP {
  padding: 0 15px;
} */

.duo-list-container.duo-app-list-container {
  margin-top: 0px;
}

.dcp-col {
  width: 50% !important;
  font-weight: 500;
}

.duo-col p {
  font-weight: 400;
  color: #425466;
  line-height: 16px;
  font-size: 11px;
}

p.elevate {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
}

.dcp-list-p {
  font-size: 16px !important;
}

.dcp-list-container {
  width: 100% !important;
  margin-top: 0px;
}

.patended {
  color: #425466;
  font-weight: bold;
}

.dcp-container {
  width: 100%;
}

.dcp-container p {
  text-align: justify;
}

.video-image {
  width: "460px";
  height: "299px";
  /* position: "absolute"; */
  top: 0;
  right: 0;
  z-index: 1;
  cursor: "pointer";
}

@media only screen and (max-width: 767px) {
  .duo-col {
    width: 100% !important;
  }

  .dcpcard {
    width: 100% !important;
  }

  .dts-body2 {
    padding: 30px 20px !important;
  }

  .dcp-hero h1 span {
    font-size: 35px;
    line-height: 45px;
  }

  .dcp-hero h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .dcp-hero button {
    margin: 20px 0 10px 0;
    padding: 10px;
  }

  .dcp-frame {
    width: 100%;
    max-width: 100% !important;
    height: 100% !important;
  }

  .dcp-list {
    width: 100%;
    height: 100%;
  }

  .video-image {
    width: 100%;
  }

  .dcp-body {
    padding: 50px 20px 0px 20px !important;
  }

  .dcp-body2 .col:nth-child(2) {
    margin: 60px 0px 0px 0px !important;
  }

  .dcp-body2 p {
    width: 100%;
  }

  .dcp-list p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .duo-col {
    width: 100% !important;
  }

  .dcpcard {
    width: 70% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .dcp-hero {
    padding: 20px 20px 20px 20px !important;
  }

  .dcp-hero-row {
    align-items: start !important;
  }

  .duo-list-container {
    margin-top: 30px;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .dcp-body2 {
    padding: 70px 150px;
  }

  .dcp-body2 .col:nth-child(2) {
    margin: 50px 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .dcp-body2 {
    align-items: flex-start;
  }

  .dcp-body2 {
    align-items: flex-start !important;
  }

  .dcp-body2 .col:nth-child(2) {
    margin: 120px 150px 0px 150px !important;
  }
}
