/* .sales-hero {
  margin-bottom: 400px;
} */

.sales-hero h1 {
  font-size: 50px !important;
}

.sales-animation-class {
  margin-left: 0px !important;
  justify-content: center !important;
}

.supplychain-row {
  align-items: center;
}

.center-image-sales {
  position: absolute;
  left: 78%;
  /* top: 100%; */
  /* margin-top: 580px; */
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .sales-hero {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .sales-hero {
    margin-bottom: 0px;
  }

  .sales-animation-class {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  .center-image-sales {
    display: none;
  }

  .sales-hero h1 {
    font-size: 36px !important;
  }

  .sup-payment-hero,
  .sales-hero {
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .sup-payment-hero {
    padding: 20px 50px 70px 200px;
  }

  .sales-hero h1 {
    font-size: 40px !important;
    line-height: 55px !important;
  }

  .sup-payment-hero h1 {
    font-size: 40px;
  }
  .center-image-sales {
    left: 78%;
  }
  .sales-hero {
    margin-bottom: 300px !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .sales-hero {
    margin-bottom: 0px !important;
  }
  .sup-payment-hero {
    padding: 20px 50px 70px 200px;
  }

  .sales-hero h1 {
    font-size: 40px !important;
    line-height: 55px !important;
  }

  .sup-payment-hero h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1599px) {
  .sales-animation-class {
    justify-content: center !important;
  }

  .center-image-sales {
    left: 77% !important;
  }
  .sales-hero h1 {
    font-size: 49px !important;
  }
}
