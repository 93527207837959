.sol-nav-link .active {
  color: #ffc000 !important;
  font-weight: bold;
}

.sol .sol-button {
  background: #ffc000 !important;
  color: #101010 !important;
  font-weight: 600 !important;
}

.supply-solution-menu {
  align-items: center !important;
}

/* .flexdiv-menu-items .solutions-logo {
  width: 100%;
} */

.flexdiv-menu-items .duo-solutions-logo {
  width: 80px;
}

.flexdiv-menu-items .duo-solutions-logo img {
  width: 100%;
}

.solutions-flexdiv-menu-items {
  display: flex;
  align-items: center;
  padding: 5px 10px 0 20px;
  margin-right: 0px;
}
.solutions-flexdiv-menu-items {
  display: flex;
  align-items: center;
  padding: 0px 10px 0 20px;
  margin-right: 0px;
}

button.nav-link.more.dusupports {
  margin-right: 0px;
}

button.nav-link.more.supportmore.active {
  margin-right: 0px;
}

.sol-button:hover {
  color: #ffc000 !important;
  background: #101010 !important;
}

.solution:hover {
  color: #ffc000 !important;
}

.solution h6:hover {
  color: #ffc000 !important;
}
