.cob-body {
  padding: 70px 200px 120px 200px !important;
}

.co-img img {
  display: flex;
  justify-content: end;
  margin-left: 100px;
  margin-bottom: 50px;
}

.duo-list.onboarding p {
  font-size: 17.5px;
  line-height: 25px;
}

img.dwpimgall {
  max-width: 100%;
  padding-top: 20px;
}

.customer-onboarding-text {
  max-width: 100%;
  font-size: 56px !important;
  line-height: 68px !important;
  font-weight: 600;
  margin-bottom: 0px !important;
}

.onboardingimg {
  margin-top: 60px;
}

.customer-onboarding-text-span {
  font-size: 50px !important;
  line-height: 55px !important;
  font-weight: 600;
}

.cob-frame {
  max-width: 50% !important;
  margin-bottom: 50px !important;
}

/* .cob-list {
  height: 500px !important;
} */

.cob-body .dwp-row {
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .cob-frame {
    max-width: 100% !important;
    margin-bottom: 0px !important;
  }

  .col.dco-hero-image-col {
    margin-top: 0px !important;
  }

  .cob-body {
    padding: 50px 20px 50px 20px !important;
  }



  .co-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .co-img img {
    width: 100%;
    margin-top: 30px;
    position: static;
    margin-left: auto;
    max-width: 80%;
    margin-bottom: 0px;
  }

  .customer-onboarding-text,
  .customer-onboarding-text-span {
    font-size: 35px !important;
    line-height: 45px !important;
  }
}

@media only screen and (max-width: 768px) {
  .cob-frame {
    max-width: 100% !important;
    margin-bottom: 0px !important;
  }

  .cob-body {
    padding: 50px 20px 50px 20px !important;
  }

  .co-img img {
    margin-left: 0px !important;
    margin-bottom: 0px;
  }

  .dwp-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .dco-hero {
    max-width: 100%;
  }

  .co-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .co-img img {
    width: 100%;
    margin-top: 30px;
    position: static;
    margin-left: auto;
    max-width: 80%;
    margin-bottom: 0px;
  }

  .dco-hero img {
    max-width: 60%;
  }

  .customer-onboarding-text-span {
    font-size: 30px;
  }

  .cob-list {
    height: auto !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .dco-hero {
    padding: 20px 20px 20px 20px !important;
  }

  .dco-hero-content-col {
    width: 50%;
  }

  .cob-frame {
    height: 550px !important;
  }

  .customer-onboarding-text {
    font-size: 47px !important;
    line-height: 55px !important;
  }

  .customer-onboarding-text-span {
    font-size: 47px !important;
    line-height: 55px !important;
  }

  .dco-hero-image-col {
    width: 50% !important;
    width: 50%;
    margin-top: 50px;
  }

  .cob-body {
    padding: 70px 100px 70px 100px !important;
  }

  .customer-onboarding-text-span {
    font-size: 30px;
  }

  .dwp-row {
    display: flex;
  }

  .dco-list-container {
    width: 100%;
  }

  .co-img img {
    width: 100%;
  }

  .cob-list p {
    font-size: 12px;
  }
}

.co-img img {
  display: flex;
  justify-content: end;
  margin-left: 50px;
}

.customer-onboarding-text {
  width: 690px;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .cob-body {
    padding: 50px 20px 0px 20px !important;
  }

  .dwp-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .co-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .co-img img {
    width: 100%;
    margin-top: 30px;
    position: static;
    margin-left: auto;
    max-width: 80%;
  }

  .dco-hero {
    padding: 10px 20px !important;
  }

  .onboardingimg {
    margin-top: 0px;
  }

  .dco-hero img {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .cob-body {
    padding: 50px 20px 0px 20px !important;
  }

  .dwp-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .dco-hero {
    max-width: 100%;
  }

  .co-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .co-img img {
    width: 100%;
    margin-top: 30px;
    position: static;
    margin-left: auto;
    max-width: 80%;
  }

  .dco-hero img {
    max-width: 60%;
    margin-right: auto;
  }

  .customer-onboarding-text-span {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  .duo-list-container.dco-list-container p {
    width: 100% !important;
  }
}

@media (min-width: 1025px) {
  .customer-onboarding-text {
    font-size: 56px !important;
    line-height: 68px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {

  .dco-hero-content-col,
  .dco-hero-image-col {
    width: 50% !important;
  }

  .duo-list-container {
    width: 100% !important;
  }

  .cob-body {
    padding: 70px 80px 70px 80px !important;
  }
}