/* business footer */
.download-montra.montrabusiness p {
  font-size: 20px;
  margin-bottom: 0px;
  padding-right: 20px;
  color: #20336b !important;
}

.app-download img {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: fill;
}

h6.companyfootertext {
  padding-top: 10px;
}

p.blucolorbusinessfooter {
  color: #20336b !important;
}

.business-footer-links li {
  color: #20336b !important;
}

.business-footer-links h6 {
  color: #20336b !important;
}

.footerlogobusiness {
  width: 142px;
}

img.bussinesfooterlogo {
  max-width: 100%;
}

span.businessfooterspan {
  color: #fff !important;
}

.business-footer-links li:hover {
  color: #fff !important;
}

/* end business footer */

.moneylink-footer1 p {
  color: #fff;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 455px;
}

.mainfooter-page {
  background-color: #f6f9fc;
  padding-top: 30px;
}

section.footer.productfooter {
  background-color: #f6f9fc;
}

.footerissuing {
  background-color: #fff;
}

.footermerchant {
  background-color: #f8f6fc;
}

.download-montra p {
  font-size: 20px;
  color: #fff !important;
  margin-bottom: 0px;
  padding-right: 20px;
}

.footer-box {
  margin-top: 40px;
}

.download-montra {
  display: flex;
}

.download-montra span {
  font-size: 15px;
}

.footer-app {
  display: inline-block;
  padding-right: 45px;
}

p.usingapp {
  font-size: 15px;
}

.download-montra p {
  font-size: 20px;
  color: #fff !important;
}

.moneylink-footer1 p span {
  color: #fff;

  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.moneylink-footer1 button {
  border-radius: 8px;
  background: #fff;
  border: none;
  color: #ff5900;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 8px 15px;
}

p.highlightfooter {
  margin-bottom: 13px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1 1 100%;
  margin-bottom: 20px;
}

.footer-first-section.footer-section {
  flex-basis: 37%;
  padding-right: 100px;
}

.footer-first-section.footer-section p {
  color: #fff;
  font-weight: 400;
}

.footersecRow.footer-section {
  flex-basis: 15%;
}

.footerfiveRow.footer-section {
  flex-basis: 17%;
}

.footerthirdRow.footer-section {
  flex-basis: 21%;
  padding-left: 5px;
}

.footerFourthRow.footer-section {
  flex-basis: 10%;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .footer-first-section.footer-section {
    flex-basis: 100%;
    padding-right: 100px;
  }

  .footersecRow.footer-section {
    flex-basis: 33.33%;
  }

  .footerthirdRow.footer-section {
    flex-basis: 33.33%;
  }

  .footerFourthRow.footer-section {
    flex-basis: 33.33%;
    padding-left: 20px;
  }

  .download-montra p {
    margin-bottom: 10px;
  }

  .footerdown {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .footerFourthRow.footer-section {
    flex-basis: 100%;
    padding-left: 0px;
  }

  .footerFourthRow.footer-section.responsivefourthfooter {
    display: none;
  }

  .responsivefourthfootermenu {
    margin-top: 15px;
  }

  .businessfooterspan {
    margin-top: 10px;
  }
}

@media screen and (min-width: 480px) {
  .responsivefourthfootermenu {
    display: none;
  }
}

@media screen and (max-width: 920px) {
  .cip-hero-img {
    justify-content: center;
  }

  .prepaid-card-header p {
    padding: 0px 10px;
  }

  .row {
    row-gap: 10px;
  }
}