.home-duo-process-flow {
  margin-top: -60px;
}

button.exploreduobutton {
  margin-bottom: 100px;
}

.sol-logo {
  margin-top: 15px;
}

button.products-main.main-duo {
  color: #000 !important;
}

p.duo-topdescription.top-description {
  margin-top: 0px;
  /* text-align: left !important; */
}

.home-duo .home-duo-title {
  margin-top: 20px;
}

.home-duo {
  padding-top: 70px !important;
}

@media only screen and (max-width: 767px) {
  .home-duo h3,
  .home-duo h3 span {
    font-size: 29px !important;
    line-height: 39px !important;
  }

  .home-duo {
    padding-top: 20px !important;
  }

  .home-duo p {
    margin-top: 5px !important;
  }

  .home-duo button.exploreduobutton {
    margin-bottom: 0px !important;
  }

  .home-duo .home-duo-process-flow {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .home-duo-card2 {
    margin-top: 30px !important;
    width: 100% !important;
  }

  .home-duo-process-flow {
    margin-top: 20px !important;
    padding: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .home-duo-process-flow {
    margin-top: 10px !important;
    padding: 20px 20px 0px 0px !important;
  }

  .duoheroimg2 {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .home-duo .exploreduobutton {
    margin-bottom: 0px !important;
  }
  .home-duo .home-duo-process-flow {
    margin-top: -50px !important;
  }

  .home-duo .duo-process-flow {
    margin-top: 30px !important;
  }
}
