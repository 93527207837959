.business-agency {
  padding: 50px 200px 170px 200px;
}

.business-payment-body-1.trackcollected.agencypadding {
  padding-bottom: 55px !important;
}

.business-payment-body-1.trackcollected.agencypadding.agencybanking3rdmodule {
  background-color: #f6f9fc;
  padding-top: 75px;
  padding-bottom: 180px !important;
}

.business-payment-body-1.trackcollected.agencypadding.agencybanking3rdmodule h1 {
  color: #20336b;
}

.business-payment-method.business-payment-reward.securewaybusiness h6 {
  color: #20336b;
}

.business-payment-method.business-payment-reward.securewaybusiness p {
  color: #20336b;
}

.business-agency h1 {
  color: #20336b;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 58px;
  padding-top: 0px;
}

.app-download.agencyapp {
  margin-top: 15px;
}

.agencypadding {
  padding-left: 300px !important;
  padding-right: 300px !important;
  padding-bottom: 80px !important;
}

.inventory-sm-btn p {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.col-md-9.business-agency-content-col {
  padding-top: 10px;
}

.business-agency p {
  color: #20336b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0px;
}

.business-agency h1 span {
  color: #20336b;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 58px;
}

.business-agency-2 {
  max-width: 290px;
  margin-left: auto;
}

.ab {
  position: absolute;
  top: 700px;
  right: 0;
  z-index: 1;
  display: none;
}

.home-business.business-payment-body.agencybody {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .business-agency h1,
  .business-agency h1 span {
    font-size: 35px;
    line-height: 43px;
  }

  .business-agency-2 {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .agencypadding {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .agency-process-container {
    padding: 10px 0;
  }
  .agencyhero1 {
    width: 100%;
  }
  img.agencyhero1 {
    padding-bottom: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .agencypadding {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .business-agency-content-col,
  .business-agency-2 {
    width: 100% !important;
  }

  .business-agency img:nth-child(3) {
    width: auto !important;
  }

  .business-agency-2 {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
  .agency-process-container {
    padding: 10px 0;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .agencypadding {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .business-agency-content-col {
    width: 60% !important;
  }

  .business-agency {
    padding: 20px 20px 100px 20px !important;
  }
}
