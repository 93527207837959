.moneylink-project .h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.moneylink-project h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0.5rem !important;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}
