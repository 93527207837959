.collection-hero {
  margin-bottom: 0px !important;
}

.capabilities.capabipadding {
  padding-right: 40px;
}

.paymentCollectionImage3 {
  width: 100%;
}

.supplychain-row {
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .collection-hero {
    margin-bottom: 0px !important;
  }

  .supplychain-gif-layout {
    margin-left: -30px;
  }
}

@media only screen and (min-width: 1708px) {
  .collection-hero {
    margin-bottom: 150px !important;
  }
}
