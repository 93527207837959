.paymentimg {
  max-width: 85%;
}

p.payment-hero-p.simplyqr {
  padding-bottom: 13px;
  margin-bottom: 0px !important;
  margin-top: -13px !important;
}

.payment-body-section-5 .row:nth-child(2) {
  margin-top: 30px;
}

.payment-hero-p {
  font-size: 18px !important;
  line-height: 26px !important;
}

.personal-payment-topdescription {
  width: 20% !important;
  text-align: center !important;
  margin: 10px 0px 5px 0px !important;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .paymentimg {
    max-width: 100%;
  }

  .payment-hero-h1,
  .payment-hero-h1-span {
    font-size: 40px !important;
    line-height: 55px !important;
  }

  .payment-body-section-3 h1 {
    font-size: 30px !important;
  }

  .payment-body h1 {
    font-size: 30px !important;
  }

  .payment-body-section-5 {
    padding: 50px 20px !important;
  }

  .payment-body-width {
    width: 100% !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .payment-body-section-6 p {
    width: 100% !important;
  }

  .payment-body-section-6 {
    padding: 35px 20px !important;
  }

  .col.payment-img {
    width: 350px;
  }

  .col.payment-hero-left {
    padding-right: 0px !important;
  }

  .payment {
    margin-left: 0px !important;
  }

  .payment-table-1 td {
    padding: 15px 8px !important;
  }

  .payment-table-row h6 {
    font-size: 14px !important;
  }

  .payment-table-row p {
    font-size: 13px !important;
  }

  .payment-table-row-2 p {
    font-size: 12px !important;
  }

  .marketplace-icon2 p {
    font-size: 13px !important;
    font-weight: 400;
  }

  .payment-table-1 th,
  td {
    padding: 5px;
    font-size: 12px !important;
  }

  img.imgpadding {
    padding-bottom: 10px;
  }
  .payment-process-container {
    padding: 0px 0px 15px 0px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .payment-body-width {
    width: 100% !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .payment-body-width-2 {
    width: 400px !important;
  }

  .payment-image-col {
    width: 500px !important;
    height: auto !important;
  }

  .payment-table-row-2 {
    display: flex !important;
  }

  .payment-body-section-5 {
    padding: 50px 50px !important;
  }

  .payment-body-section-6 {
    padding: 35px 50px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .personal-hero {
    padding: 20px 0 50px 20px !important;
  }

  .payment-body-width-2 {
    width: 300px !important;
  }

  .col.payment-hero-left {
    padding-right: 0px !important;
  }

  .payment-hero-h1,
  .payment-hero-h1-span {
    font-size: 40px !important;
    line-height: 55px !important;
  }

  .payment-img-mobile.paymentimg {
    max-width: 100%;
  }

  .payment-image-col {
    width: 500px;
    height: auto;
    padding-top: 30px;
  }

  .payment-body-section-5 {
    padding: 50px 100px !important;
  }

  .payment-body-section-6 {
    padding: 35px 100px !important;
  }

  .payment-body-section-6 p {
    width: 100% !important;
  }

  .personal-hero.payment-hero-p {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1600px) {
  .paymentimg {
    max-width: 80%;
  }
}
