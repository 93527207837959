/**
  collectionstyle.css is render all stylesheet Nibss collection section
 Author : Seema Kumari

 @version :1.0 */

.collactiontext {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.collactiontext1 {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.collectionheadertext {
  margin-top: 7px;
  margin-bottom: 20px;
}

.collactiontext2 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.row.holdsec {
  padding-top: 60px;
}

.row.holdsec3 {
  padding-top: 40px;
}

.reportcols {
  text-align: center;
}

.row.reportrow {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.col-6.reportlist2 {
  padding: 0px;
}

.col-6.reportlist2 {
  padding: 0px;
  font-size: 15px;
  font-family: Arial;
  font-weight: 600;
}

.reportcols,
.reportlist {
  padding-left: 35px;
  display: inline-block;
}

.reportlist {
  text-align: left;
}

.reportcols {
  padding-left: 35px;
}

.nibslablename {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
  padding-top: 16px;
}

.downloadreport {
  color: #e69f45;
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
  text-align: center;
  padding-top: 18px;
}
button.downloadtext.collectiondown {
  font-size: 15px;
}

.fas.fa-arrow-down {
  padding-right: 10px;
  font-size: 20px;
}

.nibscollectionreport .row.holdsec2 {
  padding-bottom: 90px;
}

/* Media-query :- It uses the @media rule to include a block of CSS properties only if a certain condition is true.
Using media queries are a popular technique for delivering a tailored style sheet
to desktops, laptops, tablets, and mobile phones (such as iPhone and Android phones). */

@media only screen and (max-width: 991px) {
  .col-sm-12.col-md-12.col-lg-4.portfoliocols {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1280px) {
  .downloadreport {
    padding-top: 36px !important;
  }
}

@media only screen and (max-width: 1550px) {
  .nibslablename {
    padding-top: 0px;
  }

  .collectionlablename {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 1336px) {
  .nibslablename {
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 1350px) {
  .nibslablename {
    padding-top: 15px !important;
  }
}

.listreport {
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.reportcols {
  padding-left: 35px;
}

.reportcols {
  text-align: center;
}
.reportcols {
  padding-left: 35px;
}
.reportcols {
  text-align: center;
}
.reportcols,
.reportlist {
  padding-left: 35px;
  display: inline-block;
}
.reportcols {
  text-align: center;
  font-weight: 600;
}

.reportlist {
  text-align: left;
}
.reportcols,
.reportlist {
  padding-left: 35px;
  display: inline-block;
}

.collectionsec {
  padding: 15px;
  background-color: #192859;
  color: white;
}

@media only screen and (max-width: 1280px) {
  .collectionsec2 {
    height: 522px;
  }

  .collectionsec {
    height: 522px;
  }
}

@media (min-width: 1281px) {
  .collectionsec,
  .collectionsec2 {
    height: 500px !important;
  }
}

.collectionsec2 {
  padding: 15px;
  background-color: gray;
  color: white;
}

.collactiontext1 {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.collactiontext2 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.row.holdsec {
  padding-top: 60px;
}

.col-4.portfoliocols {
  padding-right: 0px;
}

@media only screen and (max-width: 991px) {
  .row.holdsec2 {
    padding-bottom: 0px;
  }
  .collectionsec2 .downloadreport {
    padding-top: 18px;
  }
  .listreport {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 360px) {
  .collectionsec {
    height: 670px;
  }
}

@media only screen and (min-width: 720px) {
  .collectionsec {
    height: 730px;
  }
}

@media (min-width: 992px) {
  .row.holdsec2 {
    padding-bottom: 84px;
  }
}

@media (min-width: 1200px) {
  .row.holdsec2 {
    padding-bottom: 100px;
  }
}

@media (min-width: 1360px) {
  .row.holdsec4 {
    padding-bottom: 55px !important;
  }
}

@media only screen and (max-width: 414px) {
  .reportcols {
    text-align: center;
    font-weight: 600;
    padding-left: 0px !important;
    font-size: 14px !important;
  }
  .collectionsec {
    height: auto !important;
  }

  .collectionsec2 {
    height: auto !important;
  }

  .portfoliocols {
    padding: 0px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .operation-content .downloadreport {
    padding-top: 18px !important;
  }
}

@media only screen and (max-width: 767px) {
  .nibscollectionreport .collectionsec2,
  .nibscollectionreport .collectionsec {
    height: auto !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .nibscollectionreport .row.holdsec2 {
    padding-bottom: 80px;
  }
  .nibscollectionreport .downloadreport {
    padding-top: 30px !important;
  }
  .nibscollectionreport .row.holdsec4 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 768px) {
  .nibscollectionreport .row.holdsec2 {
    padding-bottom: 20px;
  }
  .nibscollectionreport .downloadreport {
    padding-top: 20px !important;
  }
  .nibscollectionreport .row.holdsec4 {
    padding-bottom: 20px !important;
  }

  .nibscollectionreport .collectionsec2,
  .nibscollectionreport .collectionsec {
    height: auto;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .nibscollectionreport .collectionsec2,
  .nibscollectionreport .collectionsec {
    height: auto;
  }
}

@media only screen and (max-width: 1440px) {
  .nibscollectionreport .row.holdsec4 {
    padding-bottom: 40px !important;
  }
}
