button.products-main.main-bbusiness {
  margin-bottom: 10px !important;
}

.gap-92 {
  gap: 92px;
}

.gap-72 {
  gap: 72px;
}

.yu-mr-3:nth-child(3) {
  margin-top: 223px;
}

.yu-mr-4:nth-child(1),
.yu-mr-4:nth-child(3) {
  margin-top: 52px;
}

.yu-mr-4.pg-animation-items2 {
  margin-top: 47px;
}

.yu-mr-4:nth-child(4) {
  margin-top: 330px;
}

.anim-p {
  font-size: 11px !important;
  line-height: auto !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt22 {
  margin-top: 22px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt42 {
  margin-top: 42px !important;
}

.mt38 {
  margin-top: 38px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt120 {
  margin-top: 120px !important;
}

.mt165 {
  margin-top: 165px !important;
}

.mt216 {
  margin-top: 216px !important;
}

.mt235 {
  margin-top: 235px;
}

.mt240 {
  margin-top: 240px !important;
}

.mt248 {
  margin-top: 248px !important;
}

.mt280 {
  margin-top: 280px;
}

.mt255 {
  margin-top: 255px !important;
}

.mt-725 {
  margin-top: 725px !important;
}

.mt190 {
  margin-top: 190px !important;
}

.mt427 {
  margin-top: 427px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.mt-2 {
  margin-top: -2px !important;
}

.mt195 {
  margin-top: 195px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.mt325 {
  margin-top: 325px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.mt440 {
  margin-top: 440px !important;
}

.mt468 {
  margin-top: 455px !important;
}

.mt504 {
  margin-top: 504px !important;
}

.mt475 {
  margin-top: 475px;
}

.mt475 {
  margin-top: 477px;
}

.mt502 {
  margin-top: 502px;
}

.mt72 {
  margin-top: 72px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt68 {
  margin-top: 68px !important;
}

.mt215 {
  margin-top: 215px !important;
}

.mt168 {
  margin-top: 168px !important;
}

.mt220 {
  margin-top: 220px !important;
}

.mt116 {
  margin-top: 116px !important;
}

.mt192 {
  margin-top: 192px !important;
}

.ml96 {
  margin-left: 96px !important;
}

.mt330 {
  margin-top: 330px !important;
}

.mt336 {
  margin-top: 336px !important;
}

.mt375 {
  margin-top: 375px !important;
}

.mt378 {
  margin-top: 378px !important;
}

.mt336 {
  margin-top: 336px !important;
}

.mt500 {
  margin-top: 500px !important;
}

.mt370 {
  margin-top: 370px !important;
}

.mt292 {
  margin-top: 292px !important;
}

.mt680 {
  margin-top: 680px;
}

.mt729 {
  margin-top: 729px;
}

.z-1 {
  z-index: 9999 !important;
}

.yu-flex-c {
  display: flex;
  flex-direction: column;
}

.gap-16 {
  gap: 16px;
}

.nowrap {
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.montrapg-wrapper {
  margin-top: 70px;
  /* padding-bottom: 100px; */
}

.business-2 {
  width: 50%;
}

.ske-b p {
  margin-bottom: 10px;
}

.ske img {
  position: static !important;
}

.ske-b {
  box-shadow: 0 12.6px 25.2px -11.5733px rgba(50, 50, 93, 0.25),
    0 7.56px 15.12px -7.56px rgba(0, 0, 0, 10);
}

.homebusiness-store-topdescription {
  padding-bottom: 15px !important;
  margin-bottom: 0px !important;
}

.businessAnimation {
  z-index: 999;
}

/* seema */

.col.duo-col.businesshomecol {
  padding-left: 0px;
  padding-right: 0px;
}

.col.BusinessAnimation {
  padding-left: 125px;
}

ul.business-list {
  width: 500px;
  margin-bottom: 5px;
}

.row.AgentModule {
  padding: 100px 0 00px 0;
}

.col.MontraStoreAnimation {
  padding-top: 100px;
}

.col.PaymentGatewayAnimations {
  padding-top: 120px;
}

.montraStoreAnimation {
  /* position: relative;
    width: fit-content;
    height: auto; */
}

.e-com-content-image {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  width: fit-content;
  height: auto;
}

.e-com-content-image.show {
  opacity: 1;
}

.e-com-content-image.hide {
  opacity: 0.5;
}

.ml337 {
  margin-left: 337px !important;
}

.mt620 {
  margin-top: 620px !important;
}

.animation7-montraagent {
  margin-top: 330px !important;
}

.animation7-ske-montraagent {
  margin-top: 400px !important;
}

.yu-path-2 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dashLine 2s linear forwards;
}

.yu-path-yu11 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dashLine 4s linear forwards;
}

/* .yu13-path-1 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dashLine 1s linear forwards;
}

.yu13-path-2 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dashLine 2s linear forwards;
} */

@keyframes dashLine {
  to {
    stroke-dashoffset: 0;
  }
}

.pg-animation {
  width: 100px;
}

.pg-animation img {
  z-index: 1 !important;
}

.PaymentGatewayAnimations {
  position: relative;
  display: flex;
}

.pg-animation-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 125px;
}

.pg-right-image {
  width: 935px;
  margin-top: -130px;
  z-index: 1 !important;
  margin-left: -50px;
}

.pg-animation-groups {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.pganim-text {
  display: none;
}

.ske-b-animation {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  box-shadow: 0 12.6px 25.2px -11.5733px rgba(50, 50, 93, 0.25),
    0 7.56px 15.12px -7.56px rgba(0, 0, 0, 10);
  z-index: 1 !important;
}

.mt-52 {
  margin-top: -52px;
}

.mt15 {
  margin-top: 15px;
}

.mt30 {
  margin-top: 30px;
}

.m-515 {
  margin-top: -370px;
}

.ml-200 {
  margin-left: -200px;
}

.mt218 {
  margin-top: 218px;
}

.m-433 {
  margin-top: -125px;
}

.mt145 {
  margin-top: 145px !important;
}

.mt45 {
  margin-top: 45px;
}

.montrapp-ussd {
  margin-top: -5px;
}

.ussd-montrapp {
  margin-top: 0px;
}

.mt50 {
  margin-top: 50px;
}

.ml-200 {
  margin-left: -200px;
}

.ml-177 {
  margin-left: -177px;
}

.mt100 {
  margin-top: 100px !important;
}

.mt105 {
  margin-top: 105px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt-37 {
  margin-top: -37px !important;
}

.mt340 {
  margin-top: 340px !important;
}

.mt385 {
  margin-top: 385px !important;
}

.ml-175 {
  margin-left: -175px;
}

.mt205 {
  margin-top: 205px;
}

.ml-200 {
  margin-left: -200px;
}

.mt-390 {
  margin-top: -390px;
}

.mt40 {
  margin-top: 40px;
}

.mt410 {
  margin-top: 410px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt160 {
  margin-top: 160px !important;
}

.pg1-animation {
  margin-left: -150px;
  margin-top: 25px;
}

.pg-flex {
  display: flex;
}

.pg-animation-ske {
  margin-top: -125px;
  margin-left: -130px;
  width: 100px;
}

.mt315 {
  margin-top: 315px;
}

.ml-165 {
  margin-left: -165px;
}

.mt222 {
  margin-top: 222px !important;
}

.mt24 {
  margin-top: 24px;
}

.ml-285 {
  margin-left: -285px !important;
}

.mt725 {
  margin-top: 725px !important;
}

.mt427 {
  margin-top: 427px !important;
}

.home-montra-business {
  padding-right: 0px;
}

.businessPg p {
  color: #20336b;
}

@media only screen and (max-width: 767px) {
  .normal-layout {
    display: none;
  }

  .gif-layout {
    display: block;
    /* margin-top: 20px; */
  }

  .col.BusinessAnimation {
    padding: 0 !important;
  }

  .business-2 {
    width: 100%;
  }

  .e-com-content-image.show,
  .e-com-content-image.hide,
  .e-com-content-image {
    max-width: 100% !important;
  }

  .row.AgentModule {
    padding: 0px 0 0px 0 !important;
  }

  .col.PaymentGatewayAnimations {
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .montrastore-animation-flex {
    align-items: center;
  }

  .col.MontraStoreAnimation {
    padding-top: 0px !important;
  }

  .montrapg-wrapper {
    margin-top: 0px !important;
  }

  .PaymentGatewayAnimations {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .montrastore-animation-flex {
    align-items: center;
  }

  .col.MontraStoreAnimation {
    padding-top: 0px !important;
  }

  .montrapg-wrapper {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .normal-layout {
    display: block;
  }

  /* .gif-layout {
    display: none;
  } */

  .business-section1-gif {
    width: 100%;
    height: 100%;
  }

  .col.business-2 {
    /* padding-top: 45px; */
    padding: 60px 60px 30px 60px;
  }

  /* .pg-animation-rightimage {
    width: 100%;
  } */
}

.business-section1-gif {
  width: 100%;
  height: 100%;
}

.animation7a_in_7a {
  display: none !important;
}

.pg-animation-right-image {
  margin-top: 20px;
  margin-left: -5px;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .home-montra-business {
    padding-right: 0px;
  }

  .home-business-left {
    width: 100% !important;
    /* padding: 0 100px !important; */
  }

  .home-business-left-pg {
    padding: 0px !important;
  }

  .home-business-left h1 {
    width: 100% !important;
  }

  /* .BusinessAnimation,
  .business-2,
  .MontraStoreAnimation {
    margin-top: 60px !important;
    padding: 0 100px !important;
  } */

  .montrapg-wrapper {
    margin-top: 70px;
  }

  .home-business-col {
    width: 100%;
  }

  .home-business-left p {
    width: 100% !important;
  }

  .MontraStoreAnimation {
    width: 50%;
  }

  .MontraStoreModule {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .montrapg-wrapper {
    padding-left: 20px !important;
  }

  .col.BusinessAnimation,
  .col.business-2 {
    padding-left: 0px !important;
    margin-top: 100px;
  }
}

@media only screen and (min-width: 1600px) {
  .pg-right-image {
    width: 1010px;
    margin-top: -140px;
  }

  .pg1-animation {
    margin-left: -150px;
    margin-top: 37px;
  }
}

.BusinessAnimation .skep {
  margin-top: 3px;
  font-size: 11px;
}

.business-2 .ske p {
  margin-top: 5px;
  font-size: 11px;
}