.b-banking img {
  width: 122px !important;
}

.payment-body-section-3.business-payment-body-2 {
  padding: 15px 270px 50px 270px;
}

.open-account.business-banking-open {
  height: 150px;
  padding-top: 15px;
}

.open-account.business-banking-open:not(:first-child) .open-acct-count::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  /* height: 150px; */
  width: 2px;
  background-color: #d8d8d8;
  top: 170%;
}

.open-acct-step {
  margin-left: 10px;
}

.open-account.business-banking-open:not(:last-child) .open-acct-count::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 150px;
  width: 2px;
  background-color: #d8d8d8;
  top: 96%;
}

.bankopenaccount {
  width: 250px;
}

.business-banking p {
  color: #20336b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0px;
}

.herocontent {
  padding-right: 100px;
  padding-top: 15px;
}

img.businessbankimgdown {
  max-width: 100%;
}

img.businessbankimghero {
  max-width: 100%;
  height: auto;
}

.bank-body-section-2.bank-body-section-3.business-bank-body3 h1 {
  color: #20336b;
}

.bankingimg {
  text-align: right;
  padding-top: 10px;
}

img.businessbankimgbody {
  max-width: 86%;
}

.col-4.openaccountimg {
  text-align: center;
}

.col-4.openaccountimg2 {
  padding-left: 0px;
}

.personalcountimghidden {
  background: #3fafe9;
  color: #fff;
  width: 22px;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}

.personalcountimghidden.twoimg {
  margin-top: 40px;
}

.businessbankimg {
  max-width: 100%;
}

.banking-row {
  align-items: center;
}

@media (max-width: 767px) {
  .business-banking-hero {
    padding: 20px 20px !important;
  }

  .business-payment {
    padding: 20px 20px 70px 20px !important;
  }

  .business-banking h1,
  .business-banking h1 span {
    font-size: 40px !important;
    line-height: 55px !important;
  }
  .bank-body-section-2.bank-body-section-3 {
    padding: 35px 20px !important;
  }

  .herocontent {
    padding-right: 0px !important;
  }

  .business-banking-app-download img {
    width: 30% !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .bankingimg {
    padding-top: 10px;
    width: 250px !important;
    height: auto !important;
    margin: auto !important;
  }

  .bank-body-section-2 h1 {
    font-size: 25px !important;
    line-height: 38px !important;
  }
  .banking-row-image-col {
    display: contents;
  }
  .business-banking-img {
    padding: 10px 0px 10px 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .business-banking-hero {
    padding: 20px 20px !important;
  }

  .business-banking-content-col,
  .business-banking-image-col {
    width: 100% !important;
  }

  .herocontent {
    padding-right: 0px !important;
  }

  .business-banking-img {
    max-width: 100% !important;
  }

  .bankingimg {
    padding-top: 10px;
    width: 250px !important;
    height: auto !important;
    margin: auto !important;
  }

  .business-bank {
    margin-top: 60px !important;
  }

  .bank-body-section-1 h1 {
    margin-top: 50px;
  }

  .personalb {
    padding: 0 0px;
  }

  .business-banking-app-download img {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .businessbankimgbody {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .business-banking {
    padding: 20px 20px !important;
  }

  .business-payment-body-1.agencyOffered {
    padding: 60px 100px !important;
  }

  .payment-body-section-3.business-payment-body-2 {
    padding: 15px 100px 50px 100px;
  }

  .paid-instantly-row {
    align-items: start !important;
  }

  .paid-instantly {
    padding: 100px 20px !important;
  }

  .horizontal-line,
  .horizontal-line-2 {
    width: 860px !important;
  }

  .openaccountimg2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    display: flex;
    justify-content: center;
  }

  .linkbankaccount {
    padding-right: 0px !important;
  }

  .bank-body-section-3 .open-acct-count {
    margin-left: 10px !important;
  }
  .b-banking img {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 920px) {
  .open-acct-step {
    margin-left: 0px;
  }

  .open-account.business-banking-open {
    height: auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .open-account {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0;
    height: auto;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .horizontal-line,
  .horizontal-line-2 {
    width: 920px;
  }

  .business-payment-body-1 {
    padding: 50px 170px 65px 170px;
  }

  .business-bank .b-banking img {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1439px) {
  .business-payment-body-1 {
    padding: 50px 170px 65px 170px !important;
  }

  .payment-body-section-3.business-payment-body-2 {
    padding: 15px 100px 50px 100px;
  }

  .horizontal-line,
  .horizontal-line-2 {
    width: 800px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .business-payment-body-1 {
    padding: 50px 170px 65px 270px !important;
  }

  .horizontal-line,
  .horizontal-line-2 {
    width: 1170px !important;
  }
}
