.forgotpasswordchange {
  padding-top: 50px;
  padding-bottom: 100px;
}

.toplogoml {
  margin-left: auto;
  display: block;
  margin-right: auto;
  max-width: 200px;
}

.toplogo {
  max-width: 100%;
  padding-bottom: 0px;
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .forgotpasswordchange {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .moneybgnew .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    flex-wrap: nowrap;
  }
}
