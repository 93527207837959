.verified-part-text p {
  margin-bottom: 0px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 15px;
}


p.veryeasy {
  padding-top: 0px !important;
}

button.products-main.main-verifyed {
  color: #37cc00 !important;
}

.verified-part-text.homeveryfied p {
  font-size: 15px;
  font-weight: 500;
  padding-top: 15px;
}

.verifiedhome {
  margin-top: 30px;
}

.col.veryfiedcolimg {
  padding-top: 90px;
}

.digital-kyc-row {
  padding-top: 100px;
}

.home-verified-auth-topdescription {
  margin-bottom: 5px !important;
}

@media only screen and (max-width: 1024px) {
  .verified-home-face-scan-img {
    margin-left: 0px !important;
  }

  .digital-kyc-row {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .col.veryfiedcolimg {
    padding-top: 30px !important;
  }

  .verifiedimg-1 {
    margin-bottom: 0px !important;
  }

  .digital-kyc-row {
    margin-top: 50px !important;
  }

  .digital-kyc-row {
    padding-top: 0px;
  }

  .auth-app-img {
    width: 100% !important;
  }

  .home-verified h1 span {
    display: block;
  }

  .digital-kyc-row h3 {
    font-size: 38px !important;
    line-height: 48px !important;
  }

  .digital-kyc-row .face-auth-img2 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .col.veryfiedcolimg {
    padding-top: 30px !important;
  }

  .verifiedimg-1 {
    margin-bottom: 0px !important;
  }

  .digital-kyc-row {
    padding-top: 0px;
    margin-top: 30px !important;
  }
}