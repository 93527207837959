/**
  salarystyle.css is render all stylesheet in salary processor lender section
 Author : Seema Kumari

 @version :1.0 */

li.sidebarlitext {
  line-height: 15px;
}

.uploadfile {
  width: 100%;
  padding-top: 40px;
}

.uploadlabel {
  padding-top: 8px;
  padding-right: 10px;
}

.btn.btn-primary.filebutton {
  color: #000;
  background-color: #01b1ee;
  border-color: #01b1ee;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  width: 20%;
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
}

.col-md-6.center-block {
  text-align: center;
}

.report-down {
  color: #01b1ee;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  border: transparent;
}

.table.table-bordered.table-hover.footable {
  font-size: 12px;
  font-weight: 600;
  font-family: Arial;
}

.caption-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000;
  text-align: left;
  caption-side: top;
  font-size: 14px;
}

.table-responsive .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-responsive .table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-responsive .table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

p.filesalarysuccess {
  font-size: 0.9em;
  color: green;
  font-weight: 600;
}

.errorMsg {
  color: #c03;
  font-size: 12px;
}
/**
  salarystyle.css is render all stylesheet in salary processor lender section
 Author : Seema Kumari

 @version :1.0 */

li.sidebarlitext {
  line-height: 15px;
}

.uploadfile {
  width: 100%;
  padding-top: 40px;
}

.uploadlabel {
  padding-top: 8px;
  padding-right: 10px;
}

.btn.btn-primary.filebutton {
  color: #000;
  background-color: #01b1ee;
  border-color: #01b1ee;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  width: 20%;
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
}

.col-md-6.center-block {
  text-align: center;
}

.report-down {
  color: #01b1ee;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  border: transparent;
}

.table.table-bordered.table-hover.footable {
  font-size: 12px;
  font-weight: 600;
  font-family: Arial;
}

.caption-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000;
  text-align: left;
  caption-side: top;
  font-size: 14px;
}

.table-responsive .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-responsive .table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-responsive .table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

@media only screen and (max-width: 768px) {
  .backloan {
    width: 100%;
  }

  .salaryDataLender .btn.btn-primary.filebutton {
    margin-top: 0px;
  }
}
