/**
  salesstyle.css is render all stylesheet in Sales section
 Author : Seema Kumari

 @version :1.0 */

.row.origin {
  margin-top: 25px;
}

label {
  margin-bottom: 5px;
  font-size: 0.9em;
  font-family: Arial;
}
.para4,
.para4sal,
.signupara4,
.signuptext,
.content label {
  /* color: #000; */
  font-weight: 600;
}

.custom-control.custom-radio {
  color: #000;
}
label,
li.app,
li.becomeapp {
  display: inline-block;
}

.salesform {
  background-color: #20336b;
  color: white;
  padding: 10px 30px 20px 30px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 1250px;
}

p.eligreen {
  text-align: center;
  padding-top: 40px;
  font-weight: bold;
}

.salestext {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 10px;
}

.lablechecktext {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 8px;
  font-weight: 600;
  color: white;
  font-size: 14px;
  font-family: Arial;
  margin-top: 18px;
}

.lablecheck {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.checksales {
  float: right;
  margin-bottom: 5px;
}

.lablecheck2 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 11px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.lablecheck3 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 11px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.lablecheck4 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 11px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.lablecheck5 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 11px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.lablecheck6 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 11px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.lablecheck7 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 11px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.exp {
  display: table;
  width: 100%;
  height: 100%;
}
.exp .checkbox {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

label span {
  display: inline-block;
  position: relative;
  background-color: #fff;
  width: 25px;
  height: 25px;
  transform-origin: center;
  border: 2px solid #fff;
  border-radius: 50%;
  vertical-align: -6px;

  transition: background-color 150ms 200ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}
label span:before {
  content: "";
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: #fff;
  position: absolute;
  transform: rotate(45deg);
  top: 11px;
  left: 8px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}
label span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: #fff;
  position: absolute;
  transform: rotate(305deg);
  top: 14px;
  left: 9px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}
label:hover span:before {
  width: 5px;
  transition: width 100ms ease;
}
label:hover span:after {
  width: 10px;
  transition: width 150ms ease 100ms;
}

input[type="checkbox"]:checked + label span {
  background-color: #24baf2;
  border-color: #24baf2;
}
input[type="checkbox"]:checked + label span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
input[type="checkbox"]:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
input[type="checkbox"]:checked + label:hover span {
  background-color: #24baf2;
  border-color: #24baf2;
}

input[type="checkbox"]:checked + label:hover span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

input[type="checkbox"]:checked + label:hover span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.btn.btn-warning.agreesubmit {
  color: #fff;
  background-color: #20336b;
  border-color: #20336b;
  border-radius: 8px;
  text-align: center;
  padding: 5px 20px 5px 20px;
  width: 60%;
  font-size: 12px;
  font-weight: 600;
}

input#check7 {
  display: none;
}

input#check6 {
  display: none;
}

input#check5 {
  display: none;
}

input#check4 {
  display: none;
}

input#check3 {
  display: none;
}

input#check2 {
  display: none;
}

input#check {
  display: none;
}

.btn.btn-primary.checkbutton {
  color: #fff;
  background-color: #24baf2;
  border-color: #24baf2;
  border-radius: 9px;
  display: block;
  font-size: 11px;
  margin-top: 27px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.originationcontent .form-group {
  margin-bottom: 6px;
}

.form-group.otp label {
  padding-top: 15px;
}

.salesform2 {
  background-color: gray;
  color: white;
  padding: 10px 30px 20px 30px;
  width: 100%;
  display: block;
  margin-right: auto;
  position: relative;
  height: 1250px;
}

.terms {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 25px;
  margin-top: 15px;
}

.submittedsales {
  background-color: green;
  font-weight: 600;
  color: #fff;
  margin-top: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 13px;
  text-align: center;
}
.errorsales {
  background-color: #da1e28;
  font-weight: 600;
  color: #fff;
  margin-top: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 13px;
  text-align: center;
}

.loantenortext {
  display: inline-block;
  padding-right: 0px;
  padding-bottom: 8px;
}

.loantenor {
  padding-left: 0px;
  font-size: 11px;
  font-weight: 600;
  font-family: Arial;
  color: #192859;
  padding-top: 14px;
}

.termstext {
  padding-left: 0px;
  font-size: 11px;
  font-weight: 600;
  font-family: Arial;
  padding-top: 8px;
  color: #000;
}

.btn.btn-primary.loantenorbutton {
  background-color: #20336b;
  border-color: #20336b;
  font-size: 9px;
  width: 20%;
  float: right;
  padding: 5px;
}

.charge {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  font-family: Arial;
}

.formsale {
  padding: 0px;
}

.loantenortext2 {
  display: inline-block;
  padding-right: 23px;
  padding-bottom: 10px;
  font-size: 12px;
}

.loantenor2 {
  padding-left: 0px;
  font-size: 11px;
  font-weight: 600;
  font-family: Arial;
  color: #192859;
  padding-top: 0px;
}

.spant {
  font-size: 10px;
}

.btn.btn-primary.loantenorbutton2 {
  background-color: gray;
  border-color: gray;
  font-size: 9px;
  width: 20%;
  float: right;
  margin-top: 3px;
}
.loantenortext2,
.btn.btn-primary.loantenorbutton2 {
  display: inline-block;
}

.salestext2 {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  padding-top: 7px;
  padding-bottom: 10px;
}

.listorigin {
  color: #000;
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
}

.listoriginul {
  padding: 10px;
  padding-bottom: 10px;
  list-style: square;
}

.custom-control-label.radio {
  font-size: 11px;
}

.lablesit {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 12px;
  font-family: Arial;
  padding-top: 11px;
}

.salepin {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  background-color: #24baf2;
  border: 1px solid #24baf2;
  border-radius: 13px;
  padding: 15px 28px 15px 28px;
  margin-bottom: 0px;
  margin-top: 13px;
}

.salesform3 {
  background-color: #24baf2;
  color: white;
  padding: 10px 30px 10px 30px;
  width: 90%;
  display: block;
  margin-right: auto;
  position: relative;
  margin-top: 300px;
}

.salestext3 {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  padding-top: 11px;
  padding-bottom: 10px;
}

.salestext4 {
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
}

.btn.btn-warning.cardbuttonsales {
  color: #fff;
  background-color: #20336b;
  border-color: #20336b;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
}

.custom-control-label {
  position: static;
  margin-bottom: 0;
  vertical-align: top;
}
input[type="radio"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  -ms-appearance: checkbox; /* not currently supported */
  -o-appearance: checkbox; /* not currently supported */
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 0rem;
}

.submitted {
  background-color: green;
  color: #fff;
  font-size: 13px;
  text-align: center;
}

.invalidpin,
.submitted {
  /* margin-top: 16px; */
  padding-top: 5px;
  padding-bottom: 2px;
  font-weight: 600;
  margin-bottom: 0px;
}

.submitted {
  margin-top: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 600;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1199px) {
  .salesform2 {
    padding: 10px 5px 33px 5px;
    width: 100%;
    height: 1300px;
  }
  .salesform {
    height: 1300px;
  }
}

@media only screen and (max-width: 991px) {
  .salesform {
    padding: 10px 10px 6px 10px;
    height: 940px !important;
  }
  .salesform2 {
    margin-top: 20px;
    height: 1300px;
  }
  .salesform3 {
    width: 100%;
    margin-top: 30px;
    margin-right: 0px;
    padding: 10px 20px;
  }
  .salestext3 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .salesform {
    background-color: #20336b;
    color: white;
    padding: 10px 5px 20px 5px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* height: 1140px; */
  }
}

@media only screen and (max-width: 414px) {
  .salepin {
    width: 89%;
  }

  .salesform {
    padding: 10px 10px 80px 10px;
  }

  .salesform2 {
    padding: 10px 10px 33px 10px;
  }

  .salepin {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    background-color: #24baf2;
    border: 1px solid #24baf2;
    border-radius: 13px;
    padding: 15px 15px 15px 15px;
    margin-bottom: 0px;
    margin-top: 13px;
  }

  .salesform3 {
    margin-top: 60px;
    background-color: #24baf2;
    color: white;
    padding: 10px 20px 10px 20px;
    width: 90%;
    display: block;
    margin-right: auto;
    position: relative;
    margin-left: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .salesform,
  .salesform2 {
    height: 1300px;
  }
}
