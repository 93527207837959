#Finance {
  overflow: hidden;
}

p.apply-finance {
  font-weight: 400;
  line-height: 24px;
  padding-left: 8px;
  font-size: 15px;
  color: #20336b;
}

.col.finance-body-secction1-right {
  margin-top: 0px;
}

.payment-body-section-6 {
  padding: 0px 300px;
}

.col-md-7.col-xl-8.mobilviewfinance {
  padding-left: 0px;
}

.col.businesspaymentimg {
  padding-top: 50px;
}

.financeimgphone1 img {
  max-width: 100%;
}

/* 
img.financephone1 {
  max-width: 63%;
}

img.financeimg {
  max-width: 78%;
} */

.financeinsurance {
  padding-top: 5px;
  padding-left: 0px;
}

.col.investmentcol {
  padding-top: 55px;
}

.finance-process-flow {
  margin: 30px 0px 20px 0px;
}

.finance-arrow {
  margin: 0 -36px;
}

.finance-util-down-arrow {
  left: 25px;
}

.finance-body-left-image {
  margin-top: 15px;
}

.financeimgphone1 {
  margin-top: 25px;
}

.personal-finance-topdescription {
  margin-top: 20px !important;
  width: 20% !important;
  text-align: center !important;
  margin: 15px 0px 5px 0px !important;
}

.insurance-top-description {
  line-height: normal !important;
  margin-bottom: 5px !important;
  padding-right: 0px !important;
  padding-bottom: 0px;
}

.investment-topdescription {
  margin-top: 30px !important;
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 767px) {
  .finance-hero h1 {
    font-size: 35px !important;
    line-height: 43px !important;
  }
  .financeimgphone1 {
    margin-top: 0px;
  }

  .finance-body-left-col {
    text-align: center;
  }

  .open-account {
    display: block !important;
  }

  .loan-offer {
    margin-top: 50px !important;
  }

  .loan-offer h1 {
    font-size: 35px !important;
  }

  .open-account:not(:last-child) .open-acct-count::after {
    content: none !important;
  }

  .svgimg {
    padding-bottom: 10px !important;
  }

  .finance-body {
    padding: 20px 20px 70px 20px !important;
  }

  .finanace-body-section-5 {
    padding: 20px 20px !important;
  }

  .open-account {
    height: auto !important;
    margin-bottom: 20px !important;
  }

  .open-account h6 {
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

  .finance-body-secction1-left h2,
  .finance-body-secction1-left h2 span {
    font-size: 32px !important;
  }

  .finance-body-left-image {
    width: 100%;
    margin-top: 0px;
  }

  .bank-body-section-2 h1 {
    font-size: 25px !important;
    line-height: 38px !important;
  }

  .loan4 h1 {
    font-size: 35px !important;
  }

  .loan6 h1,
  .loan6 h1 span {
    font-size: 28px !important;
  }
  .finance-hero-img {
    padding: 10px 0 !important;
  }
  .finance-body-secction1-left img {
    margin: 10px 0;
  }
  .personal-page .open-account {
    height: auto;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .open-account {
    display: block !important;
  }

  .personal-page .open-account {
    height: 100px;
    margin-right: 0px;
  }

  .mobilviewfinance,
  .finance-body-secction1-left,
  .financeinsurance {
    width: 100% !important;
  }

  .open-account:not(:last-child) .open-acct-count::after {
    content: none !important;
  }

  .finance-row {
    padding: 20px 20px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .finance-hero {
    padding: 20px 20px 20px 20px !important;
  }

  .finance-hero-img {
    padding: 15px 0 !important;
  }

  .finance-body {
    padding: 30px 20px 70px 20px !important;
  }

  .bank-body-section-2.loan-offer2 {
    padding: 100px 20px 20px 20px !important;
  }

  .finance-body-left-col {
    text-align: end !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .finance-body-left-col {
    text-align: end !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .loan3 {
    padding: 90px 200px 90px 200px !important;
  }
}
