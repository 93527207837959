.act-hero h1,
.act-hero h1 span {
  width: 600px;
  max-width: 100%;
  font-size: 56px !important;
  line-height: 68px !important;
}

.dwp-sm-btn.act-sm-btn p {
  padding-top: 0px;
}

.dlm-body {
  padding: 70px 100px 120px 200px;
}

.act-hero {
  padding: 20px 0 70px 200px !important;
  overflow: hidden;
}

.act-body-1 {
  /* height: 350px; */
  margin-bottom: 120px;
}

.activation-bodyimage {
  width: 400px;
}

.act-img {
  margin-left: 150px;
  margin-bottom: 50px;
}

.act-img img {
  mix-blend-mode: multiply;
}

button.nav-link.more.supportmore {
  margin-right: 0px;
}

.act-sm-btn {
  width: fit-content;
  display: flex;
  border: 1px solid #565656;
  border-radius: 50px;
  padding: 4px 10px;
}

/* .activation-hero-img {
  position: absolute;
  /* right: 0; */
/* top: 80px; */
/* }  */

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .act-img img {
    width: 400px;
  }

  .act-body {
    padding: 70px 50px 70px 50px !important;
  }

  .act-hero-content-col {
    width: 50%;
  }

  .act-hero {
    padding: 50px 0px 70px 70px !important;
  }

  .act-hero p {
    font-size: 14px;
  }

  .act-body-1 {
    height: auto !important;
    margin-bottom: 50px;
  }

  .act-img {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .act-hero {
    padding: 50px 20px 0px 20px !important;
  }

  .activation-hero-img {
    width: 85%;
    margin: auto;
    position: static;
  }

  .act-body-1 {
    margin-bottom: 0px;
  }

  .act-img {
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .act-img img {
    width: 85%;
    margin: auto;
  }

  .activation-bodyimage {
    width: 100%;
  }
}

.act-hero h1 {
  width: 600px;
  max-width: 100%;
}

.act-hero {
  padding: 70px 0 70px 200px;
}

/* .act-body-1 {
  height: 380px;
} */

.activation-bodyimage {
  width: 90%;
}

.act-img {
  display: flex;
  justify-content: end;
}

.act-img img {
  mix-blend-mode: multiply;
}

.act-body {
  padding: 70px 200px 120px 200px;
}

@media only screen and (max-width: 767px) {
  .duoapp-body2 .col:nth-child(2) {
    margin: 80px 0px 0px 0px !important;
  }

  .act-hero {
    padding: 20px 20px 0px 20px !important;
  }

  .act-hero h1,
  .act-hero h1 span {
    width: 100% !important;
    font-size: 35px !important;
    line-height: 45px !important;
  }
}

@media (min-width: 1530px) {
  /* .activation-hero-img {
    top: 110px;
  } */
}

@media (max-width: 1440px) {
  /* .activation-hero-img {
    top: 50px;
  } */

  .act-hero {
    padding: 20px 0 70px 200px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .act-hero {
    padding: 20px 80px 70px 80px !important;
  }

  .act-body {
    padding: 100px 80px 0 80px !important;
  }

  .activation-hero-img {
    position: static !important;
  }

  .act-img {
    margin-left: 0px !important;
  }

  .dlm-body {
    padding: 70px 80px 0px 80px !important;
  }
}