.store-hero {
  padding: 40px 15px 70px 200px;
}

.business-payment-body-1.montrastoreoffered {
  padding: 60px 200px;
}

.row.personalb.montralb {
  margin-top: 0px;
}

.business-payment-method.montrastore-method {
  width: 100%;
  height: auto;
  padding-right: 10px;
}

.business-payment-body-1.montrastoreoffered2 {
  padding: 0px 200px 75px 200px;
}

.store-icon {
  margin-bottom: 20px;
}

.store-hero2 img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
  max-width: 100%;
  padding-top: 60px;
}

.store-hero h1 {
  color: #20336b;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  width: 750px;
}

.store-sm-btn {
  width: fit-content !important;
  border: 1px solid #20336b;
  border-radius: 50px;
  display: flex;
  padding: 3px 10px;
}

.store-sm-btn p {
  margin-bottom: 0px !important;
  padding: 0px !important;
  padding-left: 6px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding-top: 2px !important;
  color: #20336b;
  width: auto !important;
}

.business-payment-body-1.montrastoreoffered2.securemontra {
  padding-bottom: 180px !important;
}

.store-icon {
  padding-left: 8px;
}

p.dont {
  padding-top: 10px;
}

p.dont {
  padding-top: 10px;
}

p.donthave {
  padding-top: 10px;
}

.store-hero p {
  color: #20336b;
  margin-bottom: 0px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 0 0;
  width: 650px;
  padding-top: 10px;
}

.business-payment-method.montrastore-method div {
  height: auto;
}

.business-store-topdescription {
  margin: 15px 0px 15px 0px !important;
}

@media screen and (max-width: 768px) {
  .store-hero h1 {
    width: 100%;
  }

  .store-hero p {
    width: 100%;
  }
}

.store-hero p:nth-child(1) {
  color: #20336b;

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
}

.store-hero h1 span {
  color: #20336b;

  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.s-icon {
  background: #fff;
  padding: 3px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
}

.s-icon svg {
  margin-top: 11px;
  margin-left: 4px;
  width: 40px;
}

.montrastoreoffered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-hero-left-image {
  padding: 10px 0;
}

@media only screen and (max-width: 767px) {
  .store-hero-left-image {
    /* width: 100% !important; */
    margin-bottom: 10px;
  }

  .business-store-body-1 h1 {
    font-size: 30px !important;
  }

  .store-hero h1,
  .store-hero h1 span {
    font-size: 35px;
    line-height: 43px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .store-hero h1,
  .store-hero h1 span {
    font-size: 40px;
    line-height: 53px;
  }

  .store-hero {
    padding: 20px 0px 20px 20px;
  }

  .store-hero p,
  .store-hero h1 {
    width: 100% !important;
  }

  .store-herpo-content {
    width: 58%;
  }

  .app-download img {
    width: 25% !important;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .store-hero p,
  .store-hero h1 {
    width: 100% !important;
  }

  .app-download img {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .store-hero {
    padding: 50px 0px 70px 80px !important;
  }

  .business-payment-body-1.montrastoreoffered {
    padding: 60px 100px;
  }

  .business-payment-body-1.montrastoreoffered2 {
    padding: 0px 100px 60px 100px;
  }
}
