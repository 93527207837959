.p-2.trial,
.p-2.trial2,
.p-2.trial3,
.p-2.trial4,
.p-2.md-trial,
.p-2.md-tria2,
.p-2.md-trial3,
.p-2.row-trial1,
.p-2.row-trial2,
.p-2.row-trial3,
.p-2.collform {
    margin-top: 0px;
    margin-left: 0px;
}

label.form-control.loanlabelcheck {
    color: #fff !important;
    line-height: 12px;
    font-size: 11px;
    padding-top: 3px;
    align-items: center;
    display: flex;
    height: 46px;
    margin-top: -2px;
    font-weight: 600;
    background-color: gray !important;
    margin-bottom: 5px !important;
}

.productheader.baseinterest {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.productheader.baseten {
    display: block;
    margin-left: auto;
    margin-right: auto;
}