.usertextmak {
  font-size: 15px;
  font-family: Arial;
  font-weight: 600;
  padding-top: 5px;
  padding-left: 11px;
  padding-bottom: 7px;
}

.p-2.managemak1,
.p-2.managemak2,
.p-2.managemak3,
.p-2.managech3,
.p-2.managecheck4 {
  margin-top: 0px;
  margin-left: 0px;
}

.container-fluid.undercontainer {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.manageheader {
  font-weight: 600;
  font-size: 16px;
  font-family: Arial;
  color: #2c007d;
  text-align: center;
  padding-bottom: 10px;
}

.usertextmak {
  font-size: 15px;
  font-family: Arial;
  font-weight: 600;
  padding-top: 5px;
  padding-left: 11px;
  padding-bottom: 7px;
}

.p-2.managemak1 {
  width: 5%;
  padding: 0.08rem !important;
}

.p-2.managemak2 {
  width: 17%;
}

.p-2.managemak3 {
  width: 5%;
}

.heightmak {
  height: 35px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.serial-header {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  padding-top: 10px;
  text-align: center;
  display: block;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

label.usertheadermak {
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #000;
  /* padding-top: 10px; */
  text-align: center;
  display: block;
  margin-bottom: 0;
  padding-bottom: 10px;
}

label.usertheadermak.usname2 {
  padding-top: 12px;
}

label.usertheaderch.usnamemak1 {
  padding-top: 17px;
}

.container-fluid.undercontainer {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.btn.btn-success.removal {
  padding: 5px 9px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.form-group.togglemanagemak2 {
  height: 35px;
  padding-top: 2px;
}

.form-group.togglemanagemak {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  margin-top: 0px;
}

label.usertheaderch.actmanage {
  padding-bottom: 6px;
}

textarea.form-control {
  height: 35px !important;
  vertical-align: middle;
  resize: none;
}

textarea.form-control.formproduct.margn.desk {
  padding-top: 9px !important;
  padding-right: 2px !important;
}

.form-control.formselectblue {
  background-color: #dcf4fb;
  color: #555;
  padding-right: 19px;
}

.formselectblue {
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
}

.btn.btn-warning.addbuttonmore {
  background-color: #26abe7;
  border-color: #26abe7;
  padding: 8px 16px;
  margin-left: 26px;
  margin-top: 21px;
  font-size: 13px;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}

.usertextmak2,
.usertextmak7 {
  font-size: 14px;
  font-weight: 600;
}

.usertextmak2 {
  padding-top: 15px;
  padding-left: 11px;
  padding-bottom: 7px;
}

.d-flex.flex-row.usercuwmak {
  background-color: #ececec;
}

.usertextmak7 {
  padding-top: 5px;
  text-align: center;
  padding-bottom: 0;
}

.pafucmaker {
  width: 90.1%;
  padding-left: 5px;
}

.pafucmaker {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ececec;
  padding-bottom: 10px !important;
}

.p-2.managech3,
.p-2.managecheck4 {
  padding-top: 11px !important;
  width: 10%;
  text-align: center;
  background-color: #fff;
  margin-left: 8px !important;
}

.form-group.form-field,
form.formroot.managemat {
  margin-bottom: 15px;
}

.form-control.formselectmanage {
  background-color: #5bb7e7;
  color: #20336b;
  font-weight: 600;
  font-family: Arial;
  font-size: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  width: 100%;
  text-align: center;
  text-align-last: center;
}

.form-group.productlabelname {
  width: 12.5%;
  margin-bottom: 0;
  text-align: center;
}

.form-group.actiflag {
  width: 5%;
  position: absolute;
  padding-top: 40px;
}

label.usertheaderch {
  padding: 10px;
  padding-bottom: 7px;
}

label.usertheaderch.actmanage {
  padding-bottom: 9px;
}

label.usertheadermak.usname2 {
  margin-bottom: 5px;
}

.p-2.managech3,
.p-2.managecheck4 {
  padding-top: 11px !important;
  width: 5%;
  text-align: center;
  background-color: #fff;
  margin-left: 8px !important;
}

.p-2.managecheck4 {
  margin-left: 0px !important;
}

.managebox {
  width: 20%;
  display: block;
  margin-top: 75px;
  background-color: #26abe7;
  border-radius: 13px;
  padding: 15px 28px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #26abe7;
}

section.usersectionmange {
  padding-top: 35px;
}

.heightch {
  height: 46px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
}

.serialch {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

.managemak3-activation {
  display: flex;
  align-items: center;
  justify-content: center;
}

input:disabled+.slider {
  background-color: #dadada !important;
}

input:disabled+.slider:after {
  color: #555555;
}

button.btn.btn-success.removal:disabled {
  background-color: #d0d0d0;
  color: #555;
  cursor: not-allowed;
  opacity: 100;
  border-color: #d0d0d0;
}

@media (min-width: 320px) {
  .p-2.managemak1 {
    width: 5.5%;
    padding: 0.08rem !important;
  }

  .p-2.managemak3,
  .p-2.managemak2 {
    width: 13.5%;
    padding: 0.08rem !important;
  }

  label.usertheadermak.makeruser {
    width: 69%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  label.switch.toggleswitch {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .switch {

    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .form-group.togglemanagemak {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
  }
}

@media (min-width: 600px) {
  .managemak3-activation label.usertheaderch.actmanage {
    padding-top: 0em;
    padding-bottom: 3px;
  }

  .p-2.managemak2.managemakemail {
    width: 21%;
  }

  .p-2.managemak2 {
    width: 16%;
  }

  .p-2.managemak3 {
    width: 5%;
  }

  .p-2.managemak1 {
    width: 5%;
  }
}

@media only screen and (min-width: 1025px) {
  .form-control.formproduct {
    font-size: 0.9em !important;
  }

  select.form-control.formselectblue {
    font-size: 0.9em;
  }

  textarea.form-control.formproduct.margn.emaildesk {
    padding-top: 0px;
    padding-right: 2px !important;
    font-size: 0.9em !important;
    line-height: 2.5;
    overflow: hidden !important;
  }

  label.usertheadermak {
    font-size: 0.9em !important;
  }
}

@media only screen and (max-width: 768px) {
  label.usertheadermak.designation {
    padding-top: 0px;
    width: 100%;
  }

  label.usertheadermak.email,
  .usertheadermak.designation.mobno {
    padding-bottom: 0px;
  }
}