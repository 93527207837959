.duo-body {
  background: #f6f9fc;
  padding: 70px 200px;
}

.duo-lists.overviewlists {
  margin-bottom: 30px;
  padding-left: 10px;
}

.col-md-6.act-hero-content-col {
  margin-top: 30px;
}

.duo-body-h1 {
  color: #101010;
  text-align: center;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 925px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.duo-list {
  display: flex;
  margin-bottom: 5px;
}

.duo-list-box {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background: #425466;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 0px;
  border-radius: 5px;
}

img.duo-list-image {
  margin-left: 8px;
  margin-bottom: 10px;
}

.duo-home-body h6 {
  color: #000;
  padding: 0px 0px 0px 0px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.duo-lists.overviewlists p {
  font-size: 15px;
}

p.dusubsec {
  font-size: 15px;
  padding-left: 9px !important;
  font-weight: 500 !important;
}

.duo-list p {
  color: #425466;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  width: 735px;
}

.duo-home-body {
  display: grid;
  justify-content: center;
  margin: auto;
}

.duo-list-container {
  margin-top: 15px;
  width: 756px;
}

.duo-list-container p {
  width: 741px;
  font-weight: 400;
  padding-left: 0px;
}

.duo-body2 {
  padding: 70px 200px;
}

.duo-body-2 p {
  font-weight: 500;
  color: #425466;
  font-size: 18px;
}

.duo-body2 h6 {
  color: #101010;
  padding-bottom: 5px;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.duo-body2 h1 {
  text-align: center;
  color: #101010;

  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.duo-body2 button {
  border-radius: 8px;
  background: #000;
  padding: 8px 15px;
  color: #fff;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.duo-body-2 {
  display: flex;
  margin-top: 30px;
}

.duo-body-3 {
  display: flex;
}

.duo-body-3 h6 {
  /* color: #425466; */
  color: black;
  padding-bottom: 8px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.duo-body-3 p {
  color: #425466;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.combinedexplainer {
  margin-top: 60px !important;
}

.duo-body-2 div:nth-child(2) {
  margin-top: 80px;
  margin-left: 50px;
  width: 400px;
}

.duo-body2 {
  display: grid;
  justify-content: center;
  margin: auto;
}

.duo-body3 h1 {
  color: #101010;
  text-align: center;

  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.duo-body3 {
  background: #f6f9fc;
  padding: 70px 200px 170px 200px;
}

.duo-body-3 button {
  border-radius: 8px;
  background: #ffc000;
  padding: 8px 15px;
  border: none;
  color: #101010;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.duo-body-3 div:nth-child(2) {
  margin-top: 20px;
  margin-left: 50px;
}

.duo-body-3 {
  margin-top: 50px;
}

.duo-img {
  width: 463px;
  height: 254px;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

/*duo overview styles */
.duo-overview-hero {
  padding: 10px 100px 150px 200px;
}

.duo-hero-img {
  display: flex;
  justify-content: end;
  margin: auto;
  max-width: 80%;
}

.duohero2 {
  display: block;
}

.duo-hero-img2 {
  width: 80%;
}

.duohero2 img {
  width: 60%;
}

.duohero2 button {
  margin-top: 20px;
  border: none;
  border-radius: 8px;
  background: #ffc000;
  color: #101010;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 10px 15px;
}

.duo-overview-h1 {
  color: #101010;

  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.duo-overview-span {
  color: #101010;

  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.duo-hero-img {
  display: flex;
  justify-content: end;
  margin: auto;
}

.duo-overview-p {
  color: #425466;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 0px 0 10px 0;
}

.duohero2 {
  display: block;
}

.duo-hero-img2 {
  width: 80%;
}

.duo-hero-button {
  margin-top: -10px;
  border: none;
  border-radius: 8px;
  background: #ffc000;
  color: #101010;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 10px 15px;
}

.duo-process-flow {
  margin-top: -45px;
}

.activation-icon,
.duo-app-icon {
  width: 35% !important;
}

.duo-util-down-arrow {
  left: 13px;
}

.duo-arrow {
  margin: 0 -25px;
}

.duo-icons {
  width: 50% !important;
}

.duo-topdescription {
  width: 10% !important;
  text-align: center !important;
  margin-bottom: 5px !important;
}

.duo-process-flow .circleimg img {
  width: auto;
}

@media screen and (max-width: 767px) {
  .duo-overview-hero {
    padding: 20px !important;
  }

  .duo-img {
    width: 100%;
    height: 100%;
  }

  .duo-hero-img2 {
    width: 100%;
  }

  .duo-overview-h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .duo-overview-span {
    font-size: 35px;
    line-height: 45px;
  }

  .duo-overview-p {
    font-size: 15px;
  }

  .duo-hero-button {
    font-size: 16px;
    margin-top: 15px;
    line-height: normal;
  }

  .duohero2 img {
    width: 100%;
  }

  .duo-hero-img {
    width: 100% !important;
  }

  .duo-body {
    padding: 40px 20px !important;
  }

  .duo-body-h1 {
    font-size: 24px;
  }

  .duo-list p {
    font-size: 14px;
  }

  .duo-body2 h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .duo-body2 h6 {
    font-size: 18px;
  }

  .duo-body2 p {
    font-size: 14px;
  }

  .duo-body3 h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .duo-body3 p {
    font-size: 13px;
  }

  .duo-list-container p {
    text-align: left;
  }

  .duo-body-2 div:nth-child(2) {
    margin: 0px 0px 0px 0px !important;
    padding: 20px 30px 0px 20px !important;
  }

  .duo-body-3 div:nth-child(2) {
    margin: 0px 0px 0px 0px !important;
    padding: 20px 30px 0px 20px !important;
  }

  .duo-body {
    padding: 20px 20px !important;
  }

  .duo-home-body {
    margin-top: 20px;
  }

  .duo-home-body h6 {
    padding: 0px 0px 0px 0px;
  }

  .duo-list-image {
    width: 40px;
  }

  .duo-process-flow {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .duo-overview-h1 {
    font-size: 40px;
    line-height: 55px;
  }

  .duo-overview-span {
    font-size: 40px;
    line-height: 55px;
  }

  .duo-body {
    padding: 50px 50px !important;
  }

  .duo-body2 h1 {
    font-size: 30px;
  }

  .duo-hero-img {
    width: 100%;
  }

  .duo-frame {
    max-width: 100% !important;
    height: auto !important;
  }

  .dts-list {
    height: auto;
  }

  .duo-body-2 div:nth-child(2) {
    margin: 30px 150px 0px 150px !important;
    /* width: 100% !important; */
  }

  .duo-body2 h1 {
    font-size: 25px;
  }

  .duo-body3 h1 {
    font-size: 30px;
  }

  .duo-body-3 div:nth-child(2) {
    margin: 30px 150px 0px 150px !important;
  }

  .duohero2 img {
    width: 100%;
  }

  .duoheroimg2 {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .duo-body-2 {
    align-items: center;
  }

  .combinedexplainer {
    margin-top: 10px !important;
  }

  .duo-body-3 div:nth-child(2) {
    width: 400px;
  }

  .duo-hero-button {
    margin-top: 20px;
  }

  .duo-body-2 div:nth-child(2) {
    margin-top: 0px;
  }

  .dts-body2 {
    padding: 30px 150px 30px 150px;
  }

  .dcp-frame-2 {
    margin-left: 0px !important;
  }

  .duo-lists.dts-list {
    padding-left: 0px;
    padding-right: 0px;
  }

  img.dwpimgall {
    max-width: 100%;
  }

  .dwp-img {
    width: 50%;
  }

  .act-hero {
    padding-left: 20px !important;
  }

  .act-img {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .duo-overview-hero {
    padding: 20px 20px 50px 20px;
  }

  .duo-overview-h1 {
    font-size: 40px;
    line-height: 55px;
  }

  .duo-overview-span {
    font-size: 40px;
    line-height: 55px;
  }

  .duo-hero-img {
    width: 100%;
  }

  .duo-body {
    background: #f6f9fc;
    padding: 70px 70px;
  }

  .duo-body-h1 {
    margin-bottom: 50px;
  }

  .duo-body3 {
    background: #f6f9fc;
    padding: 70px 70px;
  }

  .duo-body-3 {
    align-items: center;
  }

  .duo-body-3 p {
    font-size: 14px;
  }

  .duoheroimg2 {
    width: 100%;
  }

  .duo-overview-hero .duo-process-flow {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .duo-overview-hero {
    padding: 10px 80px 100px 80px !important;
  }

  .hero-row {
    align-items: start !important;
  }

  .dts-hero h1,
  .dts-hero h1 span {
    font-size: 40px !important;
    line-height: 55px !important;
  }

  .duo-body {
    padding: 70px 80px !important;
  }

  .duo-overview-image-col {
    display: flex !important;
    justify-content: end !important;
  }

  .duo-overview-hero .duo-process-flow {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .duo-overview-hero .duo-process-flow {
    margin-top: 0px !important;
  }
}