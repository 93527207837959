/* src/components/Sitemap.css */

.sitemap-wrapper {
    margin: 0 auto;
    color: #333;
}

.pageNotfound-page {
    background-color: #fff;
}

.pageNotfound {
    background: #f6f9fc;
    padding: 10px 0px 70px;
    position: relative;
    z-index: 999;
}

.pagenotfoundDescription {
    padding: 0px 200px;
}

.sitemap-page:before {
    background-color: #f6f9fc;
    height: 200px;
}

.pagenotfound-title {
    text-align: left;
    font-size: 2.5rem;
    padding: 80px 200px 20px;
    color: #333;
    font-weight: 600;
}

a.pagenotfoundlink {
    text-decoration: none;
}

.sitemap-grid {
    display: flex;
    gap: 40px;
    border-bottom: 1px solid #e1cece;
    border-bottom-style: dotted;
    margin-bottom: 40px;
}

.sitemap-section {
    margin-bottom: 40px;
}

.sitemap-section h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #000;
    font-weight: 600;
}

.sitemap-section ul {
    list-style: none;
    padding: 0;
}

.sitemap-section ul li {
    margin-bottom: 10px;
}

.sitemap-section ul li a {
    text-decoration: none;
    font-size: 1rem;
    color: #007bff;
    /* Blue color for links */
}

.sitemap-section ul li a:hover {
    text-decoration: underline;
    color: #0056b3;
}

.sitemap-section.sitemap1 {
    width: 23%;
    display: flex;
    align-items: center;
}

.sitemap-section.sitemap2 {
    width: 30%;
}

.sitemap-section.sitemap3 {
    width: 24%;
}

.sitemap-section.sitemap4 {
    width: 23%;
}

h4.sitemapheader {
    font-size: 16px;
    font-weight: 600;
}

/* For smaller screens, adjust the layout to be single-column */
@media (max-width: 768px) {
    .sitemap-grid {
        grid-template-columns: 1fr;
        /* Single column on small screens */
    }
}