/**
  collectionstyle.css is render all stylesheet in collection section
 Author : Seema Kumari

 @version :1.0 */

.collactiontext {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}
button.downloadtext.collectiondown {
  font-size: 15px;
}

.collactiontext1 {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.collectionheadertext {
  margin-top: 7px;
  margin-bottom: 20px;
}

.collectionsec {
  padding: 15px;
  background-color: #192859;
  color: white;
  height: 500px;
}

.downloadreport {
  color: #e69f45;
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
  text-align: center;
  padding-top: 18px;
}

.collectionsec2 {
  padding: 15px;
  background-color: gray;
  color: white;
  height: 500px;
}

.collactiontext2 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
  line-height: 1.1rem;
}

.row.holdsec {
  padding-top: 60px;
}

.row.holdsec3 {
  padding-top: 40px;
}

.reportcols {
  text-align: center;
  font-weight: 600;
}

.row.holdsec4 {
  padding-bottom: 45px !important;
}

.row.reportrow {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.col-6.reportlist2 {
  padding: 0px;
}

.col-6.reportlist2 {
  padding: 0px;
  font-size: 15px;
  font-family: Arial;
  font-weight: 600;
}

.collectionlablename {
  display: inline-block;
  max-width: 100%;
  padding-top: 0px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
  padding-top: 15px;
}

.collectionlablename2 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.reportcols,
.reportlist {
  padding-left: 35px;
  display: inline-block;
}

.listreport {
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.reportlist {
  text-align: left;
}

/* Media-query :- It uses the @media rule to include a block of CSS properties only if a certain condition is true.
Using media queries are a popular technique for delivering a tailored style sheet
to desktops, laptops, tablets, and mobile phones (such as iPhone and Android phones). */

@media only screen and (max-width: 414px) {
  .lablename.fc {
    text-align: left;
  }

  .lablename.allocated {
    text-align: left;
  }

  .collectionsec {
    height: auto !important;
  }

  .collectionsec2 {
    height: auto !important;
  }

  .portfoliocols {
    padding: 0px;
  }

  .lablename.against {
    text-align: left;
  }

  .reportcols {
    text-align: center;
    font-weight: 600;
    padding-left: 0px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .collectionsec2 .row.holdsec4 {
    padding-bottom: 0px !important;
  }
  .row.collsec2 {
    padding-bottom: 0px !important;
  }
  .collectionsec {
    height: 730px !important;
  }
  .collectionsec2 {
    height: 440px !important;
  }
  .collectionsec3 {
    height: 560px !important;
  }

  .portfoliocols .collectionsec2.collectionsec3 {
    height: 620px !important;
  }

  .portfoliocols .collectionsec2 {
    height: 500px !important;
  }

  .portfoliocols .collectionsec {
    height: 800px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .row.collsec2 {
    padding-bottom: 95px;
  }
}

@media only screen and (max-width: 1280px) {
  .collectionlablename.amount {
    padding-top: 15px;
  }

  .collectionsec2 {
    height: 522px;
  }

  .collectionsec {
    height: 522px;
  }

  .lablename.allocated {
    padding-top: 0px !important;
  }

  .row.collsec2 {
    padding-bottom: 128px;
  }

  .row.holdsec4 {
    padding-bottom: 59px !important;
  }

  .collectionlablename.hold {
    padding-top: 0px !important;
  }

  .downloadreport {
    padding-top: 36px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .row.collsec2 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 1366px) {
  .lablename.allocated {
    padding-top: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .lablename.allocated {
    padding-top: 0px;
  }

  .collectionlablename.hold {
    padding-top: 10px;
  }
}

@media (min-width: 1360px) {
  .row.holdsec4 {
    padding-bottom: 45px !important;
  }
}

@media only screen and (width: 1366px) {
  .collectionlablename.hold {
    padding-top: 0px;
  }

  .row.collsec2 {
    padding-bottom: 120px;
  }

  .row.holdsec4 {
    padding-bottom: 75px !important;
  }

  .collectionlablename.amount {
    padding-top: 0px;
  }

  .downloadreport {
    padding-top: 50px;
  }

  .row.holdsec2 {
    padding-bottom: 142px;
  }

  .nibslablename {
    padding-top: 0px !important;
  }
}

@media only screen and (width: 1440px) {
  .row.collsec2 {
    padding-bottom: 100px;
  }

  .row.holdsec4 {
    padding-bottom: 72px !important;
  }

  .collectionlablename.amount {
    padding-top: 0px;
  }

  .downloadreport {
    padding-top: 33px;
  }

  .row.collsec2 {
    padding-bottom: 100px;
  }
}
/**
  collectionstyle.css is render all stylesheet in collection section
 Author : Seema Kumari

 @version :1.0 */

.collactiontext {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}
button.downloadtext.collectiondown {
  font-size: 15px;
}

.collactiontext1 {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.collectionheadertext {
  margin-top: 7px;
  margin-bottom: 20px;
}

.collectionsec {
  padding: 15px;
  background-color: #192859;
  color: white;
  height: 500px;
}

.downloadreport {
  color: #e69f45;
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
  text-align: center;
  padding-top: 18px;
}

.collectionsec2 {
  padding: 15px;
  background-color: gray;
  color: white;
  height: 500px;
}

.collactiontext2 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
  line-height: 1.1rem;
}

.row.holdsec {
  padding-top: 60px;
}

.row.collsec2 {
  padding-bottom: 90px;
}

.row.holdsec3 {
  padding-top: 40px;
}

.reportcols {
  text-align: center;
  font-weight: 600;
}

.row.holdsec4 {
  padding-bottom: 20px !important;
}

.row.reportrow {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.col-6.reportlist2 {
  padding: 0px;
}

.col-6.reportlist2 {
  padding: 0px;
  font-size: 15px;
  font-family: Arial;
  font-weight: 600;
}

.collectionlablename {
  display: inline-block;
  max-width: 100%;
  padding-top: 0px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
  padding-top: 15px;
}

.collectionlablename2 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.reportcols,
.reportlist {
  padding-left: 35px;
  display: inline-block;
}

.listreport {
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.reportlist {
  text-align: left;
}

/* Media-query :- It uses the @media rule to include a block of CSS properties only if a certain condition is true.
Using media queries are a popular technique for delivering a tailored style sheet
to desktops, laptops, tablets, and mobile phones (such as iPhone and Android phones). */

@media only screen and (max-width: 414px) {
  .lablename.fc {
    text-align: left;
  }

  .lablename.allocated {
    text-align: left;
  }

  .collectionsec {
    height: auto !important;
  }

  .collectionsec2 {
    height: auto !important;
  }

  .portfoliocols {
    padding: 0px;
  }

  .lablename.against {
    text-align: left;
  }

  .reportcols {
    text-align: center;
    font-weight: 600;
    padding-left: 0px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .collectionsec2 .row.holdsec4 {
    padding-bottom: 0px !important;
  }
  .row.collsec2 {
    padding-bottom: 0px !important;
  }
  .collectionsec {
    height: 730px !important;
  }
  .collectionsec2 {
    height: 440px !important;
  }
  .collectionsec3 {
    height: 560px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .collectionlablename.amount {
    padding-top: 15px;
  }

  .collectionsec2 {
    height: 522px;
  }

  .collectionsec {
    height: 522px;
  }

  .lablename.allocated {
    padding-top: 0px !important;
  }

  .row.collsec2 {
    padding-bottom: 128px;
  }

  .row.holdsec4 {
    padding-bottom: 59px !important;
  }

  .collectionlablename.hold {
    padding-top: 0px !important;
  }

  .downloadreport {
    padding-top: 36px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .row.collsec2 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 1366px) {
  .lablename.allocated {
    padding-top: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .lablename.allocated {
    padding-top: 0px;
  }

  .collectionlablename.hold {
    padding-top: 10px;
  }
}

@media only screen and (width: 1366px) {
  .collectionlablename.hold {
    padding-top: 0px;
  }

  .row.collsec2 {
    padding-bottom: 120px;
  }

  .row.holdsec4 {
    padding-bottom: 75px !important;
  }

  .collectionlablename.amount {
    padding-top: 0px;
  }

  .downloadreport {
    padding-top: 50px;
  }

  .row.holdsec2 {
    padding-bottom: 142px;
  }

  .nibslablename {
    padding-top: 0px !important;
  }
}

@media only screen and (width: 1440px) {
  .row.collsec2 {
    padding-bottom: 100px;
  }

  .row.holdsec4 {
    padding-bottom: 72px !important;
  }

  .collectionlablename.amount {
    padding-top: 0px;
  }

  .downloadreport {
    padding-top: 33px;
  }

  .row.collsec2 {
    padding-bottom: 100px;
  }
}
