.sup-payment-hero {
  /* padding: 70px 100px 70px 200px; */
  padding: 40px 20px 350px 200px;
  /* margin-bottom: 250px; */
}

.sup-payment-hero button {
  border-radius: 8px;
  background: #4abceb;
  border: none;
  color: #20336b;
  text-align: center;
  line-height: 145%;
  padding: 10px 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 0px;
}

.sup-point.subparagraph p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
}

.supplychain-row {
  align-items: center;
}

.sup-payment-hero h1 {
  color: #20336b;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
  letter-spacing: 0.5px;
}

.payment-hero {
  font-size: 48px !important;
}

/* .sup-payment-hero img {
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 0;
} */

.left-image-payment {
  position: absolute;
  /* left: 700px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ske-long-p {
  padding: 8px 7px 12px 7px !important;
}

.center-image-payment {
  position: absolute;
  left: 83%;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .sup-payment-hero {
    padding: 20px 20px !important;
    margin-bottom: 0px;
  }

  .supplychain h2 {
    padding-top: 0px;
  }

  .supply-body {
    padding: 30px 30px !important;
  }

  .sup-payment-hero h1 {
    font-size: 35px !important;
    line-height: 45px !important;
  }

  .supply-body h1 {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 768px) {
  .sup-payment-hero {
    padding: 20px 70px important;
  }

  .animation-class {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  .sup-payment-hero {
    padding: 20px;
  }

  .sup-payment-hero h1 {
    font-size: 35px;
    line-height: 50px;
  }

  .left-image-payment {
    display: none;
  }

  .animation-class {
    margin-left: 0px !important;
  }

  .center-image-payment {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .right-payment-col {
    width: 60%;
  }

  .sup-payment-hero {
    padding: 20px 20px 70px 80px;
  }

  .left-image-top img,
  .left-image-bottom img,
  .center-image-bottom img {
    width: 150px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1439px) {
  .sup-payment-hero h1 {
    font-size: 30px;
    line-height: 50px !important;
  }

  .left-image-payment {
    display: none;
  }

  .center-image-payment {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .left-image-payment {
    left: 800px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1498px) {
  .left-image-payment {
    left: 830px !important;
  }
}

@media only screen and (min-width: 1530px) and (max-width: 1589px) {
  .left-image-payment {
    left: 870px !important;
  }

  .sup-payment-hero {
    margin-bottom: 150px !important;
  }
}

@media only screen and (min-width: 1590px) and (max-width: 1629px) {
  .sup-payment-hero {
    margin-bottom: 100px !important;
  }
}

@media only screen and (min-width: 1630px) {
  /* .left-image-payment {
    left: 1030px !important;
  } */

  .collection-hero {
    margin-bottom: 50px !important;
  }

  .sup-payment-hero {
    margin-bottom: 150px !important;
  }

  .center-image-payment {
    left: 85%;
  }
}