.business-payment-body {
  margin-top: 200px;
}

.bank-body-section-2.bank-body-section-3.business-bank-body3 {
  background-color: #f6f9fc;
}

.business-payment-body-1 {
  position: relative;
  z-index: 999;
  padding: 50px 270px 65px 270px;
}

.business-payment-method-icon {
  background: #fff;
  padding: 3px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-payment-method-icon svg {
  /* margin-top: 11px;
  margin-left: 4px; */
  width: 50px;
}

.business-payment-method {
  margin-top: 30px;
  width: 470px;
}

.business-payment-method.business-payment-reward {
  margin-top: 20px;
}

.business-payment-method {
  height: 200px;
  width: 410px;
}

.business-payment-method div:nth-child(2) {
  margin-left: 0px;
  height: 175px;
}

.business-payment-body-1 h1 {
  color: #fff;

  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.business-payment-method h6 {
  color: #fff;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.business-payment-method p {
  color: #c8d0db;
  padding-left: 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.business-payment-body-1 .row {
  margin-top: 30px;
}

.business-payment-body-2 h1 {
  color: #fff !important;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.business-payment-table-1 td {
  color: #fff !important;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.payment-table-1 th {
  font-weight: 500;
}

.business-payment-table-1 .payment-table-row h6 {
  color: #fff !important;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.business-payment-table-1 .payment-table-row p {
  color: #c8d0db !important;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.business-payment-table-1 .payment-table-row-2 div {
  color: #fff !important;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.business-payment-reward {
  display: block !important;
}

.business-payment-reward div:nth-child(2) {
  margin-left: 0;
}

.paid-instantly {
  padding: 100px 200px;
}

.paid-instantly.paid-invoice {
  padding-bottom: 155px;
}

.paid-instantly h1 {
  text-align: center;
  color: #20336b;

  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.paid-instantly p {
  color: #20336b;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 30px 150px;
}

.paid-instantly-row {
  display: flex;
  justify-content: center;
  margin: auto;
}

.paid-instantly-row img {
  display: flex;
  justify-content: center;
  margin: auto;
}

.paycount {
  margin-top: 50px !important;
  margin-left: 45px;
}

.paycount1 {
  margin-top: 85px !important;
  margin-left: 45px;
}

.paid-instantly-row p {
  padding: 0;
  text-align: left;
  color: #20336b;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 45px;
}

.paid-instantly-row h6 {
  color: #20336b;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 45px;
  padding: 10px 0 0 0;
}

.paycount1-1 {
  margin-top: 50px !important;
}

.paid-invoice {
  background: #f6f9fc;
}

.paid-invoice img {
  display: flex;
  justify-content: center;
  margin: auto;
}

.paid-instantly {
  padding: 100px 200px;
}

.paid-instantly-2 {
  padding: 0 !important;
}

.horizontal-line {
  border-top: 2px solid rgba(216, 216, 216, 1);
  margin-top: 20px;
  margin-bottom: 20px;
  position: absolute;
  margin-top: 251px;
  width: 1005px;
}

.horizontal-line-2 {
  border-top: 2px solid rgba(216, 216, 216, 1);
  margin-top: 20px;
  margin-bottom: 20px;
  position: absolute;
  margin-top: 58px;
  width: 1005px;
}

@media only screen and (max-width: 767px) {

  .business-payment h1,
  .business-payment h1 span {
    font-size: 32px !important;
    line-height: 43px !important;
  }

  .business-payment img:nth-child(3) {
    padding-bottom: 20px !important;
  }

  .businesspaymentimg {
    margin-top: 30px;
  }

  .business-payment-body-1.agencyOffered {
    padding: 20px 20px !important;
  }

  .business-payment-body-1 h1 {
    font-size: 32px !important;
  }

  .business-payment-method-icon {
    margin-bottom: 5px;
  }

  .business-payment-method {
    margin-top: 0px !important;
  }

  .payment-body-section-3 {
    padding: 30px 20px !important;
  }

  .business-payment-body-2.business-payment-body-2-h1 {
    font-size: 27px !important;
  }

  .business-payment-body-1 {
    padding: 20px 20px !important;
    border-bottom: 0.5px solid #c8d0db;
  }

  .business-payment-method div:nth-child(2),
  .business-payment-method {
    height: auto !important;
    margin-bottom: 20px !important;
  }

  .paid-instantly {
    padding: 20px 20px !important;
  }

  .paid-instantly h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .paycount {
    margin-top: 20px !important;
  }

  .paycount1 {
    margin-top: 30px !important;
  }

  .payment-process-container {
    padding: 10px 0px 20px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .payment-body-section-3.business-payment-body-2 {
    padding: 20px !important;
  }

  .business-payment img:nth-child(3) {
    width: auto !important;
    padding-bottom: 30px !important;
  }

  img.businesspaymenticon {
    max-width: 100%;
    margin-top: 30px;
  }

  .business-payment {
    padding: 20px 20px 100px 20px !important;
  }

  .business-payment-body-1.agencyOffered {
    padding: 60px 20px !important;
  }

  .payment-process-container {
    padding: 10px 0px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 920px) {
  .business-payment {
    padding: 20px 20px 100px 20px !important;
  }

  .business-payment h1,
  .business-payment h1 span {
    font-size: 40px !important;
    line-height: 53px !important;
  }

  .business-payment-body {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 921px) and (max-width: 1024px) {
  .business-payment {
    padding: 20px 20px 20px 20px !important;
  }

  .business-payment h1,
  .business-payment h1 span {
    font-size: 40px !important;
    line-height: 53px !important;
  }

  .business-payment-body {
    margin-top: 100px;
  }
}