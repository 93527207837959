.backloan {
  padding-bottom: 120px;
}

.reduce {
  padding: 0px;
}

p.productheadermak.fee,
p.productheadermak.mak2 {
  width: 70%;
}

.p-2.productform,
.p-2.productp1,
.p-2.producttoggle,
.p-2.productinput,
.p-2.md-trial,
.p-2.md-trial2,
.p-2.productinput2nd,
.p-2.row-trial1,
.p-2.row-trial2,
.p-2.productinput3rd,
.p-2.productformcol,
.p-2.productinputcollection,
.p-2.collectionpt {
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.productform,
.p-2.productglcoloms,
.p-2.productinput2nd,
.p-2.productinputcollection,
.p-2.underwrite6,
.p-2.underwritepart,
.p-2.underwritepart6 {
  width: 20%;
  padding: 0.08rem !important;
}

.productcode {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.5em;
  padding-top: 3.4em;
}

.productcode,
.productcodemak {
  width: 100%;
  text-align: center;
}

.p-2.md-trial {
  display: none;
}

.p-2.md-trial2 {
  display: none;
}

.p-2.row-trial2 {
  display: none;
}

.p-2.row-trial1 {
  display: none;
}

.p-2.productp1,
.p-2.producttoggle {
  width: 10%;
  padding: 0.08rem !important;
}

.p-2.productp1 {
  width: 10%;
  padding: 0.08rem !important;
}

.p-2.managemak2,
.p-2.productinput {
  width: 15%;
  padding: 0.08rem !important;
}

.productcodemak {
  padding-top: 4.4em;
  font-family: Arial;
}

.productcodeloanmak {
  font-size: 11.5px;
  padding-top: 2.6em;
  font-family: Arial;
  font-weight: 600;
  margin-bottom: 3px;
}

p.productheadermak.mak4 {
  padding-top: 0em;
}

.basetenor {
  padding-top: 1.14em;
}

.productbutton {
  padding-top: 34px;
  padding-left: 5px;
}

.margn {
  margin-bottom: 3px;
  height: 35px;
}

label.form-control.margn.codadminpro {
  background-color: gray !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid gray;
  margin-bottom: 3px;
  font-size: 9px;
  border-radius: 2px;
  height: 35px;
}

.toolinfopro p {
  font-size: 11.5px !important;
  font-weight: 600;
  margin-bottom: 6px;
  color: #000;
  text-align: center;
  line-height: 1.2em;
}

.switchheight {
  height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  margin-bottom: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 8px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2ab934;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2ab934;
}

input:checked+.slider:before {
  transform: translateX(35px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:after {
  content: "OFF";
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 34px;
  height: 26px;
  right: 7px;
  top: 0px;
  font-size: 12px;
}

input:checked+.slider:after {
  content: "ON";
  left: 10px;
  right: auto;
}

/* end toggle */

label.productcode {
  padding-top: 4.4em;
  color: #000;
  font-family: Arial;
}

.bx--tooltip__label .bx--tooltip__trigger {
  margin-left: 0.1rem !important;
}

.form-control.formproduct {
  background-color: #dcf4fb;
  padding-right: 2px;
  text-align: center;
  padding-left: 2px !important;
  border: 1px solid #c1bebe;
  border-radius: 2px;
  color: #555;
  font-weight: 600;
  height: 35px;
}

.form-control.formselect,
.inputblue {
  background-color: #5bb7e7;
}

.form-control.formselect {
  color: #20336b;
  font-weight: 600;
  font-family: Arial;
  font-size: 11px;
  height: 35px;
  padding-left: 0 !important;
  padding-right: 16px !important;
  border: 1px solid #c1bebe;
  border-radius: 2px;
  text-align: center;
  padding-right: 0em !important;
}

input.form-control.formproduct.margn:disabled {
  background-color: #fafafa;
}

input.form-control.formpprofile:disabled {
  background-color: #fafafa;
}

input.form-control.formpprofile2:disabled {
  background-color: #fafafa;
}

select.form-control.formselect:disabled {
  background: #fafafa !important;
  color: #000 !important;
  border-color: #ddd !important;
}

.form-group.labelcolor-productmix label {
  font-size: 11.5px !important;
  font-weight: 600;
  margin-bottom: 3px;
}

.p-2.productinput3rd {
  width: 25%;
  padding: 0.08rem !important;
}

.toolinfopro {
  text-align: center;
}

p.productheadermak {
  width: 85% !important;
  padding-top: 1.2em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

p.productheader {
  width: 85% !important;
}

.formselect {
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  font-size: 16px;
}

.suggested-button {
  color: #192859;
  border-radius: 2px;
  border: 1px solid gray;
  background-color: #26abe7;
}

.p-2.collectionpt {
  margin-top: 14.5em;
  width: 60%;
}

.collectioncard {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: #26abe7;
  border: 1px solid #26abe7;
  border-radius: 13px;
  padding: 15px 28px;
}

.collectioncard label {
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  margin-bottom: 6px;
  color: #223872;
}

@media only screen and (max-width: 767px) {
  .loanlabelmak5 {
    font-size: 0.7em !important;
  }
}

@media only screen and (max-width: 1199px) {
  .p-2.md-trial {
    width: 20%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.row-trial2 {
    width: 10%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.row-trial1 {
    width: 22%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.md-trial2 {
    width: 10%;
    padding: 0.08rem !important;
    display: block;
  }
}

@media only screen and (max-width: 1366px) {
  .p-2.productp1 {
    width: 10%;
    padding: 0.08rem !important;
  }
}

@media (min-width: 320px) {
  .container-fluid.contenttext {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media (min-width: 1200px) {
  .productcode {
    padding-top: 4em;
  }
}

@media (min-width: 801px) {
  .productcode {
    font-size: 0.8em !important;
    padding-top: 1.4em;
  }
}

@media (min-width: 480px) {
  .productcode {
    font-size: 0.7em !important;
    padding-top: 3em;
  }
}

@media only screen and (max-width: 920px) {
  .productheader.procoll {
    padding-top: 5px !important;
  }

  .row-trial2 .pcode,
  .productcodechh {
    padding-top: 28px !important;
  }

  .long-text {
    padding-top: 62px !important;
  }

  .productheadermak.actducts {
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 1200px) {
  p.productheadermak {
    padding-top: 2em;
    width: 100% !important;
  }

  p.productheader {
    width: 100% !important;
  }

  .toolinfopro .productheadermak {
    padding-top: 33px;
  }

  .product.profile-label {
    padding-top: 30px;
  }

  .product-re.profile-label {
    padding-top: 8px;
    font-size: 12px !important;
  }

  .productheadermak.actducts {
    padding-top: 33px;
  }

  .productinput2nd .productheadermak {
    padding-top: 75px;
  }

  .long-text {
    padding-top: 75px;
  }

  .md-trial2 .pcode {
    padding-top: 89px;
  }

  .row-trial2 .pcode,
  .productcodechh {
    padding-top: 14px;
  }

  .basetenor,
  .productheaderma.mak4,
  .productheadermak.procoll {
    padding-top: 18px;
  }

  .switchheight {
    justify-content: center;
  }
}