.distribution p {
  color: #20336b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.distribution-hero {
  margin-bottom: 0px !important;
  padding-bottom: 80px;
}

.sup-sm-btn.distanalytics p {
  line-height: 12px;
}

.sup-sm-btn.distanalytics {
  padding: 4px 10px;
}

.distribution li {
  color: #20336b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.distribution ul {
  padding-left: 15px;
}

.sup-point p span {
  color: #4abceb;
  font-size: 800;
}

.supplychain-row {
  align-items: center;
}
.distribution-left-payment {
  bottom: 110px;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .animation-class {
    display: none;
  }

  .responsive-image {
    display: block !important;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .responsive-image {
    display: none !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .distribution-hero {
    font-size: 39px !important;
  }
}

@media only screen and (min-width: 1599px) {
  .distribution-hero {
    padding: 70px 20px 200px 200px !important;
  }

  .distribution-left-payment {
    margin-top: 50px;
    bottom: 20px;
    left: 930px;
  }
}

@media only screen and (min-width: 1630px) and (max-width: 1707px) {
  .distribution-left-payment {
    left: 930px;
  }
  .left-image-top img,
  .left-image-bottom img,
  .center-image-bottom img {
    width: 200px;
  }
}
@media only screen and (min-width: 1708px) {
  .distribution-left-payment {
    left: 990px;
    bottom: 170px;
  }
  .left-image-top img,
  .left-image-bottom img,
  .center-image-bottom img {
    width: 200px;
  }
}

@media only screen and (min-width: 1730px) {
  .distribution-left-payment {
    left: 1000px;
  }
}
