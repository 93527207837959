.payment-animation {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-right: 0;
}

.pay-ani-1 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 96px;
  height: 157px;
  border-radius: 8px;
}

.pay-ani-1 img {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 47px !important;
}
.pay-ani-4 img {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 20px !important;
}
.pay-ani-6 img {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 20px !important;
}
.pay-ani-7 img {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 20px !important;
}
.pay-ani-4 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
}
.pay-ani-5 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 157px;
  height: 96px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  margin-top: 35px;
}
.pay-ani-4 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 96px;
  height: 96px;
  border-radius: 8px;
}
.pay-ani-6 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  margin-left: -4px;
  margin-top: 12px;
}
.pay-ani-7 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 96px;
  height: 96px;
  border-radius: 8px;
}

.pay-ani-3 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 96px;
  height: 157px;
  border-radius: 8px;
}

.pay-ani-3 img {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 47px !important;
}
.pay-ani-8 img {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 27px !important;
}
.pay-ani-5 img {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 50px !important;
}

.pay-ani-flex {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 88px;
  margin-bottom: 70px;
  position: absolute;
}
.pay-ani-flex div:nth-child(2) {
  margin-top: -20px;
  margin-left: 30px;
}
.pay-ani-8 {
  border: 1px solid #c8d0db;
  padding: 10px;
  width: 216px;
  height: 120px;
  border-radius: 8px;
  margin-top: 235px;
}
.payment-animation p {
  opacity: 0;
  font-size: 12px;
}
.payment-animation span {
  opacity: 0;
}
.pay-ani-1 {
  margin-top: 200px;
}
.pay-ani-3 {
  margin-top: 200px;
}
.pay-ani-2 {
  margin: 0 50px;
}

.p-animation-1 .pay-ani-1 {
  background: #fff;
  border: none;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.p-animation-1 .pay-ani-5 {
  background: #fff;
  border: none;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.p-animation-1 .pay-ani-3 {
  background: #fff;
  border: none;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.p-animation-1 .pay-ani-4 {
  background: #fff;
  border: none;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.p-animation-1 .pay-ani-6 {
  background: #fff;
  border: none;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.p-animation-1 .pay-ani-7 {
  background: #fff;
  border: none;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.p-animation-1 .pay-ani-8 {
  background: #fff;
  border: none;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.p-animation-1 p {
  opacity: 1;
  text-align: center;
  font-size: 13px;
}
.p-animation-1 .pay-ani-1 img {
  width: 29px;
  height: 30px;
  margin-top: 10px !important;
}
.p-animation-1 .pay-ani-2 img {
  width: 29px;
  height: 30px;
  margin-top: 10px !important;
}
.p-animation-1 .pay-ani-3 img {
  width: 29px;
  height: 30px;
  margin-top: 10px !important;
}

.ani-svg img {
  position: absolute;
  margin-top: 89px !important;
  margin-left: 30px !important;
  opacity: 0;
}
.p-animation-1 .ani-svg img {
  opacity: 1;
}

.gap-24 {
  gap: 24px;
}

.qwerty:nth-child(1),
.qwerty:nth-child(4) {
  margin-top: 212px;
}
.qwerty:nth-child(3) {
  margin-top: 252px;
}

.mt36 {
  margin-top: 36px !important;
}

.mt82 {
  margin-top: 82px !important;
}

.mt94 {
  margin-top: 94px !important;
}

.ske {
  padding: 15px 10px 12px 10px;
  border: 1px solid #c8d0db;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  margin-top: 24px;
}
.ske-a {
  padding: 15px 10px 12px 10px;
  border: 1px solid #c8d0db;
  width: 96px;
  height: 96px;
  border-radius: 8px;
}
.ske-a p {
  opacity: 0;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  color: #0a2540;
}
.ske-a:hover {
  opacity: 1;
  border: 0;
  background-color: white;
  cursor: pointer;
}
.ske-a:hover p {
  opacity: 1;
}

.ske img,
.ske-a img,
.ske-sc img,
.ske-sc > svg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 35px;
  height: 40px;
}

.ske img,
.ske-a img,
.ske-sc img,
.ske-sc > svg,
ske p,
.long-p:hover {
  cursor: pointer;
}

.ske-sc-heightchange {
  padding-top: 5px !important;
}

.ske-sc-heightchange p {
  font-size: 9px !important;
}

.supplychain-row .ske p {
  font-size: 11px;
}

.supplychain-row .ske-ske-long-p {
  font-size: 9px !important;
}

.ske p {
  text-align: center;
  font-size: 12px;
  opacity: 0;
  line-height: 15px;
  margin-top: 0px;
  color: #0a2540;
}

.ske-sc {
  padding: 15px 10px 12px 10px;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 12.6px 25.2px -11.5733px rgba(50, 50, 93, 0.25),
    0 7.56px 15.12px -7.56px rgba(0, 0, 0, 10);
}

.ske-sc img {
  margin-top: initial;
}

.ske-sc p {
  text-align: center;
  font-size: 14px;
  line-height: normal;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #0a2540;
}

.mt96 {
  margin-top: 96px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mt82 {
  margin-top: 82px !important;
}

.mt36 {
  margin-top: 36px !important;
}

.ml-15 {
  margin-left: -15px !important;
}

.mt94 {
  margin-top: 94px !important;
}

.font12 p {
  font-size: 10px;
  font-weight: 700;
}
.ske-long-p {
  padding: 8px 7px 15px 7px !important;
  width: 100px;
  height: 100px;
}
.long-p {
  font-size: 8px !important;
}

.short-long-p {
  margin-top: 0px !important;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .ske {
    width: 90px;
    height: 90px;
  }

  .ske-sc {
    width: 90px;
    height: 90px;
    padding: 7px 5px 6px 5px;
  }

  .ske-sc > svg {
    width: 55px;
  }

  .ske-sc p {
    font-size: 10px;
  }

  .outlets {
    width: 70px;
    height: fit-content;
  }

  .ske-paddingTop {
    padding: 7px 5px 6px 5px;
    width: 90px;
    height: 90px;
  }
  .long-p {
    font-size: 8px !important;
  }

  .ml30 {
    margin-left: 0px !important;
  }
}

.left-image-payment {
  position: absolute;
  /* left: -160px; */
  /* bottom: -70px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-image {
  position: absolute;
  left: -160px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .left-image-top, img {
  width: 150px;
} */
.left-image-top img,
.left-image-bottom img,
.center-image-bottom img {
  width: 150px;
}

.center-image {
  position: absolute;
  left: 50%;
  margin-top: 30px;
}

.animation-class {
  display: flex;
  justify-content: end;
  /* margin-left: 253px; */
}
