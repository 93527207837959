.p-2.headertextcheck,
.p-2.managech,
.p-2.managech1,
.p-2.managech4 {
  margin-top: 0px;
  margin-left: 0px;
}

.form-control.formprulesch {
  margin-top: 2px;
  text-align: center;
  height: 45px !important;
}

.checkerWrittingRule #content-wrapper p {
  margin-bottom: 6px;
}

.spinner {
  text-align: center;
}

@media (min-width: 1200px) {
  label.labelpt-ch {
    font-size: 0.8em;
    padding-top: 4.4em;
  }
}