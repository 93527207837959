#business-overview {
  overflow: hidden;
}
.business-overview {
  overflow: hidden;
}

.bbussineshero {
  padding-bottom: 50px !important;
}

.sectionPadding {
  padding-top: 100px;
}

.business-pg-animation-right-image {
  position: relative;
  z-index: 1;
}

.business-pg {
  background-color: #f6f9fc;
  padding-top: 100px;
  padding-bottom: 100px;
}

.business-pg .business-pg-content {
  padding-bottom: 100px;
}

.business-pg .business-pg-content .business-pg-wrapper {
  padding-top: 0px;
  margin-top: 0px;
}

.business-pg .busineslist::marker,
.busineslist,
.business-pg-explore-btn,
.business-pg-heading {
  color: #20336b !important;
}

@media (max-width: 767px) {
  .personal-hero.bbussineshero {
    padding: 20px 20px 20px 20px !important;
  }
  .business-hero-left {
    padding-right: 0px !important;
  }

  .business-hero-h1-span,
  .business-hero-h1 {
    font-size: 31px !important;
    line-height: 43px !important;
  }

  .business-hero-p {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .col-md-6.personal-hero-left.businessleft p {
    margin: 20px 0px !important;
  }
  .business-hero-app-download-image img {
    width: 30% !important;
    margin-top: 30px !important;
  }
  .businesshero2-image {
    width: 90%;
  }
  .business-pg {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .business-pg .business-pg-content {
    padding-bottom: 0px;
  }
  .business-pg .business-pg-content .business-pg-wrapper {
    padding-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .businesshero2-image {
    width: 50% !important;
    margin-bottom: 30px !important;
  }

  .business-pg {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .business-pg .business-pg-content {
    padding-bottom: 0px;
  }
  .business-pg .business-pg-content .business-pg-wrapper {
    padding-top: 0px;
  }
  .col.PaymentGatewayAnimations {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .busineslheroimg {
    position: static !important;
    width: 50% !important;
  }
  .business-hero-h1,
  .business-hero-h1-span {
    font-size: 40px !important;
    line-height: 53px !important;
  }
  .business-overview {
    margin-top: 50px !important;
  }
}

.business-overview-topdescription {
  margin: 15px 0px 5px 0px !important;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .personal-hero.bbussineshero {
    padding-right: 0px;
  }
  .busineslheroimg {
    width: 50% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 920px) {
  .responsivebusiness-col {
    width: 100% !important;
  }
  .inventory1 {
    width: auto;
  }
}
