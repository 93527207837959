/**
  Businessstyle.css is render all stylesheet in Business section
 Author : Seema Kumari

 @version :1.0 */

label.lablename.outstand {
  margin-bottom: 5px;
}

.business0 {
  padding: 10px;
  background-color: #20336b;
  color: white;
}

.businessheader {
  margin-top: 7px;
  margin-bottom: 20px;
}

.businessheader2 {
  margin-top: 25px;
  margin-bottom: 20px;
}

.col-4.businesscoloums {
  padding-right: 0px;
  max-width: 30.333%;
}
.col-4.businesscoloums1 {
  padding-right: 0px;
  max-width: 28.333%;
}

.download {
  display: block;
  width: 100%;
  padding-bottom: 0px;
  text-align: right;
  /* display: flex;
  justify-content: end; */
}

.fas.fa-arrow-down {
  padding-right: 10px;
  font-size: 20px;
}

.businesstext {
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
  margin-top: 4px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* p.businesstext.current {
  margin-top: 30px;
} */

.downloadtext {
  color: #e69f45;
  font-size: 10px;
  font-family: Arial;
  font-weight: 600;
  background-color: transparent;
  border: none;
}

.p-2.businessflex {
  padding: 0.2rem !important;
  width: 21.5%;
}
.p-2.businessflex2 {
  padding: 0.2rem !important;
  width: 14%;
}

.form-control.businessgroup {
  margin-bottom: 5px;
}

.businessname {
  display: inline-block;
  max-width: 100%;
  padding-top: 16px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.paydayloan {
  text-align: right;
  padding-bottom: 0px;
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
  margin-bottom: 0px;
}

.business1 {
  padding: 10px;
  background-color: gray;
  color: white;
}

.business2 {
  padding: 10px;
  background-color: #24baf2;
  color: white;
}

.business3 {
  padding: 10px;
  background-color: #a1a3a4;
  color: white;
  margin-top: 10px;
}

.business4 {
  padding: 10px;
  background-color: #20336b;
  color: white;
  margin-top: 10px;
}

.business5 {
  padding: 10px;
  background-color: gray;
  color: white;
  margin-top: 10px;
}

.business6 {
  padding: 10px;
  background-color: #24baf2;
  color: white;
  margin-top: 10px;
}

.business7 {
  padding: 10px;
  background-color: #a1a3a4;
  color: white;
  margin-top: 10px;
}

.business8 {
  padding: 10px;
  background-color: #20336b;
  color: white;
  margin-top: 10px;
}

.business9 {
  padding: 10px;
  background-color: #20336b;
  color: white;
}

.business10 {
  padding: 10px;
  background-color: gray;
  color: white;
}

.business11 {
  padding: 10px;
  background-color: #24baf2;
  color: white;
}

.business12 {
  padding: 10px;
  background-color: #a1a3a4;
  color: white;
  margin-top: 20px;
}

.business13 {
  padding: 10px;
  background-color: #20336b;
  color: white;
  margin-top: 20px;
}

.business14 {
  padding: 10px;
  background-color: gray;
  color: white;
  margin-top: 20px;
}

.col-4.portfoliocols {
  padding-right: 0px;
}

.businesslablename {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
  width: 100%;
}

.businesslablename1 {
  display: inline-block;
  max-width: 100%;
  padding-top: 0px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
  width: 100%;
}

.businesslablename2 {
  display: inline-block;
  max-width: 100%;
  padding-top: 4px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.businesslablename3 {
  display: inline-block;
  max-width: 100%;
  padding-top: 3px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

.businesslablename4 {
  display: inline-block;
  max-width: 100%;
  padding-top: 4px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  font-family: Arial;
}

/* Media-query :- It uses the @media rule to include a block of CSS properties only if a certain condition is true.
Using media queries are a popular technique for delivering a tailored style sheet
to desktops, laptops, tablets, and mobile phones (such as iPhone and Android phones). */

@media only screen and (max-width: 1366px) {
  .businesslablename1 {
    font-size: 10px;
  }

  .businesslablename2 {
    font-size: 10px;
  }

  .businesslablename {
    font-size: 10px;
  }

  .businesslablename3 {
    font-size: 10px;
  }

  .businesslablename4 {
    font-size: 10px;
  }

  label.lablename.outstand {
    font-size: 10px;
  }
}

@media only screen and (max-width: 414px) {
  .col-sm-12.col-md-12.col-xl-4.portfoliocols {
    padding: 0px;
  }

  label.lablename.cust {
    font-size: 10px !important;
  }

  .business1 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .business2 {
    margin-bottom: 10px;
  }

  .businesstext {
    font-size: 10px !important;
  }
}

/* Portfolio */
.d-flex.flex-row.mt-3,
.inner-addon,
.mainback,
.sticky-top.sticky-head,
.switch {
}

.portfolio-business-report {
  position: relative;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.portfolio-business-report .businessflex2 {
  padding: 0.2rem !important;
  width: 14%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.businessflex2 .businesstext {
  margin-bottom: 0px;
}

.businessflex2 .businesstext.current {
  margin-top: 30px;
}

.portfolio-business-report .businessflex {
  padding: 0.2rem !important;
  width: 21.5%;
}

.form-group {
  margin-bottom: 3px;
}

.form-group .lablename {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  line-height: 15px;
}

.form-group .form-control {
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  width: 100%;
  height: 34px;
  padding-left: 5px;
  font-size: 12px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c1bebe;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.moneylink-project li,
p.cardmontra,
p.montratextthird,
ul.montralist {
  padding-bottom: 5px;
}

.business-wrapper .fas.fa-arrow-down {
  padding-right: 0px;
}

.business-wrapper .downloadtext {
  margin-bottom: 10px;
}

.business-wrapper .stpl.downloadtext {
  margin-right: -10px;
}

@media only screen and (min-width: 768px) {
  .business-wrapper .businessrow {
    flex-direction: row;
  }
}
