/**
  creditstyle.css is render all stylesheet in credit-uw section
 Author : Seema Kumari

 @version :1.0 */

.productheadersales.ttip {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 5px;
}

.css-1wjzr1s-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
}
.form-group.loanid {
  padding-top: 0px;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-dark.nav-header.montra-sidebar.navbar.navbar-expand-lg.navbar-light.bg-light {
  padding: 0.4rem 2rem;
}

.mr-auto.montralendingheader.navbar-nav {
  margin-right: 2px !important;
}

a.btn.btn-outline-danger.logout.nav-link {
  color: #fff !important;
  background-color: #2c007d;
  background-image: none;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  font-size: 14px;
}
.montra-logout-user {
  margin-top: 13px;
}

/* .montra-main-header {
    padding: 0.3rem 2rem;
} */

.container-fluid.loanrequest {
  padding-right: 30px !important;
  padding-left: 15px !important;
}

.container-fluid.cuwritting {
  padding-right: 15px !important;
  padding-left: 25px !important;
}

.row.loanback {
  background-color: #f2f2f2;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-left: 0px;
}

input.form-control.flexinput.apprequest {
  background-color: #24baf2;
  border-color: #24baf2;
}

label.lablenamegrid5.score {
  padding-top: 15px;
}

input.form-control.flexinput.tencolor {
  background-color: #bce9f9;
}

.far.fa-file-alt {
  font-size: 27px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 11px;
}

.p-2.manageptuw {
  width: 15%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.loanlabel2 {
  font-size: 9px;
  text-align: center;
  padding-top: 0px;
  color: #5bb7e7;
}

.breadcrumb-item.active {
  color: #21346b;
  font-size: 13px;
}

.loanlabel3 {
  font-size: 9px;
  text-align: center;
  padding-top: 24px;
  color: #5bb7e7;
  display: block;
  width: 122px;
}

.loanlabelheader {
  font-size: 12px;

  font-weight: 600;
  color: #000;
  text-align: center;
  padding-top: 0px;
}

button.pdfview {
  background-color: transparent;
  border: transparent;
  padding-left: 0px;
}

.loanlabel8 {
  font-size: 9px;
  text-align: center;
  padding-top: 8px;
  color: #5bb7e7;
  width: 122px;
}

.loanlabel5 {
  font-size: 9px;
  text-align: center;
  padding-top: 0px;
  color: #5bb7e7;
  display: block;
  width: 122px;
}

.form-group.loangroup1 {
  padding-top: 0px;
}

.p-2.managesn {
  width: 4%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

input#checker1 {
  display: none;
}

input#checker2 {
  display: none;
}

.loanlabel9 {
  font-size: 9px;
  text-align: center;
  padding-top: 32px;
  color: #5bb7e7;
}

/* view details style   */

.p-2.loanview {
  width: 18%;
  padding: 0.08rem !important;
  margin-bottom: 30px;
  margin-top: 0px;
  margin-left: 0px;
}

.lablename {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
}

.lable4thsec {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 11px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  width: 100%;
  line-height: normal;
}
.lable4thsec.delmounts {
  margin-bottom: 11px;
}

.formview1 {
  background-color: #20336b;
  color: white;
  padding: 10px 10px 10px 10px !important;
  height: 325px;
}

input.form-control.flexinput {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.viewheader {
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  color: #192859;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: transparent;
  border: none;
}

.formview2 {
  background-color: #20336b;
  color: white;
  padding: 10px 10px 10px 10px !important;
  margin-left: 5px;
  height: 325px;
}

.formview3 {
  background-color: #20336b;
  color: white;
  padding: 10px 10px 10px 10px !important;
  margin-left: 5px;
  height: 325px;
}

.p-2.loanviewbig {
  width: 46%;
  padding: 0.08rem !important;
  margin-bottom: 30px;
  margin-top: 0px;
  margin-left: 0px;
}

.formview4 {
  background-color: #20336b;
  color: white;
  padding: 10px 0px 9px 0px;
  margin-left: 5px;
  height: 325px;
}

.formflex {
  padding: 0px;
  padding-top: 10px;
}

.form-control.flexinput {
  margin-bottom: 6px;
}

.p-2.childview {
  width: 16.66%;
  padding: 0.1rem !important;
  margin-top: 0px !important;
  margin-left: 0px;
}

.p-2.childviewag2 {
  width: 100%;
  padding: 0.1rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

label.flexinput.agreinput {
  width: 16.3%;
  background-color: #d9d9d9;
  height: 34px;
  padding: 3px;
  font-size: 11px;
  color: gray;
  border-radius: 2px !important;
}

label.flexinput.agreinput3 {
  width: 16.3%;
  background-color: #bce9f9;
  height: 34px;
  padding: 3px;
  font-size: 11px;
  color: gray;
  border-radius: 2px !important;
}

input.form-control.flexinput.agreinput2 {
  margin-left: 3px !important;
  border-radius: 2px !important;
  background-color: #d9d9d9;
}

input.form-control.flexinput.agreinput4 {
  margin-left: 3px !important;
  border-radius: 2px !important;
  background-color: #bce9f9;
}

.col-12.viewdiv {
  padding-right: 10px;
}

.bottomtext {
  padding-left: 4px;
}

.d-flex.flex-row.secform {
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.lablename2 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 13px;
  font-weight: 600;
  color: white;
  font-size: 12px;
  padding-bottom: 1px;
}

.formviewrow {
  background-color: #192859;
  color: white;
  padding: 10px 10px 33px 10px;
}

.p-2.loanviewbig2 {
  width: 30%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.childview2 {
  width: 27%;
  padding: 0.1rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.childview3 {
  width: 19%;
  padding: 2px !important;
  margin-top: 0px;
  margin-left: 0px;
}

/* custom checkbox  */

.lablename3 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  padding-top: 6px;
}

.formview5 {
  background-color: gray;
  color: white;
  height: 280px;
  margin-left: 5px;
}

.formview6 {
  background-color: gray;
  color: white;
  height: 280px;
  padding-top: 10px;
}

.p-2.childview4 {
  width: 25%;
  padding: 0.1rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.loanbig {
  width: 40%;
  padding: 0.08rem !important;
  margin-bottom: 30px;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.loanviewpart2 {
  width: 15%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.childview3 {
  width: 50%;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.checkboxv {
  width: 19%;
  padding: 0px !important;
  padding-left: 2px !important;
  text-align: center;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.grid3 {
  width: 27%;
  padding: 0.1rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.form-group.lastgrid {
  padding-left: 5px;
  padding-right: 5px;
}

.p-2.loanviewbig5 {
  padding: 0.08rem 0.5rem 0.08rem 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.lablenamegrid {
  display: inline-block;
  max-width: 100%;
  font-weight: 600;
  color: white;
  font-size: 11px;
  padding-top: 58px;
}

.lablenamegrid2 {
  display: inline-block;
  max-width: 100%;
  font-weight: 600;
  color: white;
  font-size: 11px;
  padding-top: 11px;
}

.lablenamegrid1 {
  display: inline-block;
  max-width: 100%;
  font-weight: 600;
  color: white;
  font-size: 11px;
  padding-top: 0px;
}

@-moz-document url-prefix() {
  .lablenamegrid2 {
    display: inline-block;
    max-width: 100%;
    font-weight: 600;
    color: white;
    font-size: 11px;
    padding-top: 32px;
  }
}

@-moz-document url-prefix() {
  .round2 {
    padding-top: 141px !important;
  }
}

@-moz-document url-prefix() {
  .lablenamegrid3 {
    padding-top: 16px !important;
  }
}

@-moz-document url-prefix() {
  .lablenamegrid {
    display: inline-block;
    max-width: 100%;
    font-weight: 600;
    color: white;
    font-size: 11px;
    padding-top: 76px !important;
  }
}

.formview7 {
  background-color: gray;
  color: white;
  height: 280px;
  margin-left: 5px;
}

.formview9 {
  background-color: gray;
  color: white;
  height: 280px;
  margin-left: 5px;
  padding-top: 0px;
}

.lablenamegrid3 {
  display: inline-block;
  max-width: 100%;
  font-weight: 600;
  color: white;
  font-size: 11px;
  padding-top: 0px;
}

.lablenamegrid4 {
  display: inline-block;
  max-width: 100%;
  font-weight: 600;
  color: white;
  font-size: 11px;
  padding-top: 0px;
}

.gridview3 {
  background-color: #24baf2;
  color: white;
  padding: 10px 10px 25px 10px !important;
  height: 234px;
}

.btn.btn-primary.Approved {
  color: #fff;
  background-color: #20336b;
  border-color: #20336b;
  padding: 5px 0px 6px 0px;
  border-radius: 50px;
  display: block;
}

.p-2.viewlrequest1 {
  width: 20%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.viewlrequest2 {
  width: 20%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.viewlrequest3 {
  width: 20%;
  padding: 0.08rem 0.5rem 0.08rem 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.viewlrequest4 {
  margin-top: 76px;
}

.p-2.viewgridrequest {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-left: 0px;
}

.gridview4 {
  background-color: #24baf2;
  color: white;
  padding: 0px 0px 48px 0px;
  margin-left: 5px;
  height: 234px;
}

.gridview5 {
  background-color: #24baf2;
  color: white;
  padding: 0px 0px 146px 0px;
  margin-left: 5px;
  height: 234px;
}

.d-flex.flex-row.secform2 {
  padding-top: 65px;
}

.far.fa-file-pdf {
  font-size: 49px;
  color: #ff0000;
}

form.formpdf {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* End view details style   */

/*   Toggle button   */

.switchloan {
  position: relative;
  top: 77px;
  width: 160px;
  height: 69px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.switchloan input {
  display: none;
}

.sliderview {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.sliderview:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 10px;
  bottom: 19px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-radius: 50%;
  border: 1px solid #000;
}

input:checked + .sliderview {
  background-color: #fff;
}

input:focus + .sliderview {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderview:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(110px);
}

/*------ ADDED CSS ---------*/
.sliderview:after {
  content: "Approved";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-30%, -50%);
  top: 50%;
  left: 68%;
  font-size: 9px;
  font-family: Verdana, sans-serif;
  background-color: green;

  padding-top: 22px;
  border-radius: 48%;
  width: 59px;
  height: 59px;
}

input:checked + .sliderview:after {
  content: "Rejected";
  left: 29px;
  background-color: #e83334;
}

input.btn.btn-warning.creditbutton {
  color: #fff;
  background-color: #192859;
  border-color: #090908;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  width: 100%;
}

/*--------- END --------*/

ul.headerright {
  font-size: 12px;
  font-family: Arial Narrow;
  font-weight: 600;
  line-height: 1;
  padding-left: 950px;
}

ul.navbar-nav.signout {
  padding-left: 40px;
  padding-top: 20px;
}

.signouttext {
  font-size: 14px;
}

.fas.fa-sign-out-alt {
  font-size: 18px;
  padding-left: 7px;
}

section#content-wrapperss {
  padding-top: 15px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.55rem 1rem !important;
  margin-bottom: 1.5rem !important;
  list-style: none;
  background-color: #e9ecef !important;
  border-radius: 0.25rem;
  font-size: 12px;
  font-weight: 600;
}

.loanlabelheader {
  font-size: 12px;

  font-weight: 600;
  color: #000;
  text-align: center;
  padding-top: 0px;
  width: 70px;
}

.loanlabelbank {
  font-size: 9px;
  text-align: center;
  padding-top: 0px;
  color: #5bb7e7;
  width: 122px;
}

.form-group.loangroup3 {
  padding-top: 7px;
}

.productheadersales {
  font-size: 12px;

  font-weight: 600;
  color: #000;
  text-align: center;
}

.serialcuw {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.serial {
  font-size: 12px;
  font-weight: 600;
}

.lablenameview {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 14px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  width: 100%;
}

label.lable4thsec.due {
  margin-bottom: 0px;
}

.lablenameview2 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0px;
  font-weight: 600;
  color: white;
  font-size: 11px;
  padding-top: 7px;
}

.lablenameview3 {
  display: inline-block;
  max-width: 100%;
  padding-bottom: 0px;
  font-weight: 600;
  color: white;
  font-size: 11px;
}

.lablenamegrid5 {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
}

.lablename1st {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: white;
  font-size: 11px;
}

.loanview {
  padding: 0px;
}

.loanview1 {
  padding-left: 5px;
  padding-right: 0px;
}

.loanviewpart2 {
  padding-left: 5px;
  padding-right: 0px;
}

.loanviewbig2 {
  padding: 0px;
}

.loanbig {
  padding: 0px;
}

.loanviewbig5 {
  padding-left: 0px;
}

.col-sm-12.col-md-6.loanviewbig {
  padding-left: 0px;
}

.col-sm-12.col-md-2.viewlrequest1 {
  padding-left: 5px;
  padding-right: 0px;
}

.col-sm-12.col-md-2.viewlrequest2 {
  padding: 0px;
}

.col-sm-12.col-md-2.viewlrequest3 {
  padding: 0px;
  padding-top: 10px !important;
}

.p-2.managecus {
  width: 16%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

p.lablename.pdfdown {
  line-height: 15px;
}

.backloan {
  padding-bottom: 120px;
}

.backloan2 {
  padding-bottom: 320px;
}

.round {
  text-align: center;
  padding-top: 5px;
}

.round2 {
  padding-top: 85px;
}

.viewdiv form {
  padding: 5px !important;
}

.cuwritting .form-group .lablename {
  line-height: normal;
  margin-bottom: 0px;
}

/* Media-query :- It uses the @media rule to include a block of CSS properties only if a certain condition is true.
Using media queries are a popular technique for delivering a tailored style sheet
to desktops, laptops, tablets, and mobile phones (such as iPhone and Android phones). */

@media only screen and (max-width: 1440px) {
  .formflex {
    padding: 0px;
    padding-top: 0px;
  }

  .lablename {
    margin-bottom: 0px;
  }

  @-moz-document url-prefix() {
    .lablenamegrid2 {
      padding-top: 33px !important;
    }
  }

  .loanlabel3 {
    width: 85px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 32px !important;
  }

  .round {
    text-align: center;
    padding-top: 8px;
  }

  label.lablenameview {
    width: 85%;
  }

  .lablename3 {
    padding-top: 16px;
  }

  .lable4thsec {
    width: 85%;
  }

  label.lable4thsec.due {
    margin-bottom: 16px;
  }

  .lablename2 {
    display: inline-block;
    max-width: 60%;
    margin-bottom: 10px;
    font-weight: 600;
    color: white;
    font-size: 12px;
    padding-bottom: 1px;
  }

  .round2 {
    padding-top: 117px;
  }

  .form-group.loangroup3 {
    padding-top: 4px;
  }

  .loanlabel8 {
    width: 85px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .lablenameview2 {
    padding-top: 15px;
  }

  .lablenameview3 {
    padding-top: 15px;
  }

  label.lablenamegrid5 {
    width: 91%;
  }

  .lablenamegrid1 {
    padding-top: 15px;
  }

  .lablenamegrid4 {
    padding-top: 15px;
  }

  .lablenamegrid {
    padding-top: 75px;
  }

  label.lablenamegrid5.tenoer {
    padding-top: 0px;
  }

  label.lablenamegrid5.score {
    padding-top: 15px !important;
  }

  .lablenamegrid2 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 1280px) {
  .d-flex.flex-row.secform2 {
    padding-top: 65px;
  }
  label.lable4thsec.due {
    max-width: 90% !important;
  }

  label.lablenamegrid5.tenoer {
    padding-top: 0px;
  }

  @-moz-document url-prefix() {
    .lablenamegrid4 {
      padding-top: 16px !important;
    }
  }

  label.lablenamegrid5.score {
    padding-top: 15px;
  }

  .lablenamegrid4 {
    padding-top: 15px;
  }

  label.lablenamegrid5 {
    width: 85% !important;
  }

  label.lablenamegrid3 {
    width: 95%;
    padding-top: 15px;
  }

  label.lablenamegrid {
    width: 95%;
  }

  .lablenamegrid {
    padding-top: 75px !important;
  }

  .lablenameview2 {
    padding-top: 0px;
    max-width: 90%;
  }

  .formflex {
    padding: 0px;
    padding-top: 0px;
  }

  .formview6 {
    padding-top: 27px;
  }

  .formview9 {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 1366px) {
  .d-flex.flex-row.secform2 {
    padding-top: 65px;
  }
  .lable4thsec {
    width: 96%;
  }

  @-moz-document url-prefix() {
    .lablenameview2 {
      padding-top: 9px !important;
      max-width: 80%;
    }
  }

  .lablenameview3 {
    padding-top: 25px !important;
  }

  .lablenameview2 {
    padding-top: 0px !important;
    max-width: 80%;
  }

  @-moz-document url-prefix() {
    .round2 {
      padding-top: 125px !important;
    }
  }

  label.lablenameview2.cusrage {
    padding-top: 10px !important;
  }

  .lablename3 {
    padding-top: 24px;
  }

  .round2 {
    padding-top: 120px;
  }

  label.lablenamegrid5 {
    width: 80%;
  }

  .lablenamegrid3 {
    padding-top: 15px;
  }

  .lablenamegrid {
    padding-top: 75px !important;
  }

  .lablenamegrid3 {
    padding-top: 4px;
  }
  label.lablenamegrid3 {
    width: 95%;
    padding-top: 15px;
  }

  .formview6 {
    background-color: gray;
    color: white;
    height: 280px;
    padding: 27px 10px 10px 10px !important;
  }

  .formview5 {
    padding-top: 9px;
  }
  .formview9 {
    padding-top: 26px;
  }
}

@media only screen and (max-width: 360px) {
  .row.loanback {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 414px) {
  .lable4thsec {
    font-size: 8px !important;
    width: 100%;
    text-align: center;
    padding-top: 22px !important;
  }
  .childview2 .lablenameview2.dballow {
    padding-top: 24px !important;
  }
  .checkboxv .round2 {
    padding-top: 165px !important;
  }
  .childview2 .lablenameview2.amtrec {
    padding-top: 24px !important;
  }
  .grid3 .lablenamegrid2 {
    padding-top: 32px !important;
  }
  .grid3 label.lablenamegrid5.score {
    padding-top: 15px !important;
  }
}

@media only screen and (max-width: 480px) {
  .checkboxv .round2 {
    padding-top: 105px !important;
  }
}

@media only screen and (max-width: 767px) {
  .d-flex.flex-row.secform2 {
    padding-top: 65px;
  }

  .d-flex.flex-row.secform2 {
    padding-top: 65px;
    width: 75%;
  }

  .p-2.viewgridrequest {
    width: 33.33%;
    padding: 0.5rem !important;
    margin-top: 0px;
    margin-left: 0px;
  }

  label.flexinput.agreinput {
    padding: 2px;
    font-size: 8px;
    word-wrap: break-word !important;
  }

  .row.loanback {
    padding-left: 5px;
    padding-right: 5px;
  }

  .container-fluid.loanrequest {
    padding-right: 20px !important;
    padding-left: 5px !important;
  }

  .serial {
    font-size: 9px;
    font-weight: 600;
  }

  .form-group.loanserialno {
    padding-top: 2px;
  }

  .productheadersales.cu5 {
    padding-top: 12px;
  }

  .productheadersales.bankname {
    padding-top: 12px;
  }

  .productheadersales.loanname {
    padding-top: 11px;
  }

  .lablenamegrid2 {
    padding-top: 16px !important;
  }

  p.lablename.pdfdown {
    line-height: 14px;
  }

  .secform .lablenameview2 {
    max-width: 100% !important;
    margin-bottom: 4px;
    padding-top: 10px !important;
  }

  .lablenameview2.amtrec {
    padding-top: 10px !important;
  }

  .lablenameview2.dballow {
    padding-top: 10px !important;
  }

  .formview7 {
    height: 290px;
  }

  .lablename3 {
    padding-top: 26px;
  }

  .round {
    padding-top: 10px !important;
  }

  .round2 {
    padding-top: 135px !important;
  }

  label.lable4thsec.due {
    padding-top: 24px !important;
    margin-bottom: 0px !important;
    max-width: 100% !important;
  }
  label.lable4thsec.delmounts {
    padding-top: 34px !important;
  }

  .col-sm-12.col-md-6.loanviewbig {
    padding-left: 0px;
    padding-right: 0px;
  }

  label.lablename.totalloan {
    font-size: 8px !important;
    padding-top: 28px;
  }

  .lable4thsec {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 3px;
    font-weight: 600;
    color: white;
    font-size: 11px;
    width: 100%;
  }

  label.lablenamegrid5 {
    width: 100% !important;
  }
  label.lablenamegrid5.tenoer {
    padding-top: 0px !important;
  }
  .lablenamegrid {
    padding-top: 76px !important;
  }

  .lablenamegrid4 {
    padding-top: 0px !important;
  }

  .formview5 {
    background-color: gray;
    color: white;
    height: 314px;
    margin-left: 5px;
  }

  .container-fluid.cuwritting {
    padding-right: 25px !important;
    padding-left: 20px !important;
  }

  .lablenameview {
    font-size: 8px !important;
    width: 100%;
    text-align: center;
    padding-top: 24px;
    margin-bottom: 6px;
  }

  .lablename {
    font-size: 11px !important;
    text-align: center;
  }

  .lablename2 {
    font-size: 8px !important;
    max-width: 100%;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px !important;
    margin-bottom: 3px;
  }

  .lable4thsec {
    font-size: 8px !important;
    width: 100%;
    text-align: center;
    padding-top: 34px;
  }

  .form-control.flexinput {
    margin-bottom: 6px;
    padding: 2px !important;
    font-size: 8px;
    text-align: center;
  }

  label.lablename2.bureauscore {
    width: 80%;
    padding-top: 34px;
  }
  .cuwritting .form-group .lablename {
    line-height: normal;
  }
  .flexinput.agreinput3 {
    font-size: 8px !important;
  }
  label.lablenamegrid5.score {
    padding-top: 0px !important;
  }
  label.lablenameview2.cusrage {
    padding-top: 26px !important;
  }
}

@media only screen and (min-width: 480px) {
  .form-control.formproduct {
    font-size: 0.8em;
    padding-right: 1px;
  }
}

@media only screen and (max-width: 767px) {
  .lablenameview {
    font-size: 8px !important;
    width: 100%;
    text-align: center;
  }
  label.lablename.totalloan {
    font-size: 8px !important;
    padding-top: 28px;
  }
}

@media only screen and (max-width: 1200px) {
  .loanlabel8 {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .lablename {
    font-size: 10px;
  }

  .lable4thsec {
    font-size: 10px;
  }

  .lablenameview {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .lablename2 {
    font-size: 10px;
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 1025px) {
  .form-control.formproduct {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 1199px) {
  label.lablenameview3 {
    padding-top: 5px;
  }

  label.lable4thsec.due {
    margin-bottom: 0px;
  }

  .lablenameview2 {
    padding-top: 24px !important;
    max-width: 80%;
  }

  label.lablenamegrid5.tenoer {
    padding-top: 15px;
  }

  .lablenamegrid2 {
    padding-top: 15px;
  }

  .round {
    text-align: center;
    padding-top: 2px;
  }

  .round2 {
    padding-top: 99px;
  }

  .loanlabel3 {
    max-width: 82px;
    padding-top: 15px !important;
  }

  .loanlabel8 {
    max-width: 82px;
  }
}

@media only screen and (max-width: 1329px) {
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .productheadersales {
    font-size: 9px;
    font-weight: 600;
    color: #000;
    text-align: center;
  }

  .loanlabel3 {
    padding-top: 20px !important  ;
  }
  label.lablenamegrid3 {
    padding-top: 13px;
  }

  .loanlabel8 {
    padding-top: 12px;
  }

  .form-group.loangroup3 {
    padding-top: 43px;
    text-align: center;
  }
  .viewdiv .row {
    flex-direction: row;
  }
  .childview .lablename {
    font-size: 10px;
    margin-bottom: 0px;
  }
  label.lablenameview {
    width: 100%;
  }
  .lable4thsec {
    margin-bottom: 0px;
    padding-top: 2px;
  }
  .lable4thsec.delmounts {
    padding-top: 15px;
  }
  label.lable4thsec.due {
    max-width: 100% !important;
  }
  label.flexinput.agreinput {
    font-size: 9px;
  }

  .childviewag2 label.flexinput.agreinput {
    font-size: 8px;
  }
  label.flexinput.agreinput3 {
    font-size: 9px;
  }
  .lablenameview2 {
    max-width: 100%;
  }
  label.lablenameview2.cusrage {
    padding-top: 25px !important;
  }
  .round2 {
    padding-top: 145px;
  }
  label.lablenamegrid5 {
    width: 100% !important;
    font-size: 10px;
  }
  .lablenamegrid4 {
    font-size: 10px;
  }
  label.lablenamegrid {
    width: 100%;
    font-size: 10px;
  }
  label.lablenamegrid5.tenoer {
    padding-top: 0px;
  }
  label.lablenamegrid5.score {
    padding-top: 0px !important;
  }
  .lablenamegrid4 {
    padding-top: 0px;
  }
  .lablenamegrid1.baseintrest {
    padding-top: 12px;
  }
  .lablenamegrid2 {
    padding-top: 9px;
  }
}

@media only screen and (max-width: 1538px) {
  .loanlabel3 {
    padding-top: 35px;
  }
}

/* new  */

.p-2.managecustwo {
  width: 16%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.managesntwo {
  width: 4%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.managecusthree {
  width: 10%;
  padding: 0.08rem !important;
  margin-top: 0px;
  margin-left: 0px;
}

.toolt {
}
.toolt > button {
  pointer-events: none;
}

.tooltip {
  background-color: none;
}

.bx--tooltip__label .bx--tooltip__trigger {
  margin-left: 0.1rem !important;
}

.toolinfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.9rem;
}

@media only screen and (max-width: 1600px) {
  label.lablenamegrid5.score {
    padding-top: 0px;
  }

  .lablenamegrid {
    padding-top: 58px;
  }

  .lablenamegrid1 {
    padding-top: 0px;
    margin-bottom: 12px;
  }
  .lablenamegrid3 {
    margin-bottom: 6px;
  }
}

/* 17/07/2024 */

#content-wrapper,
.firstduosection,
.productmixcollection {
  padding-top: 35px !important;
}

.Productgl,
.manageheader {
  font-weight: 600;
  font-size: 16px;
  font-family: Arial;
  color: #2c007d;
  text-align: center;
  padding-bottom: 10px;
}

.form-control.formproduct {
  background-color: #dcf4fb;
  padding-right: 2px;
  text-align: center;
  padding-left: 2px !important;
}
.margn {
  margin-bottom: 3px;
  height: 35px;
}

.cds--tooltip-trigger__wrapper {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.33333;
  letter-spacing: 0.32px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #525252;
}

ol.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
  padding-bottom: 0;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.55rem 1rem !important;
  margin-bottom: 1.5rem !important;
  list-style: none;
  background-color: #e9ecef !important;
  border-radius: 0.25rem;
  font-size: 12px;
  font-weight: 600;
}

.breadcrumb-item.active {
  color: #192859;
  font-size: 13px;
}

.loanview1 {
  padding-left: 5px;
  padding-right: 0px;
}

.viewheader {
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  color: #192859;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: transparent;
  border: none;
}

.formview1 {
  background-color: #20336b;
  color: white;
  padding: 10px 10px 10px 10px !important;
  height: 325px;
}
