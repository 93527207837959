.operation-content #content-wrapper {
  display: block;
}

@media only screen and (max-width: 1280px) {
  .operation-content .collectionsec2 {
    height: 522px;
  }
}

@media only screen and (max-width: 768px) {
  .operation-content .row.holdsec2 {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .operation-content .row.holdsec2 {
    padding-bottom: 0px;
  }

  .operation-content .downloadreport {
    padding-top: 18px !important;
  }
}
