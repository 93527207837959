.dlm-boxes {
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 15px;
}

img.dlm-gif {
  margin-top: 20px;
}

.dml-hero {
  padding: 10px 100px 30px 200px;
}

/* .dml-hero h1,
span {
  line-height: 60px !important;
} */

.dml-hero button {
  margin-top: 10px;
}

.dlm-sm-btn {
  width: fit-content !important;
}

.dlm-box {
  /* width: 285px; */
  max-width: 100%;
  height: 350px;
  padding: 20px 10px;
}

:root {
  --max-layout-width: calc(1080px * 0.25);
  --copy-max-width: calc(var(--font-weight) * 3);
  --normal-font-weight: 500;
  --para-font-size: 18px;
  --para-line-height: 1.6;

  --text-font: var(--normal-font-weight) var(--para-font-size, 18px) / var(--para-line-height, 1.555555556) var(--font-family);
  --para-text-color: #425466;
}

/* .dlm-box p::before {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  height: 30px;
  width: 1px;
  background-color: #ffc000;
} */

.dlm-hero-h1,
.dlm-hero-h1-span {
  font-size: 56px !important;
}

.border-line {
  border-left: 1px solid #f4d03f;
  /* Yellow line */
  padding-left: 0px;
  /* Space between the line and the text */
  margin-right: 0px;
  height: 20px;
  /* Optional: adjust for spacing between line and text */
}

.dlm-box p {
  font-size: 15px;
  max-width: var(--copy-max-width);
  font-weight: 400;
  color: var(--para-text-color);
  padding-left: 10px;
  line-height: 1.6;
  letter-spacing: 0.2px;
}

.dlm-list {
  width: 500px;
  height: 700px !important;
  padding: 10px;
  margin-bottom: 50px;
}

.dlm-img {
  /* margin-top: 350px; */
}

.dlm-dlm-box {
  height: 630px !important;
}

.duo-ani-1 {
  display: flex;
  background: #ecf1f7;
  padding: 15px;
  border-radius: 10px;
}

.duo-ani-1 img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.duo-ani {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 237px;
}

.duo-ani-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  transition: width 1s, opacity 1s;
}

.duo-ani-1 img {
  width: 45%;
  transition: opacity 1s;
}

.duo-ani-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #d6e2f1;
  /* Change the background color as needed */
  transition: width 1s;
  overflow: hidden;
  /* height: 191px; */
  margin-left: 49%;
}

.reduce-width {
  width: 50%;
  height: 100%;
}

.reduce-opacity {
  opacity: 0.5;
}

.increase-opacity {
  opacity: 1;
}

.expand-width {
  width: 50%;
}

.reduce-size {
  transform: scale(0.5);
}

.magnet {
  display: block !important;
  margin: 0% !important;
  margin-top: 80px !important;
}

.people1 {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0; */
  margin-top: -35px;
  position: absolute;
  margin-left: 100px;
}

.people2 {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0; */
  margin-top: -85px;
  margin-left: 100px;
  position: absolute;
}

.people3 {
  margin-top: -5px;
  position: absolute;
  margin-left: 95px;
}

.people4 {
  margin-top: -52px;
  margin-left: 135px;
  position: absolute;
}

.people5 {
  margin-top: -10px;
  position: absolute;
  margin-left: 135px;
}

.people6 {
  margin-top: -85px;
  margin-left: 140px;
  position: absolute;
}

.people1 img:nth-child(1) {
  grid-column: 1 / 3;
}

.people1 img {
  margin: 10px !important;
}

.people2 img {
  margin: 10px !important;
}

.people2 img,
.people1 img {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.magnet img,
.people3 img .people4 img .people5 img .people6 img {
  /* display: block; */
  /* width: 100%; */
  /* opacity: 0; */

  opacity: 0;
  transition: opacity 0.5s ease;
}

.magnet.show img {
  opacity: 1;
}

.people1.show img {
  opacity: 1;
  /* animation: moveLeft1 2s linear; */
}

.people2.show img {
  opacity: 1;
  /* animation: moveLeft2 2s linear; */
}

.people3.show img {
  opacity: 1;
  animation: moveLeft1 2s linear;
}

.people4.show img {
  opacity: 1;
  animation: moveLeft1 2s linear;
}

.people5.show img {
  opacity: 1;
  animation: moveLeft1 2s linear;
}

.people6.show img {
  opacity: 1;
}

.dlm-dlm-box {
  height: 630px !important;
}

@keyframes moveLeft1 {

  /* 0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  } */
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveLeft2 {

  /* 0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  } */
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.magnet {
  transition: transform 0.5s ease;
  transform: translateX(-59%);
}

.magnet.slide-in {
  transform: translateX(0);
}

.dlm-body {
  padding: 70px 100px 100px 200px;
}

@media only screen and (max-width: 400px) {
  .dlm-box:nth-child(1) {
    margin-bottom: 125px !important;
  }

  .dlm-box:nth-child(2) {
    margin-bottom: 85px !important;
  }

  .dlm-box:nth-child(3) {
    margin-bottom: 40px !important;
  }

  .dlm-box:nth-child(4) {
    margin-bottom: 25px !important;
  }
}

@media only screen and (max-width: 767px) {

  .dlm-hero-h1,
  .dlm-hero-h1-span {
    font-size: 35px !important;
    line-height: 45px !important;
  }

  .dlm-list {
    margin-bottom: 0px !important;
  }

  .dlm-boxes {
    grid-template-columns: repeat(1, 1fr);
  }

  .dlm-box {
    height: auto;
  }

  .dlm-box p {
    font-size: 14px;
  }

  .dlm-box:nth-child(1) {
    margin-bottom: 110px;
  }

  .dlm-box:nth-child(2) {
    margin-bottom: 70px;
  }

  .dlm-box:nth-child(3) {
    margin-bottom: 50px;
  }

  .dlm-box:nth-child(4) {
    margin-bottom: 25px;
  }

  .dlm-dlm-box {
    height: auto !important;
  }

  .dlm-body {
    padding: 50px 20px 0px 20px !important;
  }

  .dlm-list {
    padding: 0px;
  }

  .duo-lists.dts-list {
    padding-left: 8px !important;
    padding-right: 0px !important;
  }

  .magnet {
    transition: transform 0.5s ease;
    transform: translateX(-20%) !important;
  }

  .duo-responsive-image {
    width: 45% !important;
    transition: opacity 1s;
  }

  .dlm-magnet-responsive-image {
    width: auto !important;
    margin-right: auto !important;
  }

  .act-body {
    padding: 50px 20px 0px 20px !important;
  }

  .act-img {
    margin-left: 0px;
  }

  .dlm-right-image {
    width: 100%;
  }

  .dlm-img {
    margin-top: 50px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .magnet {
    transition: transform 0.5s ease;
    transform: translateX(-68%);
  }

  .dlm-body {
    padding: 50px 20px 0px 20px !important;
  }

  .dlm-box {
    height: auto;
    width: 100% !important;
  }

  .dlm-box:nth-child(1),
  .dlm-box:nth-child(2) {
    margin-bottom: 150px !important;
  }

  .dlm-box p {
    font-size: 13px;
  }

  .dlm-dlm-box {
    height: auto !important;
  }

  .dlm-right-image {
    width: 100%;
  }

  .dlm-img {
    margin-top: 50px;
  }

  .dlm-boxes {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
  }

  .magnet {
    transform: translateX(-35%);
  }

  /* .magnet {
    transform: translateX(-35%);
  } */

  .act-body {
    padding: 50px 20px 0px 20px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .dlm-body {
    padding: 70px 50px 0px 50px !important;
  }

  .dlm-sm-btn-image {
    width: auto !important;
  }

  .people1 {
    margin-top: -35px;
    position: absolute;
    /* margin-left: 60px; */
  }

  .people2 {
    margin-top: -85px;
    /* margin-left: 60px; */
    position: absolute;
  }

  .people3 {
    margin-top: -5px;
    position: absolute;
    /* margin-left: 95px; */
  }

  .people4 {
    margin-top: -52px;
    /* margin-left: 55px; */
    position: absolute;
  }

  .people5 {
    margin-top: -5px;
    position: absolute;
    /* margin-left: 55px; */
  }

  .people6 {
    /* margin-top: -52px; */
    /* margin-left: 95px; */
    position: absolute;
  }

  .dlm-box p {
    font-size: 13px;
  }

  .dlm-box {
    height: 250px;
    margin-right: 0px;
  }

  .dml-hero {
    padding: 20px 50px 70px 50px !important;
  }

  .dlm-hero-h1,
  .dlm-hero-h1-span {
    font-size: 47px !important;
  }

  .act-img {
    margin-left: 50px;
  }

  .dlm-dlm-box {
    height: auto !important;
  }


  .magnet {
    transform: translateX(-45%);
  }

  .act-body {
    padding: 70px 50px !important;

    .dlm-img {
      width: 100%;
      margin-left: 50px;
    }

    .dlm-right-image {
      width: 100%;
    }

    .magnet {
      transform: translateX(-45%);
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .dlm-dlm-box {
    height: auto !important;
  }
}