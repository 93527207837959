@media only screen and (max-width: 767px) {
  .home-hero-section {
    padding: 20px 20px 30px 20px !important;
  }
  .home-hero-p {
    padding-top: 0px !important;
  }

  .home-hero-section p {
    font-size: 35px !important;
    line-height: 45px !important;
  }

  .appstore-image,
  .playstore-image {
    width: 40% !important;
  }

  .home-personal-left h1,
  .home-personal-left h1 span {
    font-size: 29px !important;
    line-height: 39px !important;
  }

  .home-business-left h1,
  .home-business-left-h1-span,
  .home-montra-pg-h1-span,
  .home-montra-pg-h1 {
    font-size: 29px !important;
    line-height: 39px !important;
  }

  .home-montra-pg-h1 {
    font-size: 35px !important;
    line-height: 50px !important;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .personal-hero-left {
    width: 100% !important;
  }

  .home-business-left h1,
  .home-business-left-h1-span {
    font-size: 45px !important;
    line-height: 60px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .home-hero-section {
    padding: 30px 20px 30px 20px !important;
  }
}
