:root {
  --fontWeightSemibold: 500;
}

.nav-link .navbar-nav .nav-item .nav-link.active {
  color: #00baf2;
}

.second-navbar .nav-link a {
  color: #6e6e6e;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 20px;
}

.second-navbar .nav-link button:nth-child(1) {
  border: none;
  border-radius: 24px;
  background: #4abceb;
  color: var(--Shade-White, #fff);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 145%;
  font-weight: var(--fontWeightSemibold);

  /* padding: 8px 12px; */
}

.product-btn:hover {
  background: #20336b !important;
}

.product:hover {
  color: #4abceb !important;
}

.business:hover {
  color: #4abceb !important;
}

button.product-btn.produvt-sign {
  padding: 6px 12px;
  color: #20336b !important;
}

button.product-btn.produvt-sign:hover {
  color: #fff !important;
}

.flexdiv-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px !important;
}

.flexdiv-menu {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
}

.horizontal-navbar .navbar {
  padding-left: 0px !important;
}
.flexdiv-menu-items {
  display: flex;
  align-items: center;
  padding: 5px 10px 0 20px;
  margin-right: 0px;
}

.productnav_icon {
  margin-right: 10px;
  width: 30%;
}
.product_nav-title {
  display: inline-flex;
  color: #20336b;
  align-items: center;
  font-size: 18px;
  font-family: "sohne-var", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.horizontal-flow {
  display: flex;
  align-items: center;
  overflow: hidden;
  mask-image: linear-gradient(
      to right,
      transparent,
      #000 16px,
      #000 calc(100% - 16px),
      transparent
    ),
    linear-gradient(
      to left,
      transparent,
      #000 16px,
      #000 calc(100% - 16px),
      transparent
    );
}

.horizontal-flow-track {
  display: flex;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  scrollbar-width: none;
}

.horizontal-flow-items {
  display: flex;
  position: relative;
}

.horizontal-flow-items .nav-link {
  width: max-content;
}

@media only screen and (max-width: 767px) {
  .business-horizontal-navbar .second-navbar .nav-link {
    display: flex !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .business-horizontal-navbar .second-navbar .nav-link {
    display: flex !important;
  }
}
