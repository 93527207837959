/* Moneylink partner-login */
.ml-background.moneybgnew {
  padding-bottom: 100px;
  /* padding-top: 60px; */
}

.cardissuerbg,
.ml-background.moneybgnew {
  background: linear-gradient(0.97deg,
      #fff 4.89%,
      #e7d9ff 75.7%,
      #e7d9ff 146.13%);
}

.mneylink-log-header {
  margin-left: auto;
  display: block;
  margin-right: auto;
  max-width: 200px;
}

.mneylink-log-header img {
  max-width: 100%;
  padding-bottom: 50px;
  padding-top: 70px;
}

p.para4 {
  font-size: 16px;
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 0px;
  font-weight: 600;
}

label.signin-label {
  margin-bottom: 5px;
  font-size: 0.9em;
  font-family: Arial;
  font-weight: 600;
  margin-top: 10px;
}

.btn.btn-warning.partnerbutton {
  background-image: none;
  border-radius: 5px;
  border: none;
  font-weight: 600 !important;
  font-size: 15px;
  padding: 7px 70px;
  color: #fff;
  background-color: #3c1777;
}

.otp,
.sign {
  text-align: center;
  padding-top: 12px;
}

.moneybgnew .signuptext {
  font-size: 14px;
  text-align: center;
  padding-top: 15px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0px;
}

.btn.btn-warning.montrasignup {
  color: #fff;
  background-color: #ff5b02;
  background-image: none;
  border-radius: 5px;
  border: none;
  font-weight: 600 !important;
  font-size: 15px;
  padding: 6px 70px;
  margin-top: 15px;
}

.forgot {
  float: right;
  margin-bottom: 5px;
  font-size: 0.9em;
  font-weight: 600;
  padding-top: 10px;
}

.forpas,
.input-resend {
  color: #0c4195;
  text-decoration: auto;
}

.errorMsg {
  color: #c03;
  font-size: 12px;
  font-weight: 600;
}

.btn.btn-warning.otpbutton {
  background-color: #24baf2;
  border-color: #24baf2;
  padding: 5px 30px;
  font-size: 15px;
  border-radius: 8px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}

p.emptyparagraph {
  margin-bottom: 3px;
}

p.otpsub {
  color: green;
  font-size: 14px;
  font-weight: 700;
}

select.form-control.leftalign {
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #000;
  font-weight: 600;
}

input.form-control.partners-input {
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #000;
  font-weight: 600;
}

/* end Moneylink partner-login */

/* terms-condition */

h5.header-tag {
  padding-bottom: 15px;
  padding-top: 20px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375;
  letter-spacing: 0;
}

p.paragraph {
  line-height: 1.5;
  letter-spacing: 0;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

p.paragraph,
ul.paragraph {
  font-size: 1rem;
  font-weight: 300;
}

.privacypl {
  padding: 20px 20px 40px;
}

.spanterms,
li.footerli.footpersonal {
  font-weight: 600;
}

span.spanterms1.italic {
  font-weight: 600;
  font-style: italic;
}

.privacy h6 {
  color: #000;
}

.privacy {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 0.5em;
  font-weight: 300;
}

/* end terms-condition */

h2.footerget,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

.moneylink-project li,
p.cardmontra,
p.montratextthird,
ul.montralist {
  padding-bottom: 5px;
}

ol.agencylistsection,
.moneylink-project li {
  padding-bottom: 10px;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.moneylink-project label {
  margin-bottom: 5px;
  font-size: 0.9em;
  font-family: Arial;
}

.moneylink-project label,
li.app,
li.becomeapp {
  display: inline-block;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

form {
  padding: 0 !important;
}

li.namelist {
  padding-right: 20px;
  padding-top: 7px;
  font-weight: 600;
}

input.form-control.paymentsfield {
  background-color: #c4e7f9;
  border: none;
}

select.form-control.paymentsfield {
  background-color: #c4e7f9;
  text-align-last: left;
  border: none;
}

.form-group.accountdetails {
  background-color: #c4e7f9;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  display: flex;
  height: 60px;
  align-items: center;
}

.form-group.accountdetails>label {
  margin: 10px;
  padding: 49px;
  font-size: 15px;
  width: 80%;
}

input.form-control.radiorecharge {
  width: 2%;
  border: none;
}

.form-control {
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  width: 100%;
  height: 34px;
  padding-left: 5px;
  font-size: 12px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c1bebe;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control,
.moneylink-project body {
  line-height: 1.42857143;
}

div {
  margin: 1 !important;
}

button.forgototppin {
  background-color: transparent;
  border: none;
  color: #223872;
  font-weight: bold;
  width: 100%;
  margin-top: 12px;
  font-size: 13px;
  text-decoration: underline;
}

input.form-control.cardinputfield {
  border-radius: 5px;
}

label.typepin {
  color: #223872;
  font-size: 13px;
  padding-bottom: 3px;
}

h5.headerpass {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 50px;
  text-align: center;
}

label.pinotp {
  font-size: 14px;
  padding-bottom: 5px;
}

.forgotpinchange {
  padding-top: 100px;
  background-color: white;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label.newpinfiled {
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 2px;
}

.errorMsgpin {
  color: red;
  text-align: center;
  font-weight: bold;
  padding-top: 6px;
  font-size: 13px;
}

.errorMsgpinset {
  color: red;
  text-align: left;
  font-weight: bold;
  padding-top: 6px;
  font-size: 13px;
}

h5.headerpass.pinotp {
  padding-bottom: 10px;
}

p.pinchangedsuccess {
  text-align: center;
  color: green;
  font-weight: 600;
}

.submittedpin {
  color: green;
  font-size: 13px;
  text-align: center;
  margin-top: 5px !important;
}


@media only screen and (max-width: 920px) {
  .formroot .row {
    flex-direction: row;
  }
}