#DuoWebPortal {
  overflow: hidden;
}

.duo-list-container.duo-dwp p {
  margin-bottom: 0px;
}

.dwp-sm-btn {
  width: fit-content;
  display: flex;
  border: 1px solid #565656;
  border-radius: 50px;
  padding: 8px 10px;
  margin-bottom: 10px;
}

.dwp-sm-btn img {
  margin: 0;
}

.duo-list.dtswp p {
  margin-bottom: 0px;
}

.duo-list.dtswp {
  margin-bottom: 3px;
}

.dwp-frame-header-h6 {
  margin-bottom: 10px !important;
}

.webfooter {
  background-color: #f6f9fc;
  padding-top: 100px;
}

.duo-list-container.dco-list-container p {
  width: 640px;
}

.col.dco-hero-image-col {
  margin-top: 50px;
}

.duo-list-container.duo-dwp {
  margin-top: 0px;
}

.col.duo-col.dwp-col-duo {
  width: 50% !important;
}

.dts-hero.dwp-hero {
  line-height: 59px !important;
}

img.dwp-hero-img {
  padding-top: 0px;
}

.dwp-sm-btn p {
  text-align: center;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}

.dwp-hero-row {
  align-items: center !important;
}

.dwp-hero {
  padding: 10px 0 70px 200px;
}

.dwp-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p.geteasy.dwpeassy {
  margin-top: 10px;
  margin-bottom: 10px;
}

.dwp-h1-h1 {
  margin-bottom: 0px !important;
  font-weight: 500 !important;
  line-height: 53px !important;
}

h1.dwp-h1-h1 {
  padding-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .dwp-body {
    padding: 30px 20px 0px 20px !important;
  }

  .dwp-img {
    margin-bottom: 0px;
  }

  .dwp-img img {
    width: 100%;
  }

  .duo-list-container.dco-list-container p {
    width: 100%;
  }

  .dwp-sm-btn {
    margin-bottom: 0px;
  }

  .col.duo-col.dwp-col-duo {
    width: 100% !important;
  }

  .dwp-hero-row {
    padding-right: 0px !important;
  }

  .dwp-h1-h1 {
    font-size: 35px !important;
    line-height: 45px !important;
  }

  #DuoWebPortal {
    padding: 0 0px !important;
  }

  .dts-frame-img {
    margin-bottom: 10px;
  }

  .webfooter {
    padding-top: 0px;
  }

  .dwp-body {
    padding: 50px 20px 0px 20px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .dwp-img img {
    width: 100%;
  }

  .dwp-img {
    margin-bottom: 0px;
  }

  .dwp-row {
    align-items: center;
  }

  .dwp-body {
    padding: 70px 50px 0px 50px !important;
  }

  .col.duo-col.dwp-col-duo {
    width: 100% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .dwp-sm-btn {
    width: fit-content;
  }

  .dwp-hero {
    /* padding: 70px 0px 70px 100px !important; */
    padding: 70px 20px;
  }

  .dwp-body {
    padding: 70px 70px 0px 70px !important;
  }

  /* .col {
    width: 50%;
  } */

  .duo-list p {
    font-size: 14px;
  }

  .dwp-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1500px) {
  .dwp-hero-row {
    align-items: center;
  }

  .dwp-h1-h1 {
    font-size: 55px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1500px) {
  .dwp-hero-row {
    align-items: center;
  }
}
