.main p {
  color: #20336b;
  font-size: 15px;
  font-weight: 400;
}

button.cta.paygatepayment {
  /* margin-top: -15px; */
  line-height: 145%;
  padding: 10px 15px;
}

p.custpmerpara {
  padding-left: 8px;
}

.description-col p {
  font-size: 15px;
  color: #20336b;
  padding-left: 8px;
}

h3.payoffers {
  font-size: 38px;
}

.col.personal-hero-right.gatwayright {
  width: 670px;
}

.body.checkoutpay {
  padding-block-start: 4.5rem;
  padding-block-end: 10.5rem;
}

.price-item h6 {
  font-size: 15px;
  padding-top: 15px;
  font-weight: 500;
}

.price-item ul {
  font-size: 15px;
  font-weight: 400;
}

.down-arrow.payarrow {
  left: 8px;
}

.step.paystep {
  margin-right: 60px;
}

.arrow.arrowp {
  margin: 0 -55px;
  margin-top: -42px;
}

.info-5 .info-body {
  height: -moz-fit-content !important;
  height: fit-content !important;
  max-width: 357px;
}

.down-arrow.payarrow {
  left: 8px;
  bottom: -27px;
}

.step .paystep {
  width: 95px;
}

.payment-gateway-hero {
  max-width: 100%;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  gap: 8px;
  text-align: center;
}

.icon-wrapper {
  display: flex;
  background-color: #20336b;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.icon-container label {
  font-size: 15px;
  font-weight: 500;
  color: #20336b;
  line-height: 20px;
}

.inventory-sm-btn.paybtn p {
  margin: 0px !important;
}

.gateway {
  padding-block: 70px;
  padding-inline-start: 200px;
  display: flex;
  align-items: flex-start;
  gap: 7rem;
  color: #20336b;
  padding-top: 36px;
}

.gateway .diagram {
  display: block;
}

@media screen and (max-width: 1024px) {
  .gateway {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    padding-block: 30px;
  }

  .gateway .diagram {
    max-width: 100%;
  }

  .gateway>img {
    display: none;
  }

  .info-1,
  .info-2,
  .info-3,
  .info-4,
  .info-5 {
    display: none !important;
  }

  .prices {
    flex-direction: column;
    max-height: none !important;
  }

  .price-item {
    min-width: fit-content !important;
    height: 435px !important;
  }
}

.gateway p {
  font-size: 17px;
  line-height: 25px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cta {
  padding: 8px 24px;
  color: #20336b;
  background-color: #4abceb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

img.businesshero2-image {
  margin-bottom: 15px;
}

.title {
  color: #20336b;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 0px;
  padding-top: 10px;
}

.list {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: normal;
}

.list li::marker {
  background-color: #20336b;
}

.body {
  background-color: #20336b;
  padding-block: 8rem;
  padding-inline: 200px;
  color: #fff;
}

.body h2 {
  text-align: center;
  font-size: 38px;
  margin-block-end: 4rem;
  font-weight: 500;
}

.checkout-steps {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: flex-start;
  gap: 25px;
}

.card {
  position: relative;
  background-color: #fff;
  padding-inline: 20px;
  padding-block: 1.5rem;
  line-height: 24px;
  color: #425466;
  border-radius: 16px;
  border: none;
}

.open-acct-count.paycount1.payopenl {
  margin-top: 71px !important;
}

.card img {
  margin-left: auto;
  margin-right: auto;
}

.card h5 {
  font-weight: 500;
  font-size: 16px;
  color: #20336b;
  margin-block-start: 1rem;
}

.card ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-block-start: 20px;
  color: #20336b;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

.card li {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding-top: 5px;
}

.triangle {
  position: absolute;
  top: 60px;
  right: -15px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 16px solid #fff;
  border-bottom: 8px solid transparent;
}

.cutout {
  position: absolute;
  top: 60px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 16px solid #20336b;
  border-bottom: 8px solid transparent;
}

.triangle.three {
  top: 50px;
}

.triangle.four {
  top: 50px;
}

.cutout.five {
  top: 50px;
  left: -1px;
}

.cutout.four {
  top: 50px;
  left: -1px;
}

.one {
  top: 68px;
}

.two {
  top: 70px;
}

.three {
  top: 50px;
}

.triangle.two {
  top: 50px;
}

.triangle.one {
  top: 50px;
}

.cutout.two {
  top: 50px;
  left: -1px;
}

.cutout.three {
  top: 50px;
  left: -1px;
}

ul.list {
  padding-left: 20px;
}

.four {
  top: 60px;
}

.circle {
  width: 2rem;
  height: 2rem;
  background-color: #3fafe9;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-1 {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 78px;
  left: -511px;
}

.info-2 {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 7px;
  left: -400px;
}

.info-3 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: -100px;
  left: 10px;
}

.info-4 {
  position: absolute;
  display: flex;
  align-items: center;
  right: -386px;
  bottom: 211px;
}

.info-5 {
  position: absolute;
  display: flex;
  align-items: center;
  right: -386px;
  bottom: 64px;
}

.info-3 .info-body {
  height: fit-content !important;
  max-width: 173px;
}

.info-4 .info-body {
  height: fit-content !important;
  max-width: 357px;
}

.info-body {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding-block: 8px;
  padding-inline: 12px;
  border: 1px solid #fff;
  border-radius: 8px;
  font-weight: 400;
}

.info-line {
  height: 1px;
  width: 30px;
  background-color: #fff;
}

.info-line-vertical:first-of-type {
  height: 30px;
  width: 1px;
  background-color: #fff;
  margin-right: 120px;
}

.sdk {
  padding-inline: 200px;
  padding-block: 8rem;
  text-align: center;
  color: #20336b;
  font-weight: 500;
  padding-block-start: 4.5rem;
}

.sdk h2 {
  padding-inline: 200px;
  padding-block: 8rem;
  text-align: center;
  color: #20336b;
  font-weight: 500;
  line-height: 3rem;
  padding-top: 20px;
  padding-bottom: 0px;
}

.sdk-wrapper {
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-block: 4rem;
}

.sdk-card {
  box-shadow: 0px 0px 0px 1px #1019280d;
  box-shadow: 0px 0px 3px -1px #1019280a;
  box-shadow: 0px 16px 52px -6px #1019282e;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  width: 240px;
  padding-block: 1rem;
}

.sdk-card img {
  height: 200px;
  object-fit: contain;
}

.tables {
  background-color: #f6f9fc;
  padding-inline: 200px;
  padding-block: 8rem;
  padding-block-start: 4.5rem;
  padding-block-end: 4.5rem;
}

h3.featurespay {
  font-size: 38px !important;
  line-height: 2.8rem !important;
}

h3.payoffers {
  font-size: 38px !important;
}

.tables h3 {
  text-align: center;
  color: #20336b;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.3;
}

.grid.margin-bottom {
  margin-top: 35px;
}

.tables h3:first-child {
  color: #20336b;
  font-weight: 500;
  padding-bottom: 15px;
  font-size: 26px;
  line-height: 1.3;
  margin-bottom: 0px;
}

.tables .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  column-gap: 72px;
  row-gap: 48px;
  text-align: left;
}

.table-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  column-gap: 36px;
  row-gap: 32px;
}

.no-margin {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.description {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.description h5 {
  font-size: 15px;
  font-weight: 500;
  color: #20336b;
}

.description-col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.description-col .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 72px;
  row-gap: 48px;
}

.description-col h5 {
  font-size: 15px;
  font-weight: 500;
  color: #20336b;
}

.description-col .icon {
  width: 36px;
  height: 36px;
  padding-left: 8px;
}

.blue-circle {
  background-color: #20336b;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #425466;
}

.blue-circle h5 {
  color: #0a2540;
}

.blue-circle img {
  object-position: center;
  object-fit: contain;
}

.compare-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  border: 1px solid #c5d1d5;
  border-radius: 16px;
}

.compare-table .item {
  width: 100%;
  padding: 24px;
  margin: 0;
}

.compare-table .grid {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 36px;
  row-gap: 56px;
}

.pricing {
  padding-inline: 200px;
  padding-block: 4.5rem;
  text-align: center;
  color: #0a2540;
}

p.bestprice {
  font-size: 18px;
  padding-bottom: 30px;
}

.pricing h2 {
  font-size: 38px;
  font-weight: 500;
  padding-bottom: 20px;
}

.inventory-sm-btn.paybtn p {
  margin-top: 0px;
  line-height: 0px;
  padding-top: 10px !important;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 500;
}

.tag {
  border-radius: 8px;
  background-color: #dee6fd;
  color: #20336b;
  padding-block: 12px;
  padding-inline: 16px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Söhne, "Helvetica Neue", "Arial", sans-serif;
}

.features {
  margin-block-start: 5rem;
}

.features h3 {
  color: #20336b;
  font-size: 38px;
}

.payment h3 {
  font-size: 38px;
  color: #0a2540 !important;
  text-align: center;
  margin: 0;
}

.payment .description {
  max-width: 800px;
  font-size: 18px;
  color: #425466;
  text-align: center;
}

.payment-step-imgs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 83px;
}

.payment-main {
  display: flex;
  align-items: flex-start;
}

.text-group {
  text-align: left;
  flex: 1;
}

.text-group .text {
  padding-inline-end: 25px;
}

.connector-group {
  width: 100%;
  display: flex;
  align-items: center;
}

.connector {
  display: flex;
  align-items: center;
}

.dot {
  background-color: #00ace0;
  color: #fff;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect-line {
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
}

.prices {
  width: 100%;
  max-height: 645px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 4rem;
}

.price-item {
  position: relative;
  padding-inline: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: #fff;
  padding-block: 24px;
  max-width: 208px;
  font-weight: 500;
}

.price-item:nth-of-type(1) {
  background-color: #5e7cd0;
  height: 374px;
}

.price-item:nth-of-type(2) {
  background-color: #0b74e6;
  height: 435px;
}

.price-item:nth-of-type(3) {
  background-color: #b3c6e7;
  color: #20336b;
  height: 500px;
}

.price-item:nth-of-type(4) {
  background-color: #00ace0;
  height: 557px;
}

.price-item:nth-of-type(5) {
  background-color: #20336b;
  height: 620px;
}

.price-item hr {
  width: 100%;
  border: 1px solid #fff;
  margin-block: 1rem;
}

.price-item ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
}

.price-item li {
  list-style-position: outside;
  list-style-image: url("../../../assets/images/check.svg");
}

.price-icon {
  position: absolute;
  background-color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -24px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}

.price-item:nth-of-type(1) .price-icon {
  border: 1px solid #5e7cd0;
}

.price-item:nth-of-type(2) .price-icon {
  border: 1px solid #0b74e6;
}

.price-item:nth-of-type(3) .price-icon {
  border: 1px solid #b3c6e7;
}

.price-item:nth-of-type(4) .price-icon {
  border: 1px solid #00ace0;
}

.price-item:nth-of-type(5) .price-icon {
  border: 1px solid #20336b;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.margin-bottom {
  margin-block-end: 5rem;
}

.paid-instantly {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .gateway {
    display: block;
  }

  .sdk {
    padding-inline: 20px;
    padding-block: 2rem;
    padding-block-start: 0rem;
  }

  .sdk-wrapper {
    flex-direction: column;
    gap: 1.5rem;
    margin-block: 2rem;
  }

  .sdk h2 {
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1.6 !important;
  }

  .checkout-steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .tables {
    background-color: #f6f9fc;
    padding-inline: 200px;
    padding-block: 8rem;
    padding-block-start: 2rem;
    padding-block-end: 0rem;
  }

  .tables .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .grid.margin-bottom {
    margin-bottom: 50px !important;
  }

  .grid {
    margin-block-start: 2rem;
  }

  .pricing {
    padding-inline: 20px;
    padding-block: 2rem;
    padding-bottom: 30px;
  }

  .prices {
    align-items: normal;
  }

  .price-item {
    max-width: 100%;
  }

  .tag {
    padding-inline: 8px;
  }

  .description {
    flex-direction: column;
  }

  .gateway-process-container {
    padding: 10px 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  .checkout-steps {
    grid-template-columns: repeat(2, 1fr);
  }

  h3.payoffers {
    font-size: 35px !important;
  }

  .col.personal-hero-left.businessleft,
  .col.personal-hero-right.gatwayright {
    width: 100% !important;
  }

  .sdk {
    padding: 20px 80px 50px 80px;
  }

  .paycount {
    margin-top: 20px !important;
  }

  .pricing {
    padding: 20px;
    padding-bottom: 50px !important;
  }

  .prices {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 50px 50px;
  }

  .price-item {
    max-width: 100%;
  }

  .gateway-process-container {
    padding: 10px 0px;
  }
}

@media only screen and (max-width: 920px) {
  .checkout-steps {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

  .body,
  .sdk,
  .tables,
  .pricing {
    padding-inline: 20px;
  }

  .card .info,
  .card .line {
    display: none;
  }

  .sdk-wrapper {
    flex-direction: column;
  }

  .tables .grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .tables .payment-table-1 {
    display: none;
  }

  section.gateway {
    padding-left: 20px !important;
    padding-right: 0px !important;
  }

  .col.personal-hero-left.businessleft,
  .col.personal-hero-right.gatwayright {
    width: 50%;
  }

  .personal-hero-right {
    position: static !important;
  }

  .prices {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 50px 100px;
  }

  .price-item {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {

  .col.personal-hero-left.businessleft,
  .col.personal-hero-right.gatwayright {
    width: 50% !important;
  }

  .personal-hero-right {
    position: static !important;
  }

  section.gateway {
    padding-left: 80px;
    padding-right: 0px !important;
  }

  .info-5 {
    right: -170px;
    bottom: -100px;
    width: 100%;
    align-items: baseline;
  }

  .info-4 {
    right: -170px;
    bottom: 110px;
    width: 100%;
  }

  .info-line {
    width: 130px;
  }

  .horizontal-line {
    width: 840px;
  }

  .pricing {
    padding-inline: 20px;
  }
}

@media only screen and (min-width: 1500px) {
  .info-4 {
    /* bottom: 185px !important; */
    bottom: 150px !important;
  }

  .info-5 {
    /* bottom: 75px !important; */
    bottom: 50px !important;
  }

  .main p {
    color: #20336b;
    font-size: 15px;
  }

  .horizontal-line {
    width: 980px !important;
  }
}

@media only screen and (min-width: 1578px) {
  .info-4 {
    bottom: 158px !important;
  }

  .info-5 {
    bottom: 50px !important;
  }
}

@media only screen and (min-width: 1578px) {
  .info-4 {
    bottom: 155px !important;
  }

  .info-5 {
    bottom: 50px !important;
  }
}

@media only screen and (min-width: 1636px) {
  .info-4 {
    bottom: 126px !important;
  }

  .info-5 {
    bottom: 17px !important;
  }

  .card {
    padding-inline: 15px;
    padding-block: 0.5rem;
  }
}

@media only screen and (min-width: 1920px) {
  .info-4 {
    bottom: 128px !important;
  }

  .info-5 {
    bottom: 20px !important;
  }
}