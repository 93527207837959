.ver-nav-link .active {
  color: #37cc00 !important;
  background: none;
}

.ver-nav-link :hover {
  color: #37cc00 !important;
}

.verified-navbar li.nav-item:hover {
  color: #37cc00;
}

.verified {
  background: none !important;
}

.verified:active,
.verified:hover {
  color: #37cc00 !important;
}
