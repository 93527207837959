.p-2.underwriteone,
.p-2.underwrite,
.p-2.headerbuttons,
.p-2.headertext,
.p-2.underwritepart,
.p-2.underwrite2,
.p-2.headerbuttonsright,
.p-2.underwritepart3,
.p-2.underwrite3,
.p-2.underwritepart5,
.p-2.underwrite5,
.p-2.underwritepart6,
.p-2.underwrite6 {
    margin-top: 0px;
    margin-left: 0px;
}

.p-2.underwritepart5 {
    width: 20%;
    padding: 0.5rem .08rem !important;
}

.underone {
    padding-left: 0em;
    padding-right: 0.7em;
    padding-top: 3.7em;
}

.p-2.progl,
.p-2.underwrite2,
.p-2.underwrite5,
.p-2.underwriteone {
    width: 16%;
    padding: .08rem !important;
}

.p-2.underwrite {
    width: 12%;
    padding: .08rem !important;
}

label.labelpt-7 {
    font-size: 11.5px !important;
    font-weight: 600;
    margin-bottom: 6px;
    color: #000;
    text-align: center;
    /* line-height: 1.2em; */
}

p.productheadercuwmaker {
    width: 80% !important;
}

p.productheadercuwmaker.a3 {
    padding-top: 2.6em;
}

p.productheadercuwmaker.a4 {
    padding-top: 2.6em;
}

label.labelpt-7 {
    padding-top: 4.4em;
}

.text2part,
.textlabel {
    color: #00baf2;
    text-align: center;
    font-weight: 600;
    font-size: 11px;
    padding-right: 2px;
}

.textposition {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-top: -10px;
    padding-bottom: 10px;
    color: #000;
}

.p-2.headerbuttons {
    width: 25%;
}

.p-2.headertext,
img.duoaboutimg {
    width: 80%;
}

.labelpt-8 {
    height: 30px;
    font-size: 11.5px;
    padding-top: 3.5em;
}

p.makerunderwritting {
    padding-top: 0.2em;
}

p.productheadercuwmaker {
    padding-top: 1.4em;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

p.makerunderwritting {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.p-2.underwritepart3 {
    width: 22%;
    padding: .08rem !important;
}

.p-2.underwrite3 {
    width: 13%;
    padding: .08rem !important;
}

.p-2.underwrite {
    width: 12%;
    padding: .08rem !important;
}

.labelmak-8 {
    padding-top: 35px;
    height: 30px;
    padding-bottom: 5px;
}

label.labelpt-8.underprolab {
    padding-top: 2.9em !important;
}

label.labelpt-8.underprolab6 {
    padding-top: 2.9em !important;
}

.cardformuw {
    width: 50%;
    display: block;
    background-color: #26abe7;
    margin: 46px auto 10px;
    border: 1px solid #26abe7;
    border-radius: 13px;
    padding: 15px 28px;
}

.d-flex.flex-row.mt-3.padmar,
.mt-lg-4,
.my-lg-4 {
    margin-top: 0 !important;
}

.d-flex.flex-row.mt-3.cuwfirst {
    margin-top: 32px !important;
}