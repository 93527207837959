.montra-main-header {
  padding: 0.3rem 2rem;
}

.montra-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0px;
  background-color: #f8f9fa;
}

.montra-main-header .makerheader {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
}

.toplogoml {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 165px;
}

.toplogo {
  max-width: 100%;
}

.mr-auto {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.montralendingheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul.maker-top-header {
  list-style-type: none;
  display: flex;
  gap: 20px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.navbarlink {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbarlink2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagepartner {
  max-width: 60%;
  padding: 0px;
  border-radius: 4px;
  border: 1px solid #20336b;
}

.montra-logout-user {
  display: flex;
  align-items: center;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .montra-logout-user {
    display: none;
  }
}

.logout {
  padding: 5px 12px;
  font-size: 14px;
  color: #fff !important;
  background-color: #2c007d;
  background-image: none;
  border-radius: 20px;
  border: none;
  font-weight: 600;
}

.link-container {
  display: flex;
  align-items: center;
  background-image: linear-gradient(#24baf2, #fff);
  border-bottom: 1px solid #2c007d;
  padding: 12px 20px;
}

.userlink {
  padding: 5px 20px;
  text-decoration: none;
  color: #000;
  transition: color 0.3s ease;
  font-size: 14px;
  font-weight: 600;
}

.userlink:hover {
  color: #007bff;
}

.userlink:active {
  color: #fff;
  font-weight: bold;
  background-color: #2c007d !important;
  border-radius: 50px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 0.9em !important;
  margin-right: 5px;
  padding: 0.4rem 0.8rem;
}

#content-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  align-items: center;
}

.profile-content-div {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.profile-i {
  display: block;
  margin: 0 auto 20px;
}

.profile-content,
.profile-content1 {
  margin: 10px 0;
}

.profile-content span,
.profile-content1 span {
  color: #fbb40a;
  /* Maintain consistency for span colors */
}

p.profile-content1 {
  width: 400px;
  text-align: left;
}

.btn-outline-danger:hover {
  background-color: #29baec;
}

/* profile */

#content-wrapper,
.firstduosection,
.productmixcollection {
  padding-top: 35px;
}

#content-wrapper,
li.app {
  padding-bottom: 40px;
}

.backloan {
  padding-bottom: 120px;
}

.__react_component_tooltip {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 8px 21px;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999;
}

.t874bb31c-d0b4-40db-bab5-6af1fe0f6f96 {
  color: #fff;
  background: #222;
  border: 1px solid transparent;
}

.t874bb31c-d0b4-40db-bab5-6af1fe0f6f96.place-top {
  margin-top: -10px;
}

.productmix,
.signinheader {
  font-weight: 600;
  font-size: 13px;
  color: #2c007d;
  text-align: center;
  padding-bottom: 8px;
  margin-bottom: 0px;
}

.input-container {
  display: flex;
  width: 100%;
}

.form-control.forminputfield {
  background-color: #dcf4fb;
  height: 33px;
  position: relative;
  text-align-last: left;
  padding-left: 3px;
  font-size: 12px;
  color: #555;
  background-image: none;
  border: 1px solid #c1bebe;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: Arial;
}

.para4,
.para4sal,
.signupara4,
.signuptext,
.moneylink-admin label {
  color: #000;
  font-weight: 600;
}

label {
  margin-bottom: 5px;
  font-size: 13px;
  font-family: Arial;
}

label,
li.app,
li.becomeapp {
  display: inline-block;
}

.form-group {
  margin-bottom: 3px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control.inputfield {
  height: 33px;
  padding-left: 5px;
  font-size: 12px;
  color: #555;
  font-family: Arial;
}

p.toperror {
  font-size: 14px;
  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 0px;
}

.collectionheader {
  padding-top: 50px;
  font-size: 14px;
  font-family: Arial;
  text-align: center;
  font-weight: 600;
}

.product2,
.productcomm {
  padding-top: 17px;
  text-align: center;
  width: 100%;
}

.p-2.payl {
  width: 25%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-top: 0px;
  margin-left: 0px;
}

.product-re {
  width: 100%;
  text-align: center;
}

#field1,
#field2 {
  padding-left: 2px;
  background-color: gray;
  border-radius: 2px;
}

#field1 {
  border: 1px solid gray;
}

#field1,
#field2,
.basetenor,
.form-control.formproduct,
.makerunderwritting,
.productheader,
.productheadercuwmaker,
.productheadermak {
  font-size: 11px;
  font-family: Arial;
}

.form-control.comissionfield2 {
  color: #fff;
  margin-bottom: 3px;
  height: 35px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  font-family: Arial;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  border-radius: 2px;
}

.p-2.flexnowrap4,
.p-2.flexnowrapmak,
.p-2.flexnowrapmaker {
  padding: 0.1rem !important;
  width: 23%;
  margin-top: 0px;
  margin-left: 0px;
}

.loanlabelmak3 {
  font-size: 11px !important;
  margin-top: -3px;
  text-align: center;
}

.loanlabelmak3,
.loanlabelmak4 {
  color: #fff;
  line-height: 12px;
  padding-top: 3px;
  height: 36px;
}

.p-2.flexnowrap,
.p-2.flexnowrap2,
.p-2.flexnowrap3 {
  padding: 0.1rem !important;
  width: 11%;
  margin-top: 0px;
  margin-left: 0px;
}

.p-2.flexnowrapmak2 {
  margin-top: 5px;
  margin-left: 0px;
}

#field1,
#field2 {
  background-color: gray;
  border-radius: 2px;
  padding-left: 2px;
}

.loanlabelmak5,
.loanlabelmak6 {
  color: #fff;
  line-height: 12px;
  font-size: 11px;
  padding-top: 3px;
  height: 36px;
  margin-top: -3px;
  display: flex;
}

.loanlabelmak4,
.loanlabelmak5 {
  padding-right: 2px;
  align-items: center;
}

.productmix,
.signinheader {
  font-weight: 600;
  font-size: 13px;
  font-family: Arial;
  color: #2c007d;
  text-align: center;
  padding-bottom: 8px;
}

.product3rd {
  padding-top: 59px;
  width: 100%;
}

.col-4.coltable,
.col-md-3.coltable {
  padding-right: 0;
  padding-left: 10px;
}

.loanlabelmak,
.loanlabelmak2 {
  color: #fff;
  line-height: 12px;
  padding-top: 3px;
  height: 35px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  border: 1px solid #c1bebe;
  font-size: 0.8em !important;
  font-weight: 600;
}

.productcomm2,
.productpre {
  padding-top: 39px;
  text-align: center;
  width: 100%;
}

.form-control.formpprofile,
.form-control.formpprofile2 {
  background-color: #dcf4fb;
  padding-left: 3px;
  font-family: Arial;
  font-size: 11px;
  height: 35px;
  margin-bottom: 3px;
  text-align: center;
  border-radius: 2px;
  font-weight: 600;
}

.productcomm2,
.productpre {
  padding-top: 39px;
  text-align: center;
  width: 100%;
}

.cardform {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  background-color: #26abe7;
  border: 1px solid #26abe7;
  border-radius: 13px;
  padding: 15px 20px 15px 20px;
  margin-top: 46px;
  margin-bottom: 10px;
}

.cardform,
.cardformuw {
  display: block;
  background-color: #26abe7;
  margin: 46px auto 10px;
}

.otp,
.sign {
  text-align: center;
  padding-top: 12px;
}

.btn.btn-warning.cardbutton,
.btn.btn-warning.cardbuttonuw,
.btn.btn-warning.cardchecker {
  background-color: #192859;
  border-color: #090908;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #fff;
}

.third-Editable {
  padding-top: 17px;
  text-align: center;
}

.editable-button {
  margin: 7px;
  font-size: 12px;
  background-color: #dcf4fb;
  font-family: Arial;
  color: gray;
  padding: 3px 20px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid gray;
}

.noteditable-button,
.noteditable-button-com,
.suggested-button {
  margin: 7px;
  font-size: 12px;
  padding: 3px 10px;
  height: 26px;
  font-family: Arial;
}

.noteditable-button,
.noteditable-button-com {
  color: gray;
  border-radius: 2px;
  border: 1px solid gray;
}

label.profile-label {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 6px;
}

label.product-re.profile-label {
  padding-top: 4px;
}

.form-group.companyformprofile {
  margin-bottom: 10px;
}

label.collpay {
  margin-top: 27px;
}

label#field1 {
  color: #fff;
  line-height: 12px;
  padding-top: 3px;
  height: 35px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}

a.userlink.active {
  color: #fff !important;
  background-color: #2c007d !important;
  border-radius: 15px !important;
  padding: 8px 12px !important;
}

select.form-control:not([size]):not([multiple]) {
  height: 35px;
  padding-left: 0 !important;
}

@media (min-width: 320px) {
  .container-fluid {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  p.collectionheader {
    padding-top: 1em;
  }

  .p-2.managemak1 {
    width: 5.5%;
    padding: 0.08rem !important;
  }

  .p-2.managemak2 {
    width: 13.5%;
    padding: 0.08rem !important;
  }

  label.product-re {
    font-size: 13px;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .loanlabelmak {
    color: #fff;
    line-height: 10px;
    font-size: 0.7em !important;
    padding-top: 3px;
    height: 35px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    border: 1px solid #c1bebe;
    font-weight: 600;
  }

  .form-control.comissionfield2 {
    font-size: 0.6em;
    padding: 0.2em;
  }

  .loanlabelmak5 {
    color: #fff !important;
    line-height: 13px !important;
    font-size: 0.7em !important;
    padding-top: 7px;
    height: 36px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 600;
  }

  .p-2.flexnowrapmak2 {
    padding-left: 0.2em !important;
  }

  .loanlabelmak4 {
    color: #fff !important;
    line-height: 13px !important;
    font-size: 0.7em !important;
    padding-top: 3px;
    height: 35px;
    margin-bottom: 3px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 1px solid #c1bebe;
    font-weight: 600;
  }

  p.signinheader.commheader {
    padding-bottom: 0em;
  }

  label.product3rd {
    font-size: 0.8em;
    padding-top: 4.9em;
  }

  label.productpre {
    font-size: 0.8em;
  }

  label.productcomm2.setpre {
    font-size: 0.8em;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .cardform {
    width: 80%;
    padding: 15px 15px 15px 15px;
  }

  .form-group.togglemanagemak {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
  }

  label.usertheadermak.email {
    padding-top: 0.1em;
    width: 82%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .form-control.formproduct {
    font-size: 0.6em;
    padding-right: 1px;
  }

  label.usertheadermak {
    line-height: 9px;
    font-size: 8px !important;
    margin-bottom: 3px;
  }
}

@media (min-width: 480px) {
  .form-control.comissionfield2 {
    font-size: 0.7em;
  }

  p.signinheader.inshead {
    padding-bottom: 0em;
    padding-top: 4em;
  }

  p.signinheader.commheader {
    padding-top: 20px;
  }

  label.productcomm2.setpre {
    width: 80%;
  }

  .cardform {
    width: 55%;
  }

  textarea.form-control.formproduct.margn.desk {
    font-size: 0.7em;
  }

  select.form-control.formselectblue {
    font-size: 0.7em;
    padding-right: 0px;
  }

  textarea.form-control.formproduct.margn.emaildesk {
    padding-top: 0px;
    padding-right: 2px !important;
    font-size: 0.7em !important;
    line-height: 12px;
  }

  label.usertheadermak.email {
    padding-top: 0em;
  }

  .form-control.formproduct {
    font-size: 0.7em;
    padding-right: 1px;
  }

  label.usertheadermak {
    line-height: 10px;
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  label.product-re.profile-label {
    padding-top: 0px;
  }

  .product2,
  .productcomm {
    padding-top: 28px;
  }

  .productcomm.profile-label {
    padding-top: 14px;
  }

  .product.profile-label {
    padding-top: 40px;
  }

  .usertheaderch.user6 {
    padding: 5px;
    padding-bottom: 0px;
  }

  label.usertheaderch.user5 {
    width: 100%;
  }
  .userlink {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .product.profile-label {
    padding-top: 30px;
  }

  .form-control.formprulesch.loanlabelmak7.secondsec {
    margin-top: 22px;
  }

  .usertheaderch.user6 {
    padding: 5px;
    padding-bottom: 10px;
  }

  label.usertheaderch.user5 {
    width: 100%;
  }

  .form-control.formprulesch.loanlabelmak7.incomegl {
    margin-top: 42px;
  }
}

@media (min-width: 1025px) {
  label.product {
    font-size: 13px;
    padding-top: 2em !important;
  }

  label.product.profile-label {
    padding-top: 2.4em !important;
  }

  .loanlabelmak {
    line-height: 12px;
  }

  label.product2 {
    padding-top: 1.4em;
    font-size: 13px;
    color: #000;
    font-weight: 600;
  }

  .loanlabelmak5 {
    line-height: 12px;
  }

  .loanlabelmak4 {
    line-height: 12px;
  }

  label.product3rd {
    padding-top: 2em;
    font-size: 13px;
  }

  label.product3rd.makpro {
    padding-top: 1.5em !important;
  }

  label.productpre {
    padding-top: 0.3em !important;
    font-size: 13px;
  }

  label.productpre.profile-label {
    padding-top: 0.5em !important;
  }

  label.productcomm2.setpre {
    padding-top: 0.5em !important;
    font-size: 13px;
  }

  .cardform {
    width: 75%;
    padding: 10px 20px 10px 20px;
  }
}

@media (min-width: 1441px) {
  .loanlabelmak {
    font-size: 0.9em !important;
  }

  .form-control.comissionfield2 {
    font-size: 0.9em;
  }

  label.product2 {
    padding-top: 13px;
  }

  label.product2.profile-label {
    padding-top: 18px;
  }

  .loanlabelmak5 {
    font-size: 0.9em !important;
  }

  .loanlabelmak4 {
    font-size: 0.9em !important;
  }

  .form-control.formpprofile {
    font-size: 0.8em;
  }

  .form-control.formpprofile2 {
    font-size: 0.8em;
  }

  .cardform {
    width: 60% !important;
  }
}

@media (min-width: 600px) {
  .loanlabelmak {
    font-size: 0.8em !important;
  }

  .form-control.comissionfield2 {
    font-size: 0.8em;
  }

  .loanlabelmak5 {
    font-size: 0.8em !important;
  }

  label.productcomm2.setpre {
    width: 60%;
  }

  .cardform {
    width: 40%;
  }

  label.usertheadermak.email {
    width: 100%;
  }

  label.usertheadermak.designation {
    padding-top: 0px;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  label.usertheadermak {
    line-height: 12px;
    font-size: 11px !important;
    padding-bottom: 0px;
  }
}

@media (min-width: 801px) {
  p.signinheader.inshead {
    padding-top: 0em;
    padding-bottom: 2.5em;
  }

  p.signinheader.commheader {
    padding-top: 0em;
  }

  label.product3rd.makpro {
    padding-top: 3.4em;
  }

  label.product3rd {
    padding-top: 3.5em;
  }

  label.productpre {
    padding-top: 2em;
  }

  label.productcomm2.setpre {
    width: 100%;
    padding-top: 2em;
  }

  .cardform {
    width: 85%;
  }
}
