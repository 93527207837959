.inventory {
  padding: 40px 200px 0px 200px;
}

.col-md-7.col-xl-7.col.inventory-content-col {
  padding-right: 0px;
}

.business-payment-body-1.trackcollected2 {
  padding: 80px 260px;
}

.business-payment-body-1.trackcollected3 {
  padding: 0px 260px 100px 260px;
}

.business-payment-body-1.agencyinventory.montraagent {
  padding-bottom: 180px !important;
}

.invetoryfooter {
  margin-top: 145px;
}

.inventory .col h1 {
  color: #20336b;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
  margin-bottom: 0px;
  padding-top: 10px;
}

.inventory1 {
  padding: 15px 0;
}

.inventory-sm-btn p {
  margin-bottom: 0px !important;
  padding: 0px !important;
  padding-left: 6px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding-top: 2px !important;
  color: #20336b;
}

.inventory-sm-btn {
  width: -moz-fit-content !important;
  width: fit-content !important;
  border: 1px solid #20336b;
  border-radius: 50px;
  display: flex;
  padding: 5px 10px;
}

.inventory .col h1 span {
  color: #20336b;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
}

.inventory .col ul {
  margin: 0 0 0 20px;
  padding: 0;
}

.inventory .col ul li {
  color: #20336b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  padding: 5px 0;
  padding-bottom: 0px;
}

.inventory-hero2 img {
  max-width: 90%;
  margin-left: auto;
  display: block;
  padding-top: 50px;
}

/* seema */

.col.inventory-hero2 {
  padding-left: 30px;
  padding-top: 50px;
}

.business-payment-body-1.agencyinventory {
  padding: 60px 300px 80px 300px;
}

.business-payment-body-1.agencyinventory.montraagent {
  padding-top: 75px;
  background-color: #f6f9fc;
}

.business-payment-body-1.agencyinventory.montraagent h1 {
  color: #20336b;
}

@media only screen and (max-width: 767px) {

  .inventory .col h1,
  .inventory .col h1 span {
    font-size: 35px;
    line-height: 43px;
  }

  .inventory1 {
    padding: 10px 0px 10px 0px !important;
  }

  .col.inventory-hero2 {
    padding-left: 30px;
    padding-top: 20px !important;
  }

  .inventory1 {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .inventory1 {
    width: auto !important;
  }

  .inventory1 {
    padding: 0px 0px 30px 0px !important;
  }

  .business-payment-body-1.agencyinventory {
    padding: 60px 20px 80px 20px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 920px) {
  .inventory {
    padding: 30px 20px 50px 20px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 920px) {
  .inventory {
    padding: 30px 20px 50px 20px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .inventory {
    padding: 20px 20px !important;
  }

  .inventory .col h1 {
    width: 100% !important;
  }

  .business-payment-body-1.agencyinventory {
    padding: 60px 100px 80px 100px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .business-payment-body-1.agencyinventory {
    padding: 60px 100px 80px 100px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .business-payment-body-1.agencyinventory {
    padding: 60px 80px 80px 80px;
  }
}

@media only screen and (max-width: 920px) {
  .business-payment-body-1.agencyinventory {
    padding: 60px 20px 80px 20px !important;
  }
}