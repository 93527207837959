.imp-hero {
  padding: 70px 200px 90px 200px;
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}

.hero-text {
  color: #101010;
  margin-bottom: 10px;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 68px;
}

.imp-hero button {
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  background: #ffc000;
  color: #101010;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  line-height: 145%;
  /* 23.2px */
}

.imp-body {
  padding: 80px 200px 155px;
  background-color: #f6f9fc;
  text-align: center;
}

section.imp-body h2 {
  font-size: 38px;
  font-weight: 600;
  color: #000;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4.5rem;
  row-gap: 3rem;
  margin-block-start: 4rem;
}

.grid-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  text-align: left;
}

.grid-item h5 {
  font-weight: 500;
  font-size: 16px;
}

.grid-item p {
  color: #425466;
  font-weight: 400;
  font-size: 15px;
}

button.sol-button {
  background-color: #ffc000 !important;
  color: #000 !important;
  font-weight: 500 !important;
}

button.sol-button:hover {
  color: #fff !important;
}

@media only screen and (max-width: 767px) {
  .imp-hero {
    padding: 30px 30px !important;
  }

  .hero-text {
    font-size: 25px !important;
    line-height: 35px !important;
  }

  .hero-pic {
    width: 350px !important;
  }

  .imp-body {
    padding: 30px 30px !important;
  }

  .grid {
    grid: none;
  }
}

@media only screen and (max-width: 768px) {
  .imp-hero {
    padding: 70px 20px;
    flex-direction: column;
    align-items: center;
  }

  .hero-pic {
    width: 400px;
  }

  .hero-text {
    font-size: 30px;
    line-height: 50px;
  }

  .imp-body {
    padding: 70px 50px;
  }

  .list-para {
    font-size: 12px;
  }

  .imp-body h5 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .imp-hero {
    padding: 70px 20px;
  }

  .hero-text {
    font-size: 35px;
    line-height: 60px;
  }

  .hero-pic {
    width: 50%;
  }

  .imp-body {
    padding: 70px 100px;
  }

  .list-para {
    font-size: 15px;
  }
}

/* @media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .imp-hero {
    padding: 70px 100px;
  }
} */