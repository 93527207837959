:root {
  --title-font-size: 47px;
  --title-lineheight: 55px;
  --title-font: var(--title-font-size) / var(--title-lineheight);
  --hero-para-font-size: 16px;
}

#DTS {
  overflow: hidden;
}

img.dts-hero-image {
  padding-top: 55px;
}

.lineparagrapduo {
  border-left: 1px solid #ffc000;
  padding-left: 8px;
}

.dtsvideocontent {
  padding-top: 30px;
}

.dts-hero {
  padding: 30px 200px;
}

.duo-list-container p {
  width: fit-content;
}

.dts-body-list-p {
  font-size: 16px;
}

.dts-hero button {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffc000;
  color: #101010;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  line-height: 145%;
  /* 23.2px */
}

.dts-hero img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
  max-width: 100%;
}

.dts-hero-image {
  margin-right: auto !important;
  justify-content: start !important;
  /* margin-top: -80px !important; */
}

.dts-body {
  padding: 70px 200px;
  background: #f6f9fc;
}

.dts-body2 {
  padding: 70px 300px 170px 300px;
}

.dts-body2 h6 {
  color: #101010;
  padding-bottom: 5px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dts-body2 p {
  color: #425466;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* width: 400px; */
}

.dts-body2 button {
  border-radius: 8px;
  background: #000;
  border: none;
  color: #fff;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 15px;
}

.dts-body2 .col:nth-child(2) {
  margin: 60px 0 0 20px;
}

@media (min-width: 1700px) {
  .dts-body2 .col:nth-child(1) {
    text-align: -webkit-center;
  }
}

@media (min-width: 1800px) {
  .dts-body2 .col:nth-child(1) {
    text-align: -webkit-right;
  }
}

/* .duo-list-container.dts-container p {
  font-size: 18px !important;
  line-height: 28px;
} */

.dts-hero h1 {
  color: #101010;
  font-size: var(--title-font-size);
  /* line-height: var(--title-lineheight); */
  font-style: normal;
  font-weight: 500;
  line-height: 58px;
  margin-bottom: 10px;
}

.dts-hero h1 span {
  color: #101010;

  /* font-size: 50px; */
  font-size: var(--title-font-size);
  /* line-height: var(--title-lineheight); */
  line-height: 58px;
  font-style: normal;
  font-weight: 500;
}

.dts-hero-p {
  font-size: 17.5px !important;
}

/* .dts-frame {
  border: 1px solid #9db1c5;
  border-radius: 15px;
} */

/* .dts-frame-img img {
  position: absolute;
  margin-top: -30px;
  margin-left: 15px;
} */

.dts-frame-img {
  max-width: 100%;
  margin-bottom: 18px;
  margin-left: 8px;
}

.dwp-sm-btn.act-sm-btn p {
  padding-top: 0px;
  font-size: 14px;
}

.duo-lists.dts-list {
  padding-left: 8px;
  padding-right: 30px;
}

.dts-frame-header h6 {
  font-size: 18px;
  font-weight: 500;
  padding: 0px 0px 0 0px;
  color: #101010;
}

* .dwp-sm-btn.act-sm-btn p {
  padding-top: 0px !important;
  font-size: 14px !important;
}

.dts-list {
  width: 500px;
  height: auto;
  max-width: 100%;
  padding: 0 13px;
  max-height: 100%;
}

.dts-list p {
  width: 100%;
  font-weight: 400;
  font-size: 15px;
}

.patended {
  color: #425466;
  font-weight: bold;
}

.dts-container {
  width: 100%;
}

/* .dts-container p {
  text-align: justify;
} */

.video-image {
  width: 460px;
  height: 299px;
  /* position: absolute; */
  top: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .dts-hero h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .dts-hero h1 span {
    font-size: 35px;
    line-height: 45px;
  }

  .dts-hero img {
    margin-right: auto;
  }

  .dts-body {
    padding: 50px 20px !important;
  }

  .dts-body2 {
    align-items: flex-start !important;
    padding-bottom: 0px !important;
  }

  .dts-hero-image {
    width: auto !important;
    margin-right: auto;
    padding-top: 0px !important;
  }

  .dts-list {
    height: auto;
  }

  .dts-body2 .col:nth-child(2) {
    /* margin: 80px 0px 0px 0px !important; */
    margin: -20px 0px 0px 0px !important;
    width: 100%;
  }

  .dts-hero button {
    margin: 20px 0 10px 0;
    padding: 10px;
  }

  .dts-list {
    width: 100%;
    height: 100%;
  }

  .video-image {
    width: 100%;
  }

  .dts-body2 p {
    width: 100%;
    font-size: 14px;
  }

  .dts-list p {
    font-size: 13px;
  }

  .dtsvideocontent {
    padding-top: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .dts-hero h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .dts-hero h1 span {
    font-size: 35px;
    line-height: 45px;
  }

  .dts-hero img {
    margin-right: auto;
  }

  .dts-body {
    padding: 50px 50px !important;
  }

  .dts-body2 {
    align-items: flex-start !important;
  }

  .dts-hero-image {
    width: auto !important;
    margin-right: auto;
  }

  .dts-list {
    height: auto;
  }

  .dts-body2 .col:nth-child(2) {
    margin: 20px 0px 0px 150px !important;
    width: 60%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .dts-container p {
    font-size: 14px;
  }

  .dts-list {
    padding: 0 10px !important;
  }

  .dts-body {
    padding: 50px 100px;
  }

  .dts-hero-row {
    align-items: start !important;
  }

  .dts-hero {
    padding: 20px 20px 70px 20px !important;
  }

  .dts-hero h1 {
    font-size: 40px;
    line-height: 56px;
  }

  .dts-hero h1 span {
    font-size: 40px;
    line-height: 56px;
  }

  .dts-list {
    height: auto;
  }

  .dts-list p {
    font-size: 14px;
  }

  .dts-body2 {
    padding: 70px 150px 0px 150px;
  }

  .dts-body2 .col:nth-child(2) {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .video-image {
    width: 330px;
    height: auto;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .dts-body2 {
    padding: 70px 150px;
  }

  .dts-body2 .col:nth-child(2) {
    margin: 50px 0 0 50px;
  }

  .dts-list {
    height: fit-content;
  }
}

@media (min-width: 1550px) {
  .dts-content {
    width: 80%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .dts-hero {
    padding: 10px 80px 100px 80px !important;
  }

  .hero-row {
    align-items: center !important;
  }

  .duo-body {
    padding: 70px 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .duo-lists p {
    padding-right: 0px !important;
  }

  .col-md-6.dts-frame.dcp-frame.dcp-frame-2 {
    margin-top: 25px;
  }

  img.dwpimgall {
    max-width: 85%;
    padding-top: 30px !important;
  }
}

@media only screen and (max-width: 920px) {
  .dts-body {
    padding: 70px 20px;
  }

  .duo-lists p {
    padding-right: 0px !important;
  }

  .row.dwp-row {
    flex-direction: row;
  }

  .dts-hero.act-hero {
    padding: 30px 20px !important;
  }

  .row.hero-row {
    flex-direction: row;
  }
}

@media only screen and (max-width: 1280px) {
  .dts-body {
    padding: 70px 80px;
  }

  img.dwp-hero-img {
    padding-top: 120px !important;
  }

  .dcp-body {
    padding: 70px 80px !important;
  }
}