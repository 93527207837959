/**
  collectionstyle.css is render all stylesheet in operations section
 Author : Seema Kumari

 @version :1.0 */

.collactiontext {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.collactiontext1 {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.collectionheadertext {
  margin-top: 7px;
  margin-bottom: 20px;
}

.collectionsec {
  padding: 15px;
  background-color: #192859;
  color: white;
}

.downloadreport {
  color: #e69f45;
  font-size: 11px;
  font-family: Arial;
  font-weight: 600;
  text-align: center;
  padding-top: 18px;
}

.collectionsec2 {
  padding: 15px;
  background-color: gray;
  color: white;
}

.collactiontext2 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 600;
  color: #fff;
}

.row.holdsec {
  padding-top: 60px;
}

.row.holdsec2 {
  padding-bottom: 128px;
}

.row.holdsec3 {
  padding-top: 40px;
}

.reportcols {
  text-align: center;
}

.row.holdsec4 {
  padding-bottom: 79px;
}

.row.reportrow {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.col-6.reportlist2 {
  padding: 0px;
}

.col-6.reportlist2 {
  padding: 0px;
  font-size: 15px;
  font-family: Arial;
  font-weight: 600;
}

.reportcols {
  padding-left: 35px;
}

/* Media-query :- It uses the @media rule to include a block of CSS properties only if a certain condition is true.
Using media queries are a popular technique for delivering a tailored style sheet
to desktops, laptops, tablets, and mobile phones (such as iPhone and Android phones). */

@media only screen and (max-width: 1280px) {
  .row.reportrow {
    width: 33% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1366px) {
  .row.reportrow {
    width: 33% !important;
  }
}

@media only screen and (max-width: 992px) {
  .row.reportrow {
    width: 45% !important;
  }
}

@media only screen and (max-width: 768px) {
  .row.reportrow {
    width: 70% !important;
  }
}

@media only screen and (max-width: 414px) {
  .row.reportrow {
    width: 50% !important;
  }
}
