.appp-bank img {
  width: 120px !important;
  height: 36px;
}

.personal-child-body-col.openpersonal {
  margin-top: 20px;
}

.col-md-5.col-xl-4.openbank {
  padding-left: 0px;
}

img.openaccountsecond {
  max-width: 100%;
}

img.openaccountsecond {
  max-width: 100%;
}

.bankfeatures {
  width: 250px;
  height: auto;
}

img.openaccount {
  max-width: 100%;
}

img.chat-img {
  padding-top: 15px;
  padding-bottom: 20px;
}

p.enjoy {
  padding-left: 8px;
}

.lineparagrapproducts {
  border-left: 1px solid #3fafe9;
  padding-left: 8px;
}

img.imgpadding {
  padding-bottom: 20px;
  padding-left: 8px;
}

.col-md-6.chat-hero-right {
  width: 438px;
  margin-left: auto;
  padding-top: 15px;
}

.banksteps {
  margin-top: 15px;
}

.banksteps1 {
  padding-top: 60px;
}

img.PersonalBankingBannerImg {
  max-width: 85%;
}

.personalcountimghidden {
  background: #3fafe9;
  color: #fff;
  width: 22px;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}

.personalcountimghidden.twoimg {
  margin-top: 40px;
}

.bank-step-content {
  margin-left: 10px;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .second-navbar {
    display: none;
  }

  .PersonalBankingBannerImg {
    width: 100%;
  }

  img.openaccount {
    max-width: 100%;
  }

  .banking-body-image-col {
    display: flex;
    justify-content: center;
  }

  .col.banking-hero-left {
    padding-top: 0px !important;
  }

  .banking-hero-left h1 {
    font-size: 35px !important;
    line-height: 43px !important;
  }

  .bank-body-section-1 h1 {
    font-size: 35px !important;
    margin-bottom: 25px !important;
  }

  .bank-body-section-1-1,
  .freevirtual {
    height: auto !important;
  }

  .bank-step-content {
    margin-left: 0px;
  }

  .banksteps1 {
    padding-top: 0px !important;
  }

  .banking-hero {
    padding: 20px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .col.banking-hero-left {
    padding-top: 0px !important;
  }

  .personal-child-body-col {
    width: 100% !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .col.banking-hero-left {
    padding-top: 0px;
  }

  .banking-hero {
    padding: 50px 20px 50px 20px !important;
  }

  .banking-hero-left h1 {
    font-size: 40px;
    line-height: 55px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .banking-hero {
    padding: 50px 80px 50px 80px !important;
  }

  .payment-hero-left {
    padding-right: 0px !important;
    width: 50% !important;
  }

  .personal-hero.payment-hero-p {
    width: 100% !important;
    margin: 15px 0px !important;
  }
}

@media (min-width: 1281px) and (max-width: 1439px) {
  .payment-hero-left {
    padding-right: 0px !important;
    width: 50% !important;
  }

  .personal-hero.payment-hero-p {
    width: 100% !important;
    margin: 15px 0px !important;
  }

  .bank-body-section-2 {
    padding: 50px 200px 100px 200px !important;
  }
}

@media (max-width: 1440px) {
  .appp-bank img {
    margin-top: 0px;
  }
}
