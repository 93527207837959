/**
 Description:CheckerStyle.css purpose for render all  styles and colors for checker section
 Author : Seema Kumari
 Date and time : 24th August 2020
 @version :1.0
 */

.checkerside-gridbutton {
  width: 71%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.checkerside-gridbutton {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

p.usertextch.creditsuw {
  padding-top: 1em;
}

p.usertextch2 {
  padding-top: 1em;
}

.container-fluid.undercontainer {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.container-fluid.adminproduct {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.p-2.headertextcheck {
  width: 80%;
  padding-bottom: 0px !important;
}

.incomeglchecker {
  padding-top: 60px;
}

.formboxcheckerside {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;

  background-color: #26abe7;
  border: 1px solid #26abe7;
  border-radius: 13px;
  padding: 15px 28px 15px 28px;
}

.headertextcheck {
  font-size: 11px;
  font-weight: 600;
  color: #000;
  text-align: center;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 1rem;
  padding-top: 86px;
}

.labelglrowgch {
  padding-top: 93px;
}

.incomegl {
  padding-top: 51px;
}

.productheadercuwchecker {
  font-size: 11px;
  font-weight: 600;
  color: #000;
  text-align: center;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 1rem;
  padding-top: 78px;
}

.checkerunderwritting {
  font-size: 11px;
  font-weight: 600;
  color: #000;
  text-align: center;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 1rem;
  padding-top: 70px;
}

/** start manage user    */

.p-2.managech {
  width: 5%;
  padding: 0.08rem !important;
}

.form-group.togglemanagech {
  height: 46px;
  padding-top: 9px;
}

.p-2.managech1 {
  width: 15%;
  padding: 0.08rem !important;
}

.p-2.managech2 {
  padding-top: 0px !important;
  width: 10%;
  text-align: center;
}

.p-2.managech3 {
  padding-top: 11px !important;
  width: 5%;
  text-align: center;
  background-color: #fff;
  margin-left: 8px !important;
}

.p-2.managecheck4 {
  padding-top: 11px !important;
  width: 5%;
  text-align: center;
  background-color: #fff;
  margin-left: 0px !important;
}

.usertextch {
  font-size: 15px;
  font-weight: 600;
  padding-top: 5px;
  padding-left: 9px;
  padding-bottom: 7px;
}

.serial-header {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  padding-top: 0px;
  text-align: center;
  display: block;
  margin-bottom: 0px;
}

.heightch {
  height: 46px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
}

.serialch {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.form-group.togglemanagecheck {
  height: 46px;
  padding-top: 9px;
}

.d-flex.flex-row.usercuw {
  background-color: #ececec;
  padding-right: 4px;
}

.pafuc {
  width: 100%;
  padding-right: 4px;
  background-color: #ececec;
  padding-bottom: 10px !important;
  padding-left: 4.7%;
}

.usertextch2 {
  font-size: 15px;
  font-weight: 600;
  padding-top: 0px;
  text-align: center;
  padding-bottom: 0px;
}

.p-2.managech4 {
  width: 12.5%;
  padding: 0.08rem !important;
}

.p-2.managech5 {
  width: 6.5%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.usertheaderchsub {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  padding-top: 18px;
  text-align: center;
  display: block;
}

.form-group.actiflag {
  position: absolute;
  padding-top: 40px;
}

.btn.btn-success.removal {
  padding: 3px 5px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
}

/** end manage user    */

.p-2.productform {
  width: 25%;
  padding: 0.08rem !important;
}

.underthreech {
  padding-right: 15px;
}

.underfourch {
  padding-left: 4px !important;
}

.d-flex.flex-row.mt-3.mtflex {
  margin-top: 0px !important;
}

.labelchpt-5 {
  font-size: 9px;
  text-align: center;
  padding-top: 24px;
  color: #5bb7e7;
  width: 73px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.labelchpt-3 {
  font-size: 9px;
  text-align: center;
  padding-top: 49px;
  color: #5bb7e7;
  width: 73px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.labelchpt-2 {
  font-size: 9px;
  text-align: center;
  padding-top: 7px;
  color: #5bb7e7;
  width: 73px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.labelchpt {
  font-size: 9px;
  text-align: center;
  padding-top: 15px;
  color: #5bb7e7;
  width: 73px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.productlabelch7 {
  font-size: 9px;
  text-align: center;
  padding-top: 30px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-group.aligncenter.inputgraygl {
  border: 1px solid gray;
  margin-bottom: 3px;
  height: 40px;
  padding-left: 3px;
  font-size: 11px;
  background-color: #e9ecef;
  padding-right: 3px;
}

.form-control.formpprofilegl {
  margin-bottom: 0px;
  background-color: transparent;
  border: none;
  text-align: center;
  height: 18px;
  font-size: 10px !important;
  padding-top: 4px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  line-height: 9px;
}

.form-group.aligncenter.inputgrayfield {
  border: 1px solid gray;
  margin-bottom: 3px;
  height: 46px;
  padding-left: 3px;
  font-size: 11px;
}

.input-group.aligncenter.profileinputgreen {
  /** background-color: #bce394; */
  border: 1px solid gray;
  height: 34px;
}

.reducerd {
  padding-left: 0px;
  padding-right: 0px;
}

.productlabelch5 {
  font-size: 9px;
  text-align: center;
  padding-top: 35px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.productlabelch11 {
  font-size: 9px;
  text-align: center;
  padding-top: 17px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.productlabelch12 {
  font-size: 9px;
  text-align: center;
  padding-top: 17px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.productlabelch13 {
  font-size: 9px;
  text-align: center;
  padding-bottom: 4px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.productlabelch10 {
  font-size: 9px;
  text-align: center;
  padding-top: 30px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-control.forminputfield.mb-0 {
  background-color: transparent;
  border: none;
  width: auto;
}

.input-group.aligncenter.inputgreen {
  background-color: #bce394;
  border: 1px solid gray;
  height: 34px;
}

.productmix {
  padding-top: 0px;
}

.switchtoggle {
  position: relative;
  display: block;
  width: 36px;
  height: 18px;
  margin-bottom: 0px;
  margin-right: auto;
  margin-left: auto;
}

.input-group.aligncenter.profileinputgreen {
  flex-wrap: initial;
}

.productheader.baseten {
  padding-top: 32px;
}

.aligncenter,
.switchheight {
  align-items: center;
}

.round {
  text-align: center;
  padding-top: 5px;
}

.productmixcollectionpage {
  padding-top: 30px;
  padding-bottom: 60px;
}

.switchtoggle input {
  display: none;
}

.checkerslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.checkerslider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 3px;
  bottom: 5px;
  background-color: white;
  transition: 0.4s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-radius: 50%;
}

input:checked+.checkerslider {
  background-color: #2ab934;
}

input:focus+.checkerslider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.checkerslider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/**------ ADDED CSS ---------*/
.checkerslider:after {
  content: "OFF";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 65%;
  font-size: 7px;
}

input:checked+.checkerslider:after {
  content: "ON";
  left: 12px;
}

/**--------- END --------*/

.form-control.checkerinputfield {
  width: 100%;
}

.checker-button {
  font-size: 12px;
  background-color: #bce394;
  color: #000;
  padding: 3px 5px 3px 5px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid gray;
  margin-bottom: 5px;
}

.Editable2 {
  padding-top: 0px;
  padding-left: 0px;
}

.noteditable-button2 {
  font-size: 12px;
  color: gray;
  padding: 3px 5px 3px 5px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid gray;
  margin-left: 5px;
}

.input-group.aligncenter.inputgreenlight {
  background-color: #bce394;
  border: 1px solid gray;
  margin-bottom: 3px;
  height: 35px;
  padding-left: 3px;
  font-size: 11px;
}

.form-group.aligncenter.inputgreenfield {
  background-color: #bce394;
  border: 1px solid gray;
  margin-bottom: 3px;
  height: 46px;
  padding-left: 3px;
  font-size: 11px;
}

.productfiled {
  height: 46px !important;
  margin-bottom: 3px;
}

.codeproduct {
  height: 46px !important;
  margin-bottom: 3px;
  background-color: gray !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.form-control.formpprofilefield {
  margin-bottom: 0px;
  background-color: transparent;
  border: none;
  text-align: center;
  height: 22px;
  font-size: 10px !important;
  padding-top: 4px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  line-height: 9px;
}

group.aligncenter.inputgrayfield.heightmanage {
  height: 55px !important;
}

.cardform {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  background-color: #26abe7;
  border: 1px solid #26abe7;
  border-radius: 13px;
  padding: 15px 20px 15px 20px;
  margin-top: 46px;
  margin-bottom: 10px;
}

.cardform-checker {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  background-color: #26abe7;
  border: 1px solid #26abe7;
  border-radius: 13px;
  padding: 15px 20px 15px 20px;
  margin-top: 46px;
  margin-bottom: 10px;
}

.p-2.producttogglecheck {
  width: 7%;
  padding: 0.08rem !important;
}

.input-group.aligncenter.inputgreenfield {
  background-color: #bce394;
  border: 1px solid gray;
  margin-bottom: 3px;
  height: 35px;
  padding-left: 3px;
  font-size: 11px;
}

.productlabelch {
  font-size: 9px;
  text-align: center;
  padding-top: 47px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.approval-button {
  margin: 7px;
  font-size: 12px;
  color: #192859;
  padding: 3px 10px 3px 10px;
  height: 26px;
  border-radius: 2px;
  background-color: #bce394;
  border: 1px solid gray;
}

.form-group.card1 {
  width: 20%;
}

.input-group.inputcardbutton {
  margin-top: 20px;
}

.p-2.collectionptchecker {
  margin-top: 121px;
  width: 65%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-group.card1 {
  width: 33%;
  display: inline-block;
}

.form-control.formpprofilecuw {
  margin-bottom: 0px;
  background-color: transparent;
  border: none;
  text-align: center;
  height: 18px;
  font-size: 10px !important;
  padding-top: 4px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  line-height: 9px;
}

.cardtext {
  font-size: 11px;
  text-align: center;
}

.switchtoggle.centertoggle {
  margin-left: 9px;
}

.switchtoggle.centertoggle1 {
  margin-left: 5px;
}

.switchtoggle.centertoggle3 {
  margin-left: 27px;
  opacity: 0.5;
}

.collectioncardchecker {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: #24baf2;
  border: 1px solid #24baf2;
  border-radius: 13px;
  padding: 15px 28px 15px 28px;
  margin-bottom: 13px;
}

.collectionmix {
  font-size: 9px;
  text-align: center;
  width: 70px;
  color: #5bb7e7;
  padding-top: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.collectionmix2 {
  font-size: 9px;
  text-align: center;
  width: 70px;
  color: #5bb7e7;
  padding-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.collectionmix3 {
  font-size: 9px;
  text-align: center;
  width: 70px;
  color: #5bb7e7;
  padding-top: 49px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.gridbutton {
  width: 71%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.labelptchecker {
  padding-top: 84px;
}

.gridbutton2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.pcode {
  padding-top: 89px;
  width: 83px;
}

.formboxchecker {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  width: 70%;
  background-color: #26abe7;
  border: 1px solid #26abe7;
  border-radius: 13px;
  padding: 15px 28px 15px 28px;
}

.productlabelch4 {
  font-size: 9px;
  text-align: center;
  padding-top: 64px;
  color: #5bb7e7;
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-control.productpch {
  margin-top: 92px;
  height: 35px;
}

.productcodech {
  padding-top: 89px;
  width: 83px;
}

.margnch {
  margin-top: 1px;
  height: 35px !important;
}

.margnun {
  margin-top: 14px;
  height: 35px !important;
}

.margnpgl {
  margin-top: 3px;
  height: 35px !important;
}

.noteditable-checker {
  font-size: 12px;
  color: gray;
  padding: 3px 10px 3px 10px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid gray;
  margin-top: 11px;
}

.col-md-12.checkeredit {
  padding: 0px;
}

.productbuttonch1 {
  padding-top: 34px;
  padding-left: 5px;
  margin-left: 5px;
}

.col-sm-12.col-md-6.reducepadding {
  padding: 0px;
}

.input-group.aligncenter.inputgreenfieldgray {
  background-color: #e9ecef;
  border: 1px solid gray;
  margin-bottom: 3px;
  height: 35px;
  padding-left: 3px;
  font-size: 11px;
}

.toogle-img {
  padding-left: 7px;
  display: block;
}

.procode {
  padding-top: 48px;
  font-size: 11px;
  text-align: center;
  width: 100%;
}

.p-2.trial {
  width: 20%;
  padding: 0.08rem !important;
}

.p-2.trial2 {
  width: 10%;
  padding: 0.08rem !important;
}

.p-2.trial3 {
  width: 14%;
  padding: 0.08rem !important;
}

.p-2.trial4 {
  width: 14%;
  padding: 0.08rem !important;
}

.p-2.md-trial {
  display: none;
}

.p-2.md-trial2 {
  display: none;
}

.p-2.md-trial3 {
  width: 20%;
  padding: 0.08rem !important;
}

.p-2.row-trial1 {
  display: none;
}

.p-2.row-trial2 {
  display: none;
}

.p-2.row-trial3 {
  width: 25%;
  padding: 0.08rem !important;
}

.reduce {
  padding: 0px;
}

.p-2.collform {
  width: 25%;
  padding: 0.08rem !important;
}

.approvebutton {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.productch {
  padding-top: 50px;
  font-size: 11px;
  text-align: center;
  width: 100%;
}

.form-control.checkerforms {
  height: 46px;
}

.labelpt-ch {
  padding-top: 52px;
}

.labelpt-ch2 {
  padding-top: 54px;
}

.form-control.checkerprogl {
  height: 46px;
  margin-bottom: 3px;
}

.form-control.comissioninput {
  background-color: gray;
  color: #fff;
  margin-bottom: 3px;
  height: 46px;
}

.textlabelch {
  color: #5bb7e7;
  font-size: 9px;
  text-align: center;
  padding-top: 11px;
}

.textpositionch {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding-top: 21px;
}

.textpositionch1 {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding-top: 40px;
}

@media only screen and (max-width: 414px) {
  .tabbutton {
    margin-top: 30px;
  }

  .heightch {
    height: 54px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .serialch {
    font-size: 9px;
  }

  .form-group.togglemanage.vation {
    padding-top: 4px;
  }

  .p-2.managech2.rem {
    padding-left: 4px !important;
  }

  .p-2.managech2 {
    text-align: center;
    padding: 0px !important;
  }

  .form-group.togglemanagecheck {
    padding-top: 20px;
    height: 54px;
  }

  .form-group.togglemanagech {
    height: 46px;
    padding-top: 13px;
  }

  .productheadermak.line {
    width: 40px;
  }

  .productheadersales {
    font-size: 8px;
    width: 100%;
    line-height: 0.7rem;
  }

  .incomegl {
    padding-top: 37px;
  }

  .underthreech {
    padding-right: 0px;
    padding-left: 0px;
  }

  .underfourch {
    padding-left: 0px !important;
    padding-right: 0px;
  }

  .groupfour {
    padding: 0px;
  }

  .form-group.aligncenter.inputgrayfield {
    height: 54px !important;
    padding-left: 0px !important;
  }

  .codeproduct {
    height: 54px !important;
    margin-bottom: 3px;
    text-align: center;
  }

  .noteditable {
    margin-right: 20px;
  }

  .productlabelch11 {
    padding-top: 35px;
  }

  .productlabelch12 {
    padding-top: 47px;
  }

  .productlabelch13 {
    padding-top: 19px;
  }

  label.productlabel7.new {
    padding-top: 51px !important;
  }

  label.productlabel8.new2 {
    padding-top: 51px !important;
  }

  label.productlabel9.new3 {
    padding-top: 63px !important;
  }

  label.productlabel.new4 {
    padding-top: 63px !important;
  }

  .productlabelch5 {
    padding-top: 39px;
  }

  .productheader {
    font-size: 9px !important;
  }

  .productlabel2 {
    padding-top: 63px !important;
    width: 55px !important;
  }

  .productlabelch4 {
    padding-top: 75px;
  }

  .productlabelch {
    padding-top: 63px !important;
  }

  .productlabel1 {
    padding-top: 63px !important;
  }

  .productlabel3 {
    padding-top: 39px !important;
  }

  .productlabel9 {
    padding-top: 46px !important;
    font-size: 8px !important;
  }

  .productlabel {
    padding-top: 58px !important;
    font-size: 8px !important;
  }

  .productlabel7 {
    padding-top: 46px !important;
    font-size: 8px !important;
  }

  .productlabel8 {
    padding-top: 35px !important;
    font-size: 8px !important;
  }

  .productlabel10 {
    padding-top: 39px !important;
    font-size: 8px !important;
  }

  .productlabel11 {
    padding-top: 28px !important;
    font-size: 8px !important;
  }

  .productlabel12 {
    padding-top: 16px !important;
    font-size: 8px !important;
  }

  .productlabel13 {
    padding-top: 18px !important;
    padding-bottom: 2px !important;
    font-size: 8px !important;
  }

  .productcodeloan {
    padding-top: 64px !important;
  }

  .collectionmix3 {
    padding-top: 42px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .p-2.trial {
    width: 20%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.trial2 {
    width: 10%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.row-trial1 {
    width: 22%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.row-trial2 {
    width: 10%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.row-trial3 {
    width: 17%;
    padding: 0.08rem !important;
  }

  .p-2.md-trial {
    width: 20%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.md-trial2 {
    width: 10%;
    padding: 0.08rem !important;
    display: block;
  }

  .p-2.md-trial3 {
    width: 14%;
    padding: 0.08rem !important;
  }
}

@media (min-width: 360px) {
  label.productcomm2.checkprem {
    width: 50% !important;
  }

  p.headertextcheck {
    width: 85% !important;
  }

  p.checkerunderwritting {
    width: 80% !important;
  }

  p.headertextcheck.a1 {
    width: 80% !important;
  }
}

@media (min-width: 320px) {
  .cardform-checker {
    width: 92%;
  }

  .checkerside-gridbutton {
    width: 92%;
  }

  .gridbutton2 {
    width: 82%;
  }

  .gridbuttonchecker {
    width: 80%;
  }

  .gridbutton {
    width: 80%;
  }

  .p-2.collectionptchecker {
    width: 78%;
  }

  /* Manage user section */

  .form-control.formpprofilefield {
    padding: 0px !important;
    font-size: 7px !important;
    padding-top: 3px !important;
    width: 100%;
    overflow-wrap: break-word;
    line-height: 10px;
    height: 33px;
  }

  .usertheaderch.user6 {
    padding-top: 1.1em;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  label.usertheaderch.userman {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .formboxchecker {
    width: 82%;
  }

  label.usersubheader {
    font-size: 8px;
    line-height: 9px;
  }

  .usertheaderch.us8 {
    padding-top: 3.4em;
  }

  label.serial-header.manser {
    padding-top: 2.3em;
    line-height: 9px;
    font-size: 8px;
  }

  label.serial-header {
    padding-top: 10px;
    line-height: 9px;
    font-size: 8px;
  }

  label.usertheaderch.usname1 {
    padding-top: 2.3em;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  label.usertheaderch.desig {
    padding-top: 2.3em;
  }

  label.usertheaderch.user5 {
    padding-top: 2.3em;
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  label.usertheaderch.user7 {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.1em;
  }

  .usertheaderch {
    line-height: 9px;
    font-size: 8px !important;
    margin-bottom: 3px !important;
  }

  .usertextch2 {
    font-size: 13px;
  }

  label.usertheaderch.actmanage {
    padding-top: 2.4em;
  }

  .p-2.managech1 {
    width: 13.5%;
  }

  .p-2.managech {
    width: 5.5%;
  }

  label.usertheaderch.userch {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  label.usertheaderch.emailch {
    padding-top: 0.1em;
    width: 82%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  label.usertheaderch.designationch.mobno {
    padding-top: 0.1em;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  /* under writing  */

  .underthreech {
    padding-left: 0em;
    padding-right: 0em;
  }

  .underfourch {
    padding-left: 0em;
    padding-right: 0em;
  }

  .groupfour {
    padding-left: 0em;
    padding-right: 0em;
  }

  p.headertextcheck.a1 {
    width: 90%;
  }

  label.labelpt-ch {
    font-size: 0.5em;
    padding-top: 8.5em;
  }

  label.labelpt-ch2 {
    font-size: 0.5em;
    padding-top: 8.5em;
  }

  label.labelpt-ch2.proch3 {
    font-size: 0.5em;
    padding-top: 8.5em;
  }

  p.headertextcheck.a2 {
    padding-top: 1.9em;
  }

  p.headertextcheck.a3 {
    padding-top: 2em;
  }

  p.headertextcheck.a4 {
    padding-top: 2em;
  }

  label.labelch-7 {
    font-size: 0.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    line-height: 1em;
    padding-top: 5.6em;
  }

  p.headertextcheck {
    font-size: 0.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    line-height: 1em;
    padding-top: 1em;
  }

  p.checkerunderwritting {
    font-size: 0.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    line-height: 1em;
    padding-top: 0em;
  }

  /* product mix  */

  label.productcodeloanch {
    font-size: 0.5em;
    padding-top: 7em;
  }

  p.productheader.well {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.5em !important;
    line-height: 1.2em;
    padding-top: 3.5em;
  }

  label.productchecker {
    font-size: 0.5em;
    padding-top: 4.5em;
  }

  label.procode1 {
    font-size: 0.5em;
    padding-top: 4.3em;
  }

  p.productheadermak.maks3 {
    padding-top: 1.2em;
    width: 100%;
  }

  label.procode.codecheck {
    padding-top: 4.4em;
  }

  label.productch.codecheck2 {
    padding-top: 4.7em;
  }

  label.productch {
    font-size: 0.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3.4em;
  }

  label.procode {
    font-size: 0.5em;
    padding-top: 3.1em;
  }

  label.product3rd.prepro {
    padding-top: 5.1em;
  }

  label.productcomm2.checkprem {
    padding-top: 0.6em !important;
    font-size: 0.8em;
    width: 69%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .loanlabelmak6 {
    color: #fff !important;
    line-height: 10px;
    font-size: 0.7em !important;

    height: 36px;
    display: flex !important;
    align-items: center !important;
    justify-content: left !important;
    font-weight: 600;
  }

  label.product2.loanmanage {
    padding-top: 1.3em;
  }

  .loanlabelcheck {
    height: 54px !important;
    font-weight: 600;
    font-size: 0.7em !important;
  }
}

@media (min-width: 480px) {
  .formboxchecker {
    width: 50%;
  }

  .gridbutton2 {
    width: 50%;
  }

  .gridbuttonchecker {
    width: 50%;
  }

  p.productheader.baseinterest {
    padding-top: 1em;
  }

  .tabbutton {
    margin-top: 2em;
  }

  label.usertheaderch.user7 {
    padding-top: 1.4em;
  }

  .usertheaderch.user6 {
    padding-top: 1.4em;
  }

  label.usertheaderch.emailch {
    padding-top: 0em;
  }

  label.usertheaderch.actmanage {
    padding-top: 2.13em;
  }

  label.serial-header {
    line-height: 10px;
    font-size: 10px !important;
  }

  .usertheaderch {
    line-height: 10px;
    font-size: 10px !important;
  }

  .form-control.formpprofilefield {
    height: 22px;
  }

  label.labelch-7 {
    padding-top: 4.6em;
  }

  .p-2.collectionptchecker {
    width: 60%;
    margin-top: 3em !important;
  }

  p.headertextcheck.a2 {
    padding-top: 1em;
  }

  label.productch {
    font-size: 0.7em;
    padding-top: 2.7em;
  }

  label.procode {
    font-size: 0.7em;
    padding-top: 2.5em;
  }

  label.productcodeloanch {
    font-size: 0.7em;
    padding-top: 4.4em;
  }

  p.productheader.well {
    font-size: 0.7em !important;
    padding-top: 1.6em;
  }

  .productheader.baseten {
    padding-top: 2.7em;
  }

  p.productheadermak.maks3 {
    padding-top: 1.1em;
  }

  label.procode.codecheck {
    font-size: 0.7em !important;
    padding-top: 3.7em;
  }

  label.productch.codecheck2 {
    font-size: 0.7em !important;
    padding-top: 3.9em;
  }

  label.procode1 {
    font-size: 0.7em !important;
    padding-top: 3.7em;
  }

  label.productchecker {
    font-size: 0.7em !important;
    padding-top: 3.9em;
  }

  .loanlabelcheck {
    height: 46px !important;
  }

  label.product2.loanmanage {
    width: 66%;
  }

  label.productcomm2.checkprem {
    width: 80% !important;
    padding-top: 3.5em !important;
  }

  .gridbutton {
    width: 55%;
  }
}

@media only screen and (max-width: 767px) {
  label.usertheaderch.emailch {
    padding-bottom: -1px;
  }

  label.usertheaderch.emailch,
  label.usertheaderch.designationch.mobno {
    padding-top: 0em;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  label.usertheaderch.emailch {
    padding-bottom: 0px !important;
  }

  label.usertheaderch.designationch.mobno {
    padding-bottom: 8px !important;
  }
}

@media only screen and (max-width: 400px) {
  .usertheaderch.user6 {
    padding-bottom: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {

  label.usertheaderch.emailch,
  label.usertheaderch.designationch.mobno {
    padding-bottom: 10px !important;
  }

  label.usertheaderch.user5 {
    padding-top: 1.3em !important;
  }
}

@media (min-width: 600px) {
  .gridbuttonchecker {
    width: 38%;
  }

  p.productheadermak.maks3 {
    width: 70% !important;
  }

  .form-control.formpprofilefield {
    font-size: 9px !important;
  }

  label.usertheaderch.designationch {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .gridbutton2 {
    width: 40%;
  }

  label.usertheaderch.desig {
    padding-top: 1.2em;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .usertheaderch.user6 {
    padding-top: 1.2em;
  }

  label.usertheaderch.user7 {
    width: 100%;
    padding-top: 1.2em;
  }

  .usertheaderch.us8 {
    padding-top: 2.3em;
  }

  label.serial-header.manser {
    padding-top: 1.8em;
  }

  label.usersubheader {
    font-size: 11px;
    line-height: 11px;
  }

  .usertextch2 {
    font-size: 15px;
  }

  .formboxchecker {
    width: 40%;
  }

  .usertheaderch {
    line-height: 12px;
    font-size: 11px !important;
  }

  label.serial-header {
    line-height: 11px;
    font-size: 11px !important;
    padding-top: 0px;
  }

  label.usertheaderch.designationch {
    padding-top: 0em;
  }

  label.usertheaderch.emailch {
    padding-top: 0em;
    width: 100%;
  }

  label.usertheaderch.designationch.mobno {
    padding-top: 0em;
    width: 100%;
  }

  label.usertheaderch.actmanage {
    padding-top: 0em;
    padding-bottom: 6px;
  }

  label.usertheaderch.userch {
    width: 100%;
    padding-top: 0em;
    padding-bottom: 7px;
  }

  label.usertheaderch.userman {
    width: 100%;
    padding-top: 0em;
    padding-bottom: 7px;
  }

  /* under writing  */

  p.headertextcheck.a3 {
    padding-top: 1em;
  }

  p.checkerunderwritting {
    font-size: 0.8em;
    width: 100% !important;
  }

  p.headertextcheck.a1 {
    width: 100% !important;
  }

  label.labelch-7 {
    padding-top: 3.6em;
  }

  p.headertextcheck {
    font-size: 0.8em;
    width: 100% !important;
  }

  label.labelch-7 {
    font-size: 0.8em;
  }

  label.labelpt-ch {
    font-size: 0.8em;
    padding-top: 6.2em;
  }

  label.labelpt-ch2.proch3 {
    font-size: 0.8em;
    padding-top: 4.4em;
  }

  label.labelpt-ch.proch {
    padding-top: 4.9em;
  }

  label.labelpt-ch2 {
    font-size: 0.8em;
    padding-top: 3.8em;
  }

  /* productmix  */

  .p-2.collectionptchecker {
    width: 45%;
  }

  p.productheader.baseinterest {
    width: 45% !important;
    padding-top: 1.1em;
  }

  label.productcomm2.checkprem {
    width: 60% !important;
  }

  label.procode1 {
    padding-top: 2.5em;
  }

  label.productchecker {
    padding-top: 2.8em;
  }
}

@media (min-width: 720px) {
  p.productheader.well {
    width: 60% !important;
  }

  .formbox {
    width: 40%;
  }

  .formbox.formcheck-side {
    width: 40% !important;
  }

  .p-2.collectionptchecker {
    width: 37%;
  }

  .formboxchecker {
    width: 100%;
  }

  .gridbutton2.formcheck-side {
    width: 40%;
  }

  .gridbutton2 {
    width: 100%;
  }

  p.headertextcheck.a3 {
    padding-top: 1.9em;
  }
}

@media only screen and (max-width: 768px) {
  .formroot .row {
    flex-direction: row;
  }

  .form-group.aligncenter.inputgraygl {
    margin-top: 17px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1200px) {

  label.usertheaderch.designationch.mobno,
  label.usertheaderch.emailch,
  label.usertheaderch.designationch {
    padding-top: 0.1em !important;
  }

  label.usertheaderch.user7 {
    padding-top: 1.2em !important;
  }
}

@media (min-width: 801px) {
  .formbox {
    width: 90% !important;
  }

  .gridbuttonchecker {
    width: 27%;
  }

  .labelglch {
    font-size: 0.9em;
  }

  label.usersubheader {
    font-size: 12px;
    line-height: 12px;
  }

  label.serial-header.manser {
    padding-top: 2.2em;
  }

  label.usertheaderch.user5 {
    padding-top: 2.2em;
  }

  .usertheaderch {
    line-height: 12px;
    font-size: 12px !important;
  }

  label.usertheaderch.designationch {
    padding-top: 0em;
  }

  label.usertheaderch.emailch {
    padding-top: 0em;
  }

  label.usertheaderch.designationch.mobno {
    padding-top: 0em;
  }

  label.usertheaderch.desig {
    padding-top: 2.2em;
  }

  label.usertheaderch.usname1 {
    padding-top: 2.2em;
  }

  label.usertheaderch.user7 {
    padding-top: 2.2em;
  }

  label.serial-header {
    line-height: 12px;
    font-size: 12px !important;
  }

  .usertheaderch.us8 {
    padding-top: 2.2em;
  }

  p.headertextcheck {
    width: 70% !important;
  }

  label.procode {
    font-size: 0.8em;
    padding-top: 1.1em;
  }

  p.checkerunderwritting {
    width: 65% !important;
  }

  p.headertextcheck.a1 {
    width: 58% !important;
  }

  label.productch {
    font-size: 0.8em;
    padding-top: 1.2em;
  }

  label.procode.codecheck {
    font-size: 0.8em !important;
    padding-top: 2.3em;
  }

  label.productch.codecheck2 {
    font-size: 0.8em !important;
    padding-top: 2.5em;
  }

  label.procode1 {
    font-size: 0.8em !important;
    padding-top: 2.3em;
  }

  label.productchecker {
    font-size: 0.8em !important;
    padding-top: 2.5em;
  }

  p.productheader.well {
    font-size: 0.8em !important;
    padding-top: 1.6em;
    width: 60% !important;
  }

  label.productcodeloanch {
    font-size: 0.8em;
    padding-top: 2.9em;
  }

  .productheader.baseten {
    padding-top: 1.6em;
  }

  .p-2.collectionptchecker {
    width: 27%;
  }

  label.productcomm2.checkprem {
    width: 100% !important;
    padding-top: 2em !important;
  }

  label.product3rd.prepro {
    padding-top: 3.7em;
  }
}

@media (min-width: 1025px) {

  label.product2.loanmanage {
    padding-top: 1em;
  }

  .loanlabelmak6 {
    font-size: 0.9em !important;
  }

  .labelglrowchecker {
    padding-top: 64px;
  }

  .p-2.managech1 {
    width: 17%;
  }

  .incomeglchecker {
    padding-top: 64px;
  }

  .labelglch {
    padding-top: 104px !important;
    font-size: 0.9em !important;
  }

  .labelgl {
    font-size: 0.9em;
  }

  .gridbuttonchecker {
    width: 50%;
  }

  p.productheadermak.maks3 {
    width: 80% !important;
  }

  p.productheader.well {
    width: 100% !important;
  }

  p.productheader.baseinterest {
    padding-top: 0.1em;
  }

  .usertextch {
    padding-left: 15px;
  }

  .form-control.formpprofilefield {
    font-size: 10px !important;
  }

  .formbox {
    width: 65% !important;
  }

  .formboxchecker {
    width: 65% !important;
  }

  .gridbutton2 {
    width: 65% !important;
  }

  label.usertheaderch.user5 {
    padding-top: 1.2em;
  }

  label.usertheaderch.desig {
    padding-top: 1.2em;
  }

  label.usertheaderch.usname1 {
    padding-top: 1.2em;
  }

  label.serial-header.manser {
    padding-top: 1.2em;
  }

  label.usertheaderch.user7 {
    padding-top: 1.2em;
  }

  .usertheaderch.us8 {
    padding-top: 1.2em;
  }

  p.headertextcheck {
    width: 100% !important;
  }

  p.checkerunderwritting {
    width: 100% !important;
  }

  p.headertextcheck.a1 {
    width: 100% !important;
  }

  p.signinheader.preasscheck {
    padding-bottom: 2.5em;
  }

  label.product2.loanmanage {
    width: 100%;
  }

  label.product3rd.prepro {
    padding-top: 1.5em;
  }

  label.productcomm2.checkprem {
    padding-top: 0.6em !important;
    font-size: 0.9em;
  }
}

@media (min-width: 1200px) {
  .col-sm-12.col-md-12.col-lg-12.col-xl-7.underone.checkunone {
    padding-top: 4.6em;
  }

  .underthreech {
    padding-left: 0em;
    padding-right: 0.7em;
  }

  xl-7.groupfour {
    padding-right: 0.7em !important;
  }

  .groupfour {
    padding-right: 0.8em;
  }

  .col-sm-12.col-md-12.col-lg-12.col-xl-5.groupfour {
    padding-left: 0.7em;
  }

  p.headertextcheck.a4 {
    padding-top: 1.9em;
  }

  label.labelch-7 {
    padding-top: 3.5em;
  }

  label.procode {
    padding-top: 4.3em;
  }

  label.productch {
    padding-top: 4.3em;
  }

  p.productheader.well {
    width: 100% !important;
    padding-top: 0.6em;
  }

  p.productheader.baseinterest {
    width: 80% !important;
  }

  .productheader.baseten {
    padding-top: 1.8em;
  }

  label.productcodeloanch {
    padding-top: 3.8em;
  }

  .p-2.collectionptchecker {
    width: 70%;
    margin-top: 16.5em !important;
  }
}

@media (min-width: 1441px) {
  .loanlabelcheck {
    font-size: 0.9em !important;
  }

  .loanlabelmak4 {
    font-size: 0.9em !important;
  }

  .form-control.formpprofilefield {
    font-size: 12px !important;
  }

  label.usersubheader {
    font-size: 14px;
  }

  .usertheaderch {
    font-size: 14px !important;
  }

  label.serial-header {
    font-size: 14px !important;
  }

  .usertextch {
    font-size: 18px;
  }

  .checkerside-gridbutton {
    width: 60%;
  }

  p.checkerunderwritting {
    width: 75% !important;
  }

  p.headertextcheck.a1 {
    width: 67% !important;
  }

  p.headertextcheck.a2 {
    width: 60% !important;
  }

  p.productheader.well {
    width: 80% !important;
    padding-top: 0.6em;
  }

  label.productcomm2.checkprem {
    padding-top: 0.4em !important;
    font-size: 0.9em;
  }
}

@media (min-width: 1921px) {
  label.productcomm2.checkprem {
    width: 58% !important;
  }
}