.montra-main-header {
  padding: 0.3rem 2rem;
}

.montra-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0px;
  background-color: #f8f9fa;
}

.montra-main-header .makerheader {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
}

.makerheader {
  flex-wrap: wrap;
}

.toplogoml {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 165px;
}

.toplogo {
  max-width: 100%;
}

.mr-auto {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.montralendingheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul.maker-top-header {
  list-style-type: none;
  display: flex;
  gap: 20px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.navbarlink {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbarlink2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagepartner {
  max-width: 60%;
  padding: 0px;
  border-radius: 4px;
  border: 1px solid #20336b;
}

.montra-logout-user {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.logout {
  padding: 5px 12px;
  font-size: 14px;
  color: #fff !important;
  background-color: #2c007d;
  background-image: none;
  border-radius: 20px;
  border: none;
  font-weight: 600;
}

.link-container {
  display: flex;
  align-items: center;
  background-image: linear-gradient(#24baf2, #fff);
  border-bottom: 1px solid #2c007d;
  padding: 12px 20px;
}

.userlink {
  padding: 5px 20px;
  text-decoration: none;
  color: #000;
  transition: color 0.3s ease;
  font-size: 14px;
  font-weight: 600;
}

.userlink:hover {
  color: #007bff;
}

.userlink:active {
  color: #fff;
  font-weight: bold;
  background-color: #2c007d !important;
  border-radius: 50px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 0.9em !important;
  margin-right: 5px;
  padding: 0.4rem 0.8rem;
}

#content-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  align-items: center;
}

.profile-content-div {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding-top: 100px;
}

.profile-i {
  display: block;
  margin: 0 auto 20px;
}

.profile-content,
.profile-content1 {
  margin: 10px 0;
}

.profile-content span,
.profile-content1 span {
  color: #fbb40a;
}

p.profile-content1 {
  width: 400px;
  text-align: left;
}

.btn-outline-danger:hover {
  background-color: #29baec;
}

@media only screen and (max-width: 767px) {
  p.profile-content1 {
    width: 100%;
  }

  #content-wrapper {
    padding-top: 50px;
  }
}

@media (max-width: 768px) {
  .hamburger-icon {
    display: block;
    margin-left: auto;
  }

  .montra-logout-user {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #f8f9fa;
    width: 100%;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .montra-logout-user.open {
    display: flex;
  }

  .maker-top-header {
    flex-direction: column;
    gap: 10px;
  }
}

.hamburger-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: #2c007d;
}

@media (max-width: 768px) {
  .hamburger-icon {
    display: block;
    margin-left: auto;
    margin-top: -10px;
  }

  .montra-logout-user {
    display: none;
  }

  .montra-logout-user.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #f8f9fa;
    width: 100%;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .maker-top-header {
    flex-direction: column;
    gap: 10px;
  }
  ul.maker-top-header {
    margin-bottom: 20px;
    list-style-type: none;
    display: flex;
    gap: 20px;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
  }
}
