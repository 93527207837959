.home-container {
  width: 100%;
  padding-inline-start: 200px;
  padding-inline-end: 40px;
  padding-block: 80px;
  padding-bottom: 170px;
}

button.products-main {
  padding: 4px 18px !important;
  border-radius: 50px !important;
  background: #ececec !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #223872 !important;
  cursor: default !important;
  border: none !important;
}

button.products-main:hover {
  background: #a4e0f9;
  color: #223872;
}

h6.montr-sub-text {
  font-size: 18px !important;
  color: #4abceb !important;
  background-color: transparent;
}

.homepersonal-payment-topdescription {
  width: 20% !important;
  text-align: center !important;
  padding-bottom: 0 !important;
}

.homepersonal-compare-topdescription {
  width: 20% !important;
  text-align: center !important;
  padding-bottom: 0 !important;
  margin-bottom: 10px !important;
}

@media screen and (max-width: 767px) {
  .home-container {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .left-1 {
    width: 100% !important;
  }

  .left {
    margin-top: 35px !important;
  }

  .home-personal2-left-h1,
  .home-personal2-left-h1-span {
    font-size: 29px !important;
    line-height: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .left-1 {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .home-container {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    padding-block: 70px;
    margin-bottom: 100px;
  }
}

.login-box {
  width: 100%;
  border-radius: 40px;
  border: 1px solid #bfbfbf;
  position: relative;
  padding-inline-start: 50px;
  padding-inline-end: 80px;
  padding-block: 50px;
  display: flex;
  align-items: center;
}

.login-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  margin: auto 0;
}

.ani-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ani-step-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.ani-step-3 {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.ani-step-4 {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.ani-step svg {
  margin-left: 30px;
}

.ani-step-1-box {
  background-color: #4abceb;
  border-radius: 20px;
  color: #fff;
  padding: 20px;
  max-width: 100px;
  text-wrap: wrap;
  text-align: center;
  position: relative;
  font-size: 10px;
}

.ani-step-1-box label {
  position: absolute;
  bottom: -40px;
  right: 0;
  left: -110px;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}

.connect-2 {
  position: absolute;
  top: 0;
  bottom: 83px;
  right: -72px;
  margin: auto 0;
}

.connect-3 {
  position: absolute;
  top: 0;
  bottom: -175px;
  right: -80px;
  margin: auto 0;
}

.connect-4 {
  position: absolute;
  top: -260px;
  bottom: 0;
  right: -48px;
  margin: auto 0;
}

.connect-5 {
  position: absolute;
  top: 0;
  bottom: -260px;
  right: -48px;
  margin: auto 0;
}

.connect-180 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -51px;
  margin: auto 0;
}

.connect-180-small {
  position: absolute;
  top: 0;
  bottom: 10px;
  right: -52px;
  margin: auto 0;
}

.box-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  text-align: center;
  border: 2px solid #bfbfbf;
  color: #bfbfbf;
  background-color: #fff;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}

.box-img label {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}

.box-img-blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  text-align: center;
  color: #fff;
  background-color: #4abceb;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}

.box-img-blue label,
.box-img-2-blue label,
.box-img-3-blue label {
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 10px;
  right: -70px;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}

.box-img-3-blue p {
  height: fit-content;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}

.box-img img {
  width: 32px;
  height: 32px;
}

.box-img-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 200px;
  text-align: center;
  border: 2px solid #bfbfbf;
  color: #bfbfbf;
  background-color: #fff;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}

.box-img-3-blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 200px;
  text-align: center;
  color: #fff;
  background-color: #4abceb;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}

.box-img-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 250px;
  text-align: center;
  border: 2px solid #bfbfbf;
  color: #bfbfbf;
  background-color: #fff;
  border-radius: 10px;
  font-size: 10px;
  padding-inline: 10px;
  position: relative;
}

.box-img-2-blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 250px;
  text-align: center;
  color: #fff;
  background-color: #4abceb;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}

.ani-grey-box {
  position: relative;
  margin-left: 50px;
  margin-bottom: 120px;
  max-width: 100px;
  background-color: #a6a6a6;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
}

.ani-grey-box p {
  position: absolute;
  top: -150px;
  left: 0;
  right: 0;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}

.ani-grey-box ul {
  text-align: left;
}

.ani-grey-box li::marker {
  color: #fff;
}

.no-padding-inline {
  padding-inline: 0;
  justify-content: space-between;
}

.box-compartment {
  width: 100%;
  border-bottom: 2px solid #bfbfbf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.box-img-2 .box-compartment:nth-child(4),
.box-img-3 .box-compartment:nth-child(2) {
  border-bottom: none;
}

.text-group-1,
.text-group-2,
.text-group-3,
.text-group-4,
.text-group-5 {
  opacity: 0;
  animation: cycleItems 15s infinite;
}

.text-group-2 {
  animation-delay: 3s;
}

.text-group-3 {
  animation-delay: 6s;
}

.text-group-4 {
  animation-delay: 9s;
}

.text-group-5 {
  animation-delay: 12s;
}

@keyframes cycleItems {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.yu-flex {
  display: flex;
}

.yu-mt {
  margin-top: 24px;
}

.yu-mr,
.yu-mr-2 {
  margin-right: 48px;
}

.yu-mr:first-of-type,
.yu-mr:nth-child(4) {
  margin-top: 200px;
}

.yu-mr:nth-of-type(2),
.yu-mr:nth-of-type(5) {
  margin-top: 80px;
}

.yu-mr:last-of-type,
.yu-mr-2:last-of-type {
  margin-right: initial;
}

.yu-mr-2:nth-child(2),
.yu-mr-2:nth-child(3),
.yu-mr-2:nth-child(4),
.yu-mr-2:nth-child(5) {
  /* margin-top: 64px; */
  margin-top: 40px;
}

/* .yu-mr-2:nth-child(5) {
  margin-top: 277px;
} */

.yu-relative {
  position: relative;
}

.yu-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.yu-g-1,
.yu-g-2,
.yu-g-3 {
  /* align-items: center; */
  align-items: start;
  gap: 48px;
}

.yu-g-3:hover {
  cursor: pointer;
}

.yu-f-1 {
  margin-top: 224px;
}

.yu-f-2 {
  margin-top: 104px;
}

.yu-f-3 {
  margin-top: 24px;
}

.yu-f-4 {
  margin-top: 224px;
}

.yu-f-5 {
  margin-top: 104px;
}

.yu-f-6 {
  margin-top: 344px;
}

.yu-f-7 {
  margin-top: 264px;
}

.yu-f-8 {
  margin-top: 512px;
}

.yu-mt-x {
  margin-top: 72px;
  margin-left: 45px;
}

.yu-mt-x-2 {
  margin-top: 48px;
  margin-left: 45px;
}

.yu-mt-x-3 {
  margin-top: 78px;
  margin-left: 96px;
}

.ske {
  padding: 15px 10px 12px 10px;
  border: 1px solid #c8d0db;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ske-a {
  padding: 15px 10px 12px 10px;
  border: 1px solid #c8d0db;
  width: 96px;
  height: 96px;
  border-radius: 8px;
}

.ske-a p {
  opacity: 0;
  text-align: center;
  font-size: 11px;
  line-height: 18px;
  margin-top: 8px;
  color: #0a2540;
}

.ske-a:hover {
  border: 0;
  background-color: white;
}

.ske-a:hover p {
  opacity: 1;
}

.home-container .ske img,
.ske-a img,
.ske-b img,
.ske-b>svg {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0px;
  cursor: pointer;
  width: 35px;
  height: 40px;
}

.ske-b:hover {
  cursor: pointer;
}

.ske-b>svg {
  width: 35px;
  height: 40px;
}

.ske p {
  text-align: center;
  font-size: 11px;
  opacity: 0;
  line-height: 15px;
  margin-top: 10px;
  color: #0a2540;
}

.home-personal .ske p {
  font-size: 11px;
  margin-bottom: 0px !important;
  margin-top: 3px;
}

.loan-repayment {
  margin-top: 0px !important;
  font-size: 11px !important;
  opacity: 1 !important;
  line-height: normal !important;
  color: #c8d0db !important;
}

.skep {
  opacity: 1 !important;
  line-height: normal !important;
  margin-top: 3px;
  color: #c8d0db !important;
  margin-bottom: 0px;
}

.payout-skep {
  opacity: 1 !important;
  line-height: normal !important;
  margin-top: 0px !important;
  color: #c8d0db !important;
  /* margin-bottom: 5px !important; */
}

.ske-b {
  padding: 15px 10px 12px 10px;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ske-b img {
  margin-top: initial;
}

.ske-b p {
  text-align: center;
  font-size: 11px;
  line-height: normal !important;
  /* margin-top: 5px; */
  color: #0a2540;
  margin-bottom: 0px !important;
}

.yu-path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 3s linear forwards;
}

.yu-path-personal-anim2 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 4s linear forwards;
}

.yu-path-personal-anim3 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 7s linear forwards;
}

.yu-path-business-anim1 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 5s linear backwards;
}

.yu-g-1:hover .yu-animate-1 {
  transform: scale(0);
  opacity: 0;
  animation: scaleAnimation 3s ease forwards;
  animation: moveAlongPath 5s linear infinite;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

.ml5 {
  margin-left: 5px !important;
}

.ml-5 {
  margin-left: -5px !important;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes moveAlongPath {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 1000;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.yu-none {
  display: none;
}

.yu-gru-1:hover .yu-none {
  display: block;
}

.z-back {
  z-index: -1;
}

.mt24 {
  margin-top: 24px;
}

.mt25 {
  margin-top: 25px;
}

.ml60 {
  margin-left: 60px !important;
}

.mt66 {
  margin-top: 66px !important;
}

.mt67 {
  margin-top: 67px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mt205 {
  margin-top: 205px !important;
}

.montracustomer {
  margin-top: 170px !important;
}

.loanmanagement {
  margin-top: 60px !important;
}

.mt43 {
  margin-top: 43px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt112 {
  margin-top: 112px !important;
}

.mt130 {
  margin-top: 130px !important;
}

.mt195 {
  margin-top: 195px !important;
}

.ml45 {
  margin-left: 45px;
}

.mt50 {
  margin-top: 50px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.ml-30 {
  margin-left: -30px !important;
}

.mt19 {
  margin-top: 19px !important;
}

.mt-30 {
  margin-top: -30px !important;
}

.mt178 {
  margin-top: 178px !important;
}

.ml80 {
  margin-left: 80px;
}

.ml87 {
  margin-left: 87px;
}

.mt220 {
  margin-top: 220px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.mt180 {
  margin-top: 180px !important;
}

.mt186 {
  margin-top: 186px;
}

.mt232 {
  margin-top: 232px;
}

.mt365 {
  margin-top: 365px;
}

.mt286 {
  margin-top: 286px;
}

.mt240 {
  margin-top: 240px;
}

.mt245 {
  margin-top: 245px;
}

.ml240 {
  margin-left: 240px;
}

.mt125 {
  margin-top: 125px !important;
}

.opacity-0 {
  opacity: 0;
}

.animation6-lendingpartner {
  margin-top: 125px !important;
  transition: 0.5s ease;
}

.animation6-ske-lendingpartner {
  margin-top: 60px !important;
}

.animation6-montracustomer-top {
  margin-top: 170px !important;
  margin-left: 40px !important;
}

.animation6-loanrepayment {
  margin-top: 60px !important;
  /* margin-left: -30px !important; */
  transition: 0.5s ease;
}

.anim-6 {
  margin-top: 125px;
}

.animation5-loanrepayment {
  margin-top: 50px !important;
}

.animation5-loanmanagement {
  margin-top: 125px !important;
  margin-left: 288px;
}

.animation6-loanmanagement {
  margin-top: 115px !important;
  margin-left: 30px;
  transition: 0.5s ease;
}

.animation6-ske-loanmanagement {
  margin-top: 60px !important;
  margin-left: 0px;
}

.animation5-montra-customer {
  margin-top: 170px !important;
}

.animation5-loanstatement {
  margin-top: 166px !important;
}

.animation5-ske-loanservice {
  margin-top: 32px;
}

.animation6-ske-loanstatement {
  margin-top: 130px !important;

  /* transition: 0.5s ease; */
}

.animation6-ske-loanrepayment {
  margin-top: 60px !important;
}

.animation5-ske-loanservice {
  margin-top: 35px !important;
}

.lendingAnimations {
  margin-top: 115px !important;
}

@media only screen and (max-width: 767px) {
  .normal-layout {
    display: none;
  }

  .gif-layout {
    display: block;
    margin-top: 20px;
  }

  .personal-margin-bottom {
    margin-bottom: 0px;
  }

  .lendingAnimations {
    margin-top: 0px !important;
  }

  .personal-margin-bottom {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .normal-layout {
    display: block;
  }

  .gif-layout {
    display: block;
  }

  .personal-section1-gif {
    width: 100%;
    height: 100%;
  }
}

.personal-section1-gif {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .home-personal-left {
    width: 50% !important;
    /* padding: 0 100px !important; */
  }

  .lendingAnimations {
    margin-top: 0px !important;
    padding: 0 100px !important;
  }

  .home-personal-col {
    width: 100%;
    /* padding: 0 100px !important; */
  }

  .home-personal-left p {
    width: 100% !important;
  }

  .home-personal-left span {
    display: block !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1500px) {
  .home-container {
    width: 100%;
    padding-inline-start: 140px;
  }
}