.sup-button {
  border-radius: 24px;
  background: #20336b !important;
}

.sup-button:hover {
  background: #4abceb !important;
  color: #20336b !important;
}

.sup-nav-link .active {
  color: #20336b !important;
}

.solution-menu {
  align-items: center;
}

.flexdiv-menu-items .supplychain-logo {
  width: 120px;
}

.flexdiv-menu-items .supplychain-logo img {
  width: 100%;
}

/* .sup-nav-link :hover {

  color: #20336B !important;
} */

.supplychain-navbar li.nav-item:hover {
  color: #4abceb;
}
