#DuoApp {
  overflow: hidden;
}

.duo-app-hero {
  padding: 10px 100px 70px 200px !important;
}

.duo-lists p {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.4;
  padding-right: 20px;
}

.duo-list-container.duo-app-list-container {
  width: 100%;
}

.duo-sm-btn {
  display: flex;
  width: 100px;
  border: 1px solid #565656;
  border-radius: 50px;
  padding: 8px 10px;
  margin-bottom: 10px;
}

.duo-sm-btn.duop p {
  padding-top: 0px;
  font-weight: 500;
}

img.duo-app-hero-img {
  margin-top: 40px;
}

.duo-sm-btn img {
  margin: 0;
}

.duo-lists.duo-lists-app p {
  margin-bottom: 0px;
  font-size: 15px !important;
}

.duo-sm-btn p {
  text-align: center;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
}

p.geteasy {
  font-size: 16.5px;
  line-height: 24px;
  margin-top: 0px;
}

.duo-app-h1 {
  margin-bottom: 20px;
}

.duoapp-body2 {
  align-items: center;
}

.duoapp-body2 .col:nth-child(2) {
  margin: 0px 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .duo-app-hero {
    padding: 20px !important;
  }

  .duo-app-list-container p {
    text-align: start;
  }

  .dts-content img {
    height: 260px !important;
  }

  .duo-sm-btn {
    display: flex !important;
    width: 105px;
    align-items: center;
    margin-bottom: 0px;
  }

  .duo-small-image {
    width: 20% !important;
  }

  .duo-app-h1 {
    margin-top: 10px !important;
  }

  .duoapp-body2 .col:nth-child(2) {
    /* margin: 80px 0px 0px 0px !important; */
    margin: 0px 0px 0px 0px !important;
    width: 100%;
  }

  .duoapp-body {
    padding: 30px 20px !important;
  }

  .row {
    row-gap: 50px;
  }

  .duoapp-body2 .col:nth-child(2) {
    /* margin: 80px 0px 0px 0px !important; */
    margin: 120px 150px 0px 150px;
  }

  .duo-app-hero-img {
    display: flex;
    max-width: 100%;
    margin-top: 0px !important;
    margin: auto !important;
    width: auto !important;
  }

  .duobody-row {
    flex-direction: row !important;
  }

  .duoapp-body-row {
    row-gap: 40px !important;
    padding: 0px 0px;
  }

  .duo-app-hero-img {
    display: flex;
    max-width: 100%;
    margin-top: 0px !important;
    margin: auto !important;
    width: auto !important;
  }

  .duobody-row {
    flex-direction: row !important;
  }

  .dts-body2 {
    padding: 20px 20px;
  }

  .dts-body2 img {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .duoapp-body {
    padding: 50px 50px !important;
  }

  .duo-app-hero {
    padding: 20px !important;
  }

  .row {
    row-gap: 50px;
  }

  .duoapp-body2 img {
    width: 100% !important;
  }

  .duoapp-body2 .col:nth-child(2) {
    /* margin: 80px 0px 0px 0px !important; */
    margin: 120px 150px 0px 150px;
  }

  .duo-app-hero-img {
    display: flex;
    max-width: 100%;
    margin-top: 0px !important;
    margin: auto !important;
    width: auto !important;
  }

  .duobody-row {
    flex-direction: row !important;
  }

  .duoapp-body-row {
    row-gap: 40px !important;
    padding: 0px 30px;
  }

  .duo-app-hero-img {
    display: flex;
    max-width: 100%;
    margin-top: 0px !important;
    margin: auto !important;
    width: auto !important;
  }

  .duobody-row {
    flex-direction: row !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .duoapp-body2 {
    padding: 70px 50px 70px 50px;
  }

  .duo-app-hero {
    padding: 20px !important;
  }

  .dts-hero h1,
  .dts-hero h1 span {
    font-size: 35px !important;
    line-height: 48px !important;
  }

  .duoapp-body {
    padding: 70px 50px 0px 50px;
  }

  .dts-row {
    align-items: flex-start;
  }

  .duoapp-body2 {
    align-items: start !important;
  }

  .duo-app-h1 span {
    font-size: 47px !important;
  }

  .duoapp-body2 {
    align-items: start !important;
  }

  .dts-row {
    align-items: flex-start;
  }

  .duoapp-body2 {
    align-items: start !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .duo-app-hero {
    padding: 10px 80px 100px 80px !important;
  }

  .hero-row {
    align-items: start !important;
  }

  .dts-hero h1,
  .dts-hero h1 span {
    font-size: 40px !important;
    line-height: 55px !important;
  }

  .duo-body {
    padding: 70px 80px !important;
  }

  .duo-overview-image-col {
    display: flex !important;
    justify-content: end !important;
  }
}
