@media only screen and (min-width: 1281px) and (max-width: 1439px) {

  .chat-hero h1,
  .chat-hero h1 span {
    font-size: 47px !important;
    line-height: 60px !important;
  }

  .chat-hero p {
    width: 100% !important;
  }

  .chat-img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .col-md-6.chat-hero-right {
    width: 400px;
    margin-left: 0px;
  }

  .finance-hero-left {
    width: 100% !important;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {

  .col-md-6.chat-hero-right {
    width: 400px;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {

  .chat-hero h1,
  .chat-hero h1 span {
    font-size: 33px !important;
    line-height: 43px !important;
  }

  .chat-hero p {
    width: 100% !important;
  }

  img.chat-img {
    width: 100% !important;
  }

  .col-md-6.chat-hero-right {
    padding-top: 30px !important;
  }

  .chat-body h1 {
    font-size: 29px !important;
  }

  .payment-body-section-5 h1 {
    margin-bottom: 20px !important;
  }

  .chat-hero {
    padding: 20px 20px !important;
  }

  .chat-body {
    padding-top: 30px !important;
    padding-bottom: 20px !important;
  }

  .chat_body {
    padding-top: 0px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  img.chat-img {
    width: 100% !important;
  }
}

.chat-top-description {
  width: 17% !important;
  text-align: center;
}