.personal-overview-hero.personal-overview-hero-h1 {
  font-size: 56px;
  line-height: 68px;
}

@media (max-width: 767px) {
  .personal-hero {
    padding: 20px 20px 20px 20px !important;
  }
  .personal-hero-left {
    padding-right: 0px !important;
  }

  .personal-overview-hero-h1-span,
  .personal-overview-hero-h1 {
    font-size: 35px !important;
    line-height: 43px !important;
  }
  .personal-hero-p {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .personal-hero-app-download-image img {
    width: 30% !important;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .personal-hero-app-download-image img {
    width: 30% !important;
    margin-top: 30px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .personal-hero-left {
    padding-right: 0px !important;
  }
  .personalheroimg {
    width: 50% !important;
  }
  .personal-overview-hero-h1-span,
  .personal-overview-hero-h1 {
    font-size: 46px !important;
    line-height: 58px !important;
  }
  .personal-overview-hero {
    padding: 20px 0 150px 20px !important;
  }
}
