.sup-sm-btn {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border: 1px solid #20336b;
  border-radius: 50px;
  padding: 4px 10px;
  margin-bottom: 10px;
}

.sup-sm-btn p {
  color: #20336b;
  margin-bottom: 0px !important;
  padding: 4px 0 0 0 !important;
  padding-left: 6px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.order-hero {
  /* margin-bottom: 400px !important; */
}

.order-hero h1 {
  font-size: 56px !important;
  line-height: 68px;
}

.center-image-order {
  position: absolute;
  /* left: 77%;
  margin-top: 580px; */
  left: 78%;
  margin-top: 30px;
}

.supplychain-row {
  align-items: center;
}

.order-animation-class {
  display: flex;
  justify-content: center !important;
  margin-left: 0px !important;
}

@media only screen and (max-width: 767px) {
  .sup-sm-btn {
    margin-bottom: 5px;
  }

  .sup-small-image {
    width: 15% !important;
  }

  .order-hero h1 {
    font-size: 25px !important;
    list-style: 38px !important;
  }
}

@media only screen and (max-width: 768px) {
  .order-hero {
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .center-image-order {
    display: none;
  }

  .order-hero .row {
    align-items: start;
  }

  .order-hero h1 {
    font-size: 47px !important;
    line-height: 56px;
  }
}

/* @media only screen and (min-width: 1590px) {
  .order-hero {
    margin-bottom: 0px !important;
  }
} */

@media only screen and (min-width: 1530px) and (max-width: 1589px) {
  .order-hero {
    margin-bottom: 80px !important;
  }
}
