.homepersonal-moneylink-topdescription {
  width: 17% !important;
  text-align: center !important;
  margin-bottom: 15px !important;
  padding-bottom: 0px !important;
  color: #4d148c !important;
}

button.products-main.main-platforms {
  margin-bottom: 0px !important;
  color: #4d148c !important;
}

.homepage-moneylink-textcolor {
  color: #4d148c !important;
}

@media only screen and (max-width: 1024px) {
  .moneylink-img-img img {
    width: 100%;
  }

  .direction-img {
    display: block;
  }

  .home-moneylink h1 {
    font-size: 29px !important;
    line-height: 39px !important;
  }
}