.face-auth-hero {
  padding: 20px 15px 70px 200px;
}

/* #FaceAuthentication {
  padding-left: 15px;
  padding-right: 15px;
} */

.face-auth-hero h1 {
  color: #0b74e6;
  font-size: 56px;
  line-height: 68px;
  font-weight: 500;
  /* width: 530px; */
}

.face-auth-button {
  margin-top: 30px;
}

.duo-list-container.duo-dwp.verdwp {
  width: 580px;
}

.col.verfaceauth {
  width: 50%;
}

.face-auth-img1 {
  padding: 10px 0 0 0;
}

.face-auth-img2 {
  padding: 10px 0;
}

.duo-list-container.duo-dwp.verdwp p {
  margin-bottom: 0px;
  font-size: 18px;
}

.col.verfaceauth {
  padding-top: 30px;
}

.face-auth-hero button {
  padding: 10px 15px;
  border: none;
  background: #37cc00;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.face-auth-body {
  text-align: center;
  padding: 70px 200px 155px 200px;

  background: #f6f9fc;
}

.face-auth-body .row {
  margin-top: 100px;
}

.face-auth-body-h1 {
  color: #0b74e6;
  font-size: 38px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.2px;
}

.verified-part-text {
  text-align: left;
}

.faceauthrow {
  display: flex;
  margin-top: 20px;
}

.faceauthrow1 {
  display: flex;
}

.aaa p {
  margin: 0;
}

.aaa {
  margin-left: 5px;
}

.faceauth {
  margin-top: 0;
}

.faceauthvery {
  width: 100%;
}

.faceauth-image {
  width: 100%;
}

.faceauth-process-flow {
  margin-top: 20px;
}

.faceauth-arrow {
  margin: 0 -10px;
}

.facetopDescription {
  margin-bottom: 5px;
}

.face-auth-body .row {
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .face-auth-hero h1 {
    color: #0b74e6;
    font-size: 35px !important;
    line-height: 45px !important;
  }

  .col.verfaceauth {
    padding-top: 0px;
  }

  .face-auth-img2 {
    width: 100% !important;
  }

  .face-auth-body .row {
    margin-top: 0px;
  }

  .face-auth-hero {
    padding: 20px 20px !important;
  }

  .col.verfaceauth {
    width: 100% !important;
  }

  .face-auth-body-h1 {
    font-size: 28px !important;

    margin-bottom: 30px;
  }

  .list-heading {
    margin-bottom: 5px;
  }

  .verified-part {
    display: flex;
    margin-bottom: 10px;
  }

  .faceauth-image {
    width: 100%;
  }

  .face-auth-button {
    margin-top: 0px;
  }

  .duo-list-container.duo-dwp.verdwp {
    width: 100% !important;
  }

  .face-auth-row {
    row-gap: 20px !important;
  }

  .verified-part-text-text img {
    margin-top: 0px !important;
  }

  .veri-part {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .col.verfaceauth {
    width: 100% !important;
  }

  .face-auth-img2 {
    width: auto !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .face-auth-hero {
    padding: 20px 0 20px 20px;
  }

  .face-auth-body {
    text-align: center;
    padding: 70px 50px;
  }

  .verified-part span {
    color: #425466;
    font-size: 12px;
  }

  .face-auth-row {
    align-items: start;
  }

  .face-auth-hero h1 {
    color: #0b74e6;
    font-size: 56px;
    line-height: 68px;
    font-weight: 600;
    width: 496px;
  }

  .face-auth-button {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .face-auth-hero {
    padding: 20px 100px 70px 80px;
  }

  /* .face-auth-hero h1 {
    line-height: 100%;
  } */
}