.auth-app {
  padding: 20px 100px 70px 200px;
}

/* #FaceAuthentication {
  padding-left: 15px;
  padding-right: 15px;
} */

.auth-app h1 {
  color: #0b74e6;

  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
  width: 480px;
}

.auth-app h1 div {
  color: #37cc00;

  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
}

.auth-app p {
  color: #425466;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 550px;
}

.auth-app-img {
  padding: 0px 0 30px 0;
}

.auth-app button {
  padding: 10px 15px;
}

.auth-app-img-2 img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
  max-width: 100%;
}

.auth-app-body-1 {
  padding: 70px 100px 70px 200px;
  background: #f6f9fc;
}

.auth-app-body-1 h1 {
  color: #0b74e6;
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.auth-app-body-1-inner {
  margin-top: 50px;
}

.auth-app-img-2 {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-right: 0;
}

.col.softtokenheader {
  padding-top: 20px;
}

.col.faceauth {
  padding-top: 20px;
}

.setupauth {
  padding-top: 8px;
}

button.verified-btn1 {
  margin-top: 15px;
}

.auth-app-body-2-2 h1 {
  text-align: center;
  color: #101010;
  font-weight: 500;
  padding: 70px 200px 0px 200px;
}

.auth-app-body-2-2 h1 span {
  color: #37cc00;
}

.auth-app-body-2 {
  padding: 30px 200px 155px 200px;
}

.auth-app-body-2-img img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
  max-width: 100%;
}

.auth-app-body-2 .row {
  margin-top: 50px;
}

.veri-part {
  width: 340px;
}

.veri-part-2 {
  width: 450px;
}

.veri-part-2 p {
  font-weight: 700;
  padding-top: 10px;

  font-size: 18px;
}

.vf {
  display: flex;
}

.vf img {
  margin-top: -30px;
  margin-right: 13px;
}

.vf p {
  margin-bottom: 0 !important;
}

.verified-part-text-text {
  display: flex;
  margin-top: 20px;
}

.verified-part-text-text p {
  font-weight: 500;
}

.verified-part-text-text img {
  margin-top: 0px;
  margin-right: 15px;
}

.verified-part-text-text span {
  width: 340px !important;
}

.fa span {
  width: 434px !important;
}

.verified-part span {
  font-family: Söhne, "Helvetica Neue", "Arial", sans-serif;
}

.auth-app-body-2 .row {
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .auth-app h1,
  .auth-app h1 div {
    font-size: 50px !important;
    line-height: 60px !important;
  }

  .auth-app h1 div {
    display: block;
  }

  .auth-app p {
    font-size: 14px;

    width: 100%;
  }

  .auth-app-body-1 h1 {
    font-size: 24px;
  }

  .auth-app-body-1-inner {
    margin-top: 0px;
    align-items: center;
  }

  .auth-app-body-2-2 h1 {
    padding: 30px 20px;
    font-size: 24px !important;
  }

  .auth-app-body-2 {
    padding: 0px 20px 30px 20px !important;
  }

  .authapp-body3-list-span {
    font-size: 13px !important;
  }

  .col.softtokenheader {
    padding-top: 0px;
  }

  .auth-app-body-1 {
    padding: 30px 20px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .veri-part {
    width: 100% !important;
  }

  .auth-app p {
    width: 100% !important;
  }

  .auth-app-img {
    width: auto !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .auth-app {
    padding: 20px 20px 70px 20px !important;
  }

  .auth-app-body-1 {
    padding: 30px 70px 70px 70px;
  }

  .verified-part span {
    color: #425466;
    font-size: 14px;
  }

  .auth-app-body-2-2 h1 {
    padding: 30px 70px 0 70px;
    font-size: 32px;
  }

  .auth-app-body-2 {
    padding: 70px 0 70px 50px;
  }

  .appauth-list-span {
    color: #425466;
    font-size: 12px !important;

    line-height: 22px !important;
  }

  .auth-app-body-2-img img {
    margin-top: 0px !important;
  }

  .auth-app-img-2 {
    margin-top: 0px;
  }

  .auth-app-body-2 .row {
    margin-top: 0px;
  }

  .authapp-body3-list-span {
    font-size: 12px !important;
  }
}
