.company-nav {
  display: flex;
  justify-content: center;

  margin: auto;
  margin-top: 10px;
  position: relative;
  z-index: 9999;
}

.company-nav li.nav-item:hover {
  color: #4abceb !important;
}

.flexdiv-menu-items .money-trans {
  position: static;
  transform: none;
}

@media only screen and (max-width: 767px) {
  .company-hero {
    margin-top: 0px;
  }

  .company-horizontal-navbar .second-navbar {
    display: block !important;
  }
  .company-horizontal-navbar .second-navbar .nav-link a {
    padding-top: 0px !important;
  }

  .company-horizontal-navbar .navbar {
    padding: 0px 10px 0px 0px !important;
  }
}
