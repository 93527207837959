.company-hero {
  background-image: url("../../../assets/images/uday/company/over-bg2.svg");
  background-size: cover;
  background-position: center;
  object-fit: cover;
  margin-top: 10px;
  padding: 70px 200px;
}

.sponsors .slick-slide {
  width: auto !important;
  padding-right: 50px;
}

.storyfooter {
  margin-top: 130px;
}

.sponsors .slick-slide {
  width: calc(100% / 5);
  /* Change 5 to the number of slides you want to show */
}

.money-trans {
  display: flex;
  position: absolute;
  transform: translate(200%, 3px);
}

.money-trans-1 {
  border: 1px solid #e3d7d7;
  height: 52px;
  width: fit-content;
  padding: 3px;
  border-radius: 5px;
  background: #04112f;
}

.money-trans-1 p {
  line-height: 5px;
  font-size: 22px;
  font-weight: 500;
  color: #fff;

  margin: 1rem 0;
}

.money-trans-2 p {
  line-height: 5px;
  font-size: 22px;
  font-weight: 500;
  color: #4abceb;
  margin-top: 11px;
}

.money-trans-2 h6 {
  font-size: 12px;
  font-weight: 500;
  color: #4abceb;
  line-height: 12px;
  margin-left: 4px;
  margin-top: -2px;
}

.money-trans-1 p:nth-child(1) {
  margin-top: 7px;
}

.money-trans-1 p:nth-child(2) {
  margin-left: 15px;
}

.money-trans-2 p:nth-child(2) {
  margin: 1rem 0;
}

.company-hero-first-part {
  display: flex;
  flex-direction: row;
}

.company-hero-1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.company-hero-1 h1 {
  max-width: 100%;
  color: #fff;
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: -13px;
}

@media (min-width: 1500px) {
  .company-hero-1 h1 {
    width: 600px;
  }
}

.company-child-flex {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.company-hero-2 {
  /* position: absolute; */
  /* width: 889px; */
  width: 100%;
  /* margin-top: 40px; */
}

.company-hero-2 img {
  /* position: absolute;
  margin-top: -150px; */
  max-width: 100%;
  /* right: 700px; */
  /* margin-left: -100px; */
}

.service p {
  font-size: 15px;
  line-height: 10px;
  color: #fff;
  padding-bottom: 0px;
  padding-left: 22px;
  margin-bottom: 10px;
}

.service {
  width: 200px;
}

.service-icon {
  padding-bottom: 10px;
}

.service-icon img {
  display: flex;
  justify-content: center;
  /* margin: auto; */
  width: 40px;
  margin-bottom: 5px;
}

.service-box {
  /* border: 1px solid #4abceb; */
  border-radius: 10px;
  padding: 10px;

  /* margin-top: 10px; */
}

.service-box-hero {
  padding-left: 0px;
}

div.service-box-box {
  width: 3px;
  height: 3px;
  background: #fff;
  border-radius: 50%;
}

.service-box-text p {
  text-align: left;
  padding: 0;
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 13px;
  line-height: 17px;
}

.service-text.content {
  margin-bottom: 5px;
}

.service-text {
  display: flex;
}

.company-body {
  padding: 100px 200px;
}

.vission {
  display: flex;
}

.mission {
  display: flex;
  margin-top: 210px;
}

.mission-img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}

.vission-text {
  width: 450px;
  max-width: 100%;
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}

.mission-text {
  width: 450px;
  max-width: 100%;
  display: flex;
  justify-content: end;
  margin: auto;
  margin-left: 0;
}

.vission-text h1 {
  font-size: 38px;
  font-weight: 500;
}

.mission-text h1 {
  font-size: 38px;
  font-weight: 500;
}

.mission-text p {
  font-size: 18px;
  font-weight: 400;
  color: #425466;
}

.vission-text p {
  font-size: 18px;
  font-weight: 400;
  color: #425466;
}

.montra-m p {
  color: #667185;
  line-height: 20px;
  font-size: 13px;
}

.montra-mm {
  height: 250px;
}

.montra-mmm {
  background: #edf8fd;
  border: none !important;
  box-shadow: 0 4px 8px rgba(88, 92, 95, 0.16);
  position: relative;
  margin-top: 28px;
}

.montra-mmm:hover {
  border: none;
  background: #20336b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #fff !important;
}

.hovered {
  background: #20336b !important;
  color: #fff !important;
}

.hovered.montra-mmm::before {
  background: #20336b;
}

.montra-mmm:hover::before {
  background: #20336b;
}

.montra-mmm::before {
  content: "";
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  height: 10%;
  width: 0.5px;
  /* background-color: #d3d3d3; */
  background-color: #000;
}

/* .service-box-hero::before { */
/* .overview-hero-p::before {
  content: "";
  position: absolute;
  top: -25px;
  left: calc(16px * 0.7);
  transform: translateX(-50%);
  height: 15px;
  width: 1px;
  background-color: #4abceb;
} */

.overview-hero-p {
  text-align: left;
  padding-left: 10px !important;
  font-weight: 500;
  font-size: 16px;
  width: 300px;
}

.border-left {
  border-left: 3px solid #4abceb;
  padding-right: 10px;
}

.service-box-hero {
  position: relative;
  z-index: 999;
  padding-top: 5px;
}

/* .service-m:hover h6,
.montra-mmm:hover {
  color: #fff !important;
  background: #20336b !important;
}

.service-m:hover p,
.montra-mmm:hover {
  color: #fff !important;
  background: #20336b !important;
} */

.montra-m h6 {
  font-size: 14px;
  color: #000;
  text-align: center;
  font-weight: 500;
  /* width: 103px; */
  max-width: 100%;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 15px;
}

.montra-m p {
  color: #667185;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: left;
}

.service-m {
  width: 153px;
}

.montra h1 {
  text-align: center;
  padding: 100px 0 50px 0;
  font-weight: 600;
  margin-top: 90px;
  font-size: 38px;
}

.montra-meaning {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo-carousel {
  margin: 50px 0 50px 0;
  padding: 50px 200px;
  background: #fafafa;
}

.logo-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.sponsors h1 {
  font-size: 38px;
  font-weight: 600;
  text-align: center;
}

.logo-item img {
  max-width: 100%;
  max-height: 100%;
}

.service-icon h4 {
  color: black;
  /* border: 1px solid #d3d3d3; */
  font-weight: 500;
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: #edf8fd;
}

/* .service-m:hover h4, */
.montra-mmm:hover p,
.montra-mmm:hover h6 {
  background: #20336b !important;
  color: white !important;
}

/* seema */

.slick-list {
  margin: 0px 70px !important;
}

.slick-prev::before,
.slick-next::before {
  color: #a2a2a2 !important;
}

.service-parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 10px;
  width: 100%;
}

/* seema */

@media (max-width: 430px) {
  .company-hero-1 h1 {
    width: 100%;
    font-size: 28px !important;
  }

  .service-parent {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .service-box-text-p {
    font-size: 14px !important;
    margin-left: 5px;
  }

  .company-hero-2 img {
    margin-top: 0px !important;
    margin-left: 0px !important;
    max-width: 100% !important;
  }

  .vission-img {
    width: 100% !important;
  }

  .company-hero-1 h1 {
    margin-top: 0px;
  }

  .overview-hero-p {
    font-size: 14px;
  }

  .service {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .company-hero-1 {
    flex-direction: column !important;
    row-gap: 20px;
  }

  .service-parent {
    padding: 0 7px !important;
    flex-direction: row !important;
  }

  .service-parent {
    grid-template-columns: repeat(2, 1fr);
  }

  .service-box {
    padding-top: 10px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
  }

  .service-text {
    display: flex;
    align-items: center !important;
  }

  .service {
    margin: 0px !important;
    margin-bottom: 10px !important;
  }

  .service-icon img {
    width: 40px !important;
    height: 40px !important;
  }

  .vission-text h1,
  .mission-text h1 {
    font-size: 30px;
  }

  .mission-text p,
  .vission-text p {
    font-size: 15px;
  }

  .montra-m p {
    padding: 0 7px;
  }

  .overview-service-body {
    flex: 0 0 calc(50% - 20px) !important;
    margin-bottom: 0;
  }

  .montra-mm {
    padding-top: 20px !important;
    align-items: flex-start !important;
  }

  .logo-carousel {
    padding: 50px 20px;
  }

  .service-m {
    width: 100%;
  }

  .montra-meaning {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .company-hero-second-part {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .company-hero-first-part {
    flex-direction: column;
  }

  .company-hero-1 {
    flex-direction: row;
  }

  .company-hero-1-h1 {
    font-size: 41px !important;
  }

  .service-parent {
    column-gap: 0px !important;
  }

  .company-child-flex {
    width: 100%;
  }

  .service-parent {
    width: 100%;
  }

  .overview-hero-p {
    text-align: left;
    padding-bottom: 0px !important;
    padding-left: 10px !important;
  }

  .service {
    flex: 0 0 calc(50% - 20px) !important;
    margin: 10px;
    text-align: center;
  }

  .service-icon img {
    width: 60px;
    height: 60px;
  }

  .company-hero-2 img {
    /* margin-top: -53px !important; */
    max-width: 100% !important;
    margin-left: 0px !important;
  }

  .vission-text h1,
  .mission-text h1 {
    font-size: 32px;
  }

  .vission-text p,
  .mission-text p {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .montra-mm {
    height: 175px;
  }

  .montra-m h6 {
    width: 100%;
  }

  .sponsors {
    padding-top: 30px;
  }

  .service.service-m {
    flex: 0 0 calc(50% - 20px) !important;
    margin-bottom: 0;
  }

  /* .service-box-hero {
    height: 240px;
  } */

  .slick-arrow,
  .slick-prev {
    margin-left: 20px !important;
  }

  .slick-arrow,
  .slick-next {
    margin-right: 30px !important;
  }

  .logo-carousel {
    padding: 50px 20px;
  }
}

@media (max-width: 992px) {
  .service.service-m {
    /* flex: 0 0 calc(30% - 20px); */
    margin-bottom: 0;
  }

  .logo-carousel {
    padding: 50px 20px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .logo-carousel {
    padding: 50px 20px;
  }

  .company-hero {
    padding: 70px 20px;
  }

  .company-hero-2 img {
    /* margin-top: 14px; */
    max-width: 100%;
  }

  .company-hero-second-part {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .service.service-m {
    flex: 0 0 calc(30% - 20px);
    margin-bottom: 0;
  }

  .service {
    flex: 0 0 calc(50% - 20px);
    margin: 10px;
    text-align: center;
  }

  .company-body {
    padding: 100px 20px;
  }

  .company-hero-2 img {
    max-width: 80%;
  }
}

@media (min-width: 1024px) {
  .company-hero {
    padding: 70px 20px;
  }

  .company-body {
    padding: 100px 20px;
  }

  .company-hero-2 img {
    /* margin-top: 14px; */
    max-width: 100%;
  }

  .montra-mm {
    height: 180px;
  }
}

@media (min-width: 1280px) {
  /* .company-hero-2 img {
    margin-top: -150px;
    max-width: 55%;
  } */
  .montra-mm {
    height: 250px !important;
  }

  .company-hero {
    padding: 50px 100px 70px 200px;
  }

  .company-body {
    padding: 100px 200px;
  }

  .service-m {
    width: 145px;
  }
}

@media (min-width: 1366px) {
  .service-m {
    width: 165px;
  }
}

@media only screen and (max-width: 1440px) {
  .company-hero-1 h1 {
    width: 514px;
    font-size: 38px;
  }

  .service-parent {
    column-gap: 15px;
    row-gap: 5px;
  }
}

@media (max-width: 1440px) {
  .company-hero-2 img {
    margin-top: 80px;
    max-width: 100%;
  }

  .company-hero-1 h1 {
    margin-bottom: 30px;
  }

  .company-hero {
    padding: 20px 20px 10px 200px;
  }
}

@media (max-width: 1498px) {
  .company-hero-2 img {
    /* margin-top: -150px; */
    max-width: 100%;
  }
}

@media (max-width: 1356px) {
  .company-hero {
    padding-top: 20px;
  }

  .company-hero-1 h1 {
    margin-bottom: 20px;
  }

  .company-hero-2 img {
    margin-top: 80px;
    max-width: 100%;
  }
}

@media (max-width: 1280px) {
  .money-trans {
    transform: translate(100%, 3px);
  }

  .company-hero {
    padding-left: 80px;
  }
}

@media (max-width: 1030px) {
  .company-hero {
    padding-left: 20px;
  }

  .overview-hero-p {
    width: 100%;
    font-size: 14px !important;
  }

  .service-parent {
    column-gap: 5px;
  }

  .company-hero-2 img {
    margin-top: 40px;
    max-width: 120%;
  }

  .money-trans {
    transform: translate(30%, 3px);
  }
}

@media (max-width: 900px) {
  .overview-image-container {
    height: 50px;
    background: #fff;
  }

  .money-trans {
    transform: translate(30%, 5px);
  }

  .company-child-flex {
    width: 100%;
  }

  .company-hero-2 {
    display: block !important;
  }

  .company-hero-2 img {
    margin-top: 0px;
    max-width: 80%;
    margin-left: 70px;
  }

  .company-hero-first-part {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 450px) and (max-width: 900px) {
  .vission img {
    width: auto !important;
  }
}

@media (max-width: 1045px) {
  .service-parent {
    column-gap: 0px;
  }

  .company-hero-2 img {
    margin-top: 80px;
    max-width: 100%;
  }
}

@media (min-width: 1499px) {
  .company-hero-2 img {
    margin-top: 20px;
    max-width: 110%;
  }

  .company-hero {
    padding: 30px 100px 35px 200px;
  }

  .montra-mm {
    height: 220px !important;
  }
}
