.col-hero-p {
  color: #425466;
  font-size: 18px;
  margin-bottom: 10px;
}

.duo-list.duo-list-collection p {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 5px;
}

.duo-lists.listcollection p {
  font-size: 18px;
  line-height: 28px;
}

.collection-duo-lists.dts-list {
  padding-left: 8px !important;
}

.dts-hero.col-hero {
  padding-top: 25px;
}

.collection-hero-1 {
  width: 50%;
}

.collection-duo-lists {
  padding-right: 30px;
}

.collection-body {
  background: #f6f9fc;
}

.collection-hero-img {
  width: 100%;
}

.collection-img {
  display: flex;
  justify-content: end;
  margin-bottom: 50px;
}

.collection-list {
  height: auto !important;
  margin-bottom: 50px;
}

.collection-list-container {
  width: 100% !important;
}

.duo-list-container.collection-list-container {
  margin-top: 10px;
}

.collection-duo-list-box {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background: #425466;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 7px;
}

@media only screen and (max-width: 767px) {
  .collection-list {
    height: 100% !important;
    margin-bottom: 0px;
  }

  .collection-img {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }

  .collection-img img {
    width: 100%;
  }

  .collection-duo-lists p {
    font-size: 16px !important;
  }

  .dts-list {
    padding: 0 5px !important;
  }

  .col.collection-hero-1 {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .collection-hero-1 {
    width: 100%;
  }


}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .collection-body {
    padding: 70px 50px 0px 50px !important;
  }

  .coll-sm-btn {
    width: 140px !important;
  }

  .collection-img {
    margin-left: 100px !important;
  }

  .collection-list {
    height: 550px !important;
  }

  .collection-img img {
    width: 100%;
  }
}

.col-hero-p {
  color: #425466;
  font-size: 18px;
  margin-top: 20px;
  font-weight: 400;
}

.collection-hero-1 {
  width: 50%;
}

.collection-hero-img {
  width: 100%;
}

.collection-img {
  display: flex;
  justify-content: end;
}

.collection-list {
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .collection-hero-1 {
    width: 100%;
  }

  .collection-list {
    height: 100% !important;
  }
}

.collection-img {
  margin-left: 0px !important;
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .imp-hero {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media only screen and (min-width: 1281px) {
  .hero-text {
    font-size: 45px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .coll-sm-btn {
    width: 140px !important;
  }

  .collection-img {
    margin-left: 100px !important;
  }

  .collection-list {
    height: 550px !important;
  }
}