.hero {
  padding-inline-start: 200px;
  padding-block-start: 70px;
  padding-block-end: 400px;
  display: flex;
  gap: 208px;
  align-items: flex-start;
}

button.cta-blue.tappaybelow {
  margin-top: 33px;
}

.business-agency.business-tappay {
  padding: 50px 200px 250px 200px;
}

.tap-sm-btn {
  width: -moz-fit-content !important;
  width: fit-content !important;
  border: 1px solid #20336b;
  border-radius: 50px;
  display: flex;
  padding: 2px 10px;
}

.tap-sm-btn p {
  margin-bottom: 0px !important;
  padding: 0px !important;
  padding-left: 6px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding-top: 2px !important;
  color: #20336b;
}

.row.tapbody {
  padding-top: 40px;
}

.business-tappay {
  padding: 20px 200px 170px 200px;
}

.business-payment-body-1.Tapandbody p {
  font-size: 15px;
  font-weight: 400;
  padding-top: 2px;
  color: #c5cfd8;
  padding-left: 8px;
}

.business-payment-body-1.Tapandbody h2 {
  text-align: center;
  font-size: 38px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 0px;
}

.business-payment-body-1.Tapandbody h6 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
}

.container.grids.tapgrid {
  margin-bottom: 80px;
}

h1.tapaccept {
  line-height: 68px;
  padding-top: 10px;
}

.business-agency.business-tappay p {
  padding-right: 60px;
  padding-bottom: 14px;
}

.business-payment-body-1.Tapandbody {
  padding-top: 80px;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 10px;
}

.mobile-animation {
  position: relative;
  width: 130%;
  height: 100%;
}

.mobile-animation img {
  position: absolute;
  opacity: 0;
  animation: fade 9s infinite;
  width: 100%;
  margin-left: auto;
  margin-top: 20px;
}

.mobile-animation img:nth-child(1) {
  animation-delay: 0s;
}

.mobile-animation img:nth-child(2) {
  animation-delay: 3s;
}

.mobile-animation img:nth-child(3) {
  animation-delay: 6s;
}

.tappay-topdescription {
  margin: 0px 0px 5px 0px !important;
  width: 15% !important;
  text-align: center !important;
}

.tapPay-down-description-font {
  font-size: 12px !important;
}

@media only screen and (max-width: 767px) {
  .business-tappay {
    padding: 30px 20px 50px 20px !important;
    margin-bottom: 450px;
  }

  .mobile-animation {
    display: flex;

    justify-content: center;
    margin-top: 30px;
  }

  .hero {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    display: block;
    margin: 0 0 50px 0;
  }

  .mobile-animation img {
    width: 100%;
    margin-left: 100px;
  }

  img.dlm-sm-btn-image {
    max-width: 100% !important;
  }

  .inventory-sm-btn {
    padding: 3px 5px;
  }

  h1.tapaccept {
    line-height: 43px;
  }

  .mobile-animation {
    margin-top: 0px !important;
  }

  .mobile-animation img {
    width: 70%;
    margin-left: 0px;
  }

  .business-payment-body-1.Tapandbody h2 {
    font-size: 33px;
  }

  .pricing-container {
    gap: 20px !important;
  }

  .pricing-container ul {
    border-inline-start: none !important;
    padding-inline-start: 20px !important;
  }

  .pricing-container li {
    font-size: 15px !important;
  }

  .tap-pay-pricing {
    padding-bottom: 50px !important;
    padding-inline: 30px !important;
  }
  .tappay-process-container {
    padding: 0px 0px 10px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .business-tappay {
    margin-bottom: 600px;
  }
  .tappay-process-container {
    padding: 0px 0px 10px 0;
  }

  .mobile-animation img {
    display: block;
    width: 70%;
    margin-left: 150px;
  }

  .inventory-sm-btn {
    padding: 3px 5px;
  }

  .business-tappay-content-col {
    width: 100%;
  }

  .mobile-animation {
    width: 500px;
  }

  .mobile-animation img {
    width: 100%;
    margin-left: 0px;
  }

  .business-payment-body-1.Tapandbody {
    padding-top: 20px !important;
  }

  .tap-pay-body-grid {
    width: 100%;
  }

  .tap-pay-body-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .pricing-container {
    gap: 20px !important;
  }

  .tap-pay-pricing {
    padding: 60px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 920px) {
  img.dlm-sm-btn-image {
    max-width: 100%;
  }
  .home-business {
    margin-top: 450px;
  }
  .mobile-animation {
    width: 50%;
    margin: auto;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .business-tappay {
    padding: 50px 20px 50px 20px !important;
  }

  .mobile-animation {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .mobile-animation img {
    display: block;
    width: 100%;
    margin-top: 0px;
    margin-left: 150px;
  }

  .hero {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    display: block;
    margin: 0 0 250px 0;
  }

  .pricing-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 16px;
    gap: 68px;
    margin-inline: 20px;
    width: 100% !important;
  }

  .pricing-container ul {
    text-align: left;
  }

  .mobile-animation img {
    margin-left: 0px !important;
  }

  .business-tappay {
    padding: 30px 20px 80px 20px !important;
  }

  .business-tappay-row {
    align-items: start !important;
  }

  .pricing-container {
    gap: 30px !important;
  }

  .tap-pay-pricing-container {
    width: 500px !important;
  }

  .tap-pay-pricing-container ul {
    padding-inline-start: 0px !important;
  }
}

.tap-pay-main h1 {
  color: #20336b;

  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
}

.tap-pay-main b {
  font-weight: 600;
}

.tap-pay-main {
  color: #20336b;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  width: 500px;
  padding: 10px 0;
}

.body-main {
  position: relative;
  z-index: 1000;
  background-color: #20336b;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 100px 0;
}

.body-main h2 {
  font-size: 38px;
  font-weight: 500;
  color: #4abceb;
  text-align: center;
}

.body-main p {
  color: #c5cfd8;
}

.gap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.grids {
  margin-block-end: 8rem;
  justify-content: center;
}

.col-md-4.tapayanimations {
  padding-top: 22px;
}

.grids img {
  height: 36px;
}

button.cta-blue.tappaybutton {
  margin-top: 5px;
  line-height: 145%;
  padding: 10px 15px;
}

.pricing {
  background-color: #fff;
  padding-block: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 155px;
}

.pricing h2 {
  color: #20336b;
  text-align: center;
  font-weight: 500;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.tag {
  border-radius: 8px;
  background-color: #dee6fd;
  color: #20336b;
  padding-block: 12px;
  padding-inline: 16px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.pricing-container {
  position: relative;
  width: 837px;
  background-color: #20336b;
  padding-block: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 16px;
  gap: 68px;
  font-weight: 500;
}

.pricing-container h3 {
  font-size: 40px;
  font-weight: 700;
}

.pricing-container .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: -30px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  background-image: url("../../../assets/images/features-5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 23px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4abceb;
}

.pricing-container ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-inline-start: 40px;
  border-inline-start: 1px solid #fff;
}

.pricing-container li {
  list-style-position: inside;
  list-style-image: url("../../../assets/images/check-2.svg");
  font-size: 18px;
  font-weight: 400;
}

/* Animations */
@keyframes fade {
  0% {
    opacity: 0;
  }

  33.33% {
    opacity: 1;
  }

  66.66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .mobile-animation img {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .business-agency {
    padding: 50px 200px 250px 200px;
  }
}
